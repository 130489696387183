import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import TRANSLATE from "../constants/TRANSLATE";

export const useTranslate = () => {
  const { app } = useContext(AppContext);

  const translate = (key, params = undefined) => {
    let appText = app?.text?.[key];

    if (params && appText) {
      Object.keys(params).forEach((param) => {
        appText = appText.replace(`{${param}}`, params[param]);
      });
    }

    return appText || TRANSLATE[key] || "";
  };

  return { translate };
};
