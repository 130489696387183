const AgreementEN = () => {
  return <>
    <h1 className="text-center h4 mb-4">
      Beyond Eğitim ve Danışmanlık Hizmetleri Ltd. Şti. (“Digi-Stage”)
    </h1>
    <h2 className="text-center h5 mb-4">
      Information and Disclosure Text on the Processing and Protection of Personal Data of
      Musing©
    </h2>
    <h4>1. Identity of the Data Controller</h4>
    <p>
      As Beyond Eğitim ve Danışmanlık Hizmetleri Ltd. Şti. (“Digi-Stage”), we process the personal
      data we obtain regarding all participants of the Musing© game (“Musing”) as the data
      controller. You can find information about the type, processing method, purpose and legal
      grounds of this personal data in this Information and Disclosure Text prepared in order to ensure
      that we can act in accordance with the Personal Data Protection Law No. 6698 ("Law") and
      related regulations.
    </p>
    <h4>2. Processing of Personal Data and Purposes of Processing</h4>
    <p>
      Your e-mail address which you shared with Musing, the username and avatar you choose, the
      name of the team you create, your score and ranking in the game and (if you wish to give us
      feedback) your suggestions and complaints are processed electronically (via software).
      Processing of these personal data is limited to the purposes of fulfilling of our contractual
      obligations, maintaining our commercial activities and ensuring the customer satisfaction, and
      your data will not be used for any other purposes than these.
    </p>
    <p>
      To enable players to communicate by voice when using Musing, Digi-Stage uses Voice Calling
      from Agora Lab Incorporated. As data controller, we do not process any additional personal
      data via Voice Calling other than the given above; however, Agora Lab Inc., as a data processor,
      processes some of your personal data on its servers located abroad in accordance with its data
      policy in order to provide its services within the scope of its contractual obligations and to
      ensure service quality. These data contain your IP address, configuration data of Agora, usage
      data and metadata, system performance data and system logs. For more detailed information on
      this subject, you can access the Information and Disclosure Text of Processing the Personal
      Data of Agora Lab. Inc. from <a
        href="https://www.agora.io/en/agora-processor-privacy-statement/"
        target="_blank"
        rel="noreferrer nofolloew"
      >
        this link
      </a>.
    </p>
    <h4 id="cookies">2/a. Data Processed Through Cookies</h4>
    <p>
      Since Musing runs on your web browser, some cookies that are mandatory or necessary for the
      game to work and to ensure server security are operating on our URL address. These cookies
      consist of the following:
    </p>
    <p>
      AspNetCore.Antiforgery: It is used for the server security. It generates a key for each client
      connecting to the server.
      <br/>
      websdk_ng_global_parameter: It is a cookie that belongs to Agora Lab. Inc. It is a necessary
      parameter for Agora Voice Calling to work in your browser.
    </p>
    <p>
      The storage periods of processed data are determined within the framework of the Digi-Stage
      Data Processing and Destruction Policy. For more detailed information about the storage
      period, you can contact with us.
    </p>
    <h4>
      4. To Whom And For What Purposes The Processed Personal Data Is Transferred
    </h4>
    <p>
      In order to access Musing without downloading to your computer and the stability of the game,
      Musing runs on a cloud computing system called AWS (Amazon Web Services), and your
      personal data listed above are processed and transferred on servers of Amazon Web Services
      Inc. located in Germany.
    </p>
    <p>
      The personal data processed by Agora Lab. Inc. are automatically processed and transferred on
      the company’s servers located in the USA.
    </p>
    <p>
      Your personal data processed by us directly or through data processing organisations may be
      transferred to other official institutions and organizations, independent audit firms, courts,
      mediators, arbitration boards and committees, Digi-Stage’s counsels, financial and customs
      advisors, and other consultants, as well as Digi-Stage’s shareholders, in accordance with the
      principles of proportionality and purposefulness stipulated in the Law. The purposes of transfer,
      in addition to the purposes stated in Article 2 of this Information Notice, are for the performance
      of Digi-Stage’s contractual obligations, fulfilment of legal obligations and establishment or
      protection of legal rights.
    </p>
    <h4>5. Processing Method and Legal Grounds of Collecting Personal Data</h4>
    <p>
      All personal data processed within the scope of maintaining the Digi-Stage’s ordinary
      commercial activities, accounting and financial transactions and customer satisfaction
      procedures and listed in Article 2 above are processed based on the legal reasons of “being
      directly related to the establishment and performance of the contract”, “being necessary to fulfil
      our legal obligations”, “being mandatory for our legitimate interests” and if any, “explicit
      consent”.
    </p>
    <p>
      These personal data are collected from the written information transmitted by you to Digi-Stage
      and from the applications and software used within the scope of the company’s activities. All
      categories of data can be processed both automatically and non-automatically.
    </p>
    <h4>6. Your Rights Regarding the Protection of Personal Data</h4>
    <p>
      Within the framework of personal data protection legislation, you have the following rights
      regarding your personal data:
      <br/>
      - To learn whether your personal data is being processed, and if so, to request information
      about it.
      <br/>
      - To learn the purpose of processing and whether your data is being used accordingly.
      <br/>
      - To learn the third parties to whom your data has been transferred, whether domestically or
      internationally.
      <br/>
      - To request correction of your data if it is incomplete or incorrect, and to have the correction
      communicated to third parties if your data has been transferred.
      <br/>
      - To request the deletion or destruction of your data if the processing conditions are no longer
      valid, and to have this communicated to third parties if your data has been transferred.
      <br/>
      - To object if you believe an adverse outcome has arisen against you as a result of the data
      being analyzed solely through automated systems.
      <br/>
      - To request compensation for the damages occurred due to unlawful processing.
      <br/>
      You can make applications regarding your personal data under the Law through the following
      channels:
      <br/>
      - You can deliver your physical documents in person to our address: Esentepe Mh. Talatpaşa
      Cd. No:5, Kapı No:1 Şişli/ İSTANBUL, or send them via a notary with documents verifying
      your identity.
      <br/>- You can also submit your application using your registered email address at Digi-Stage to
      the email address <a href="mailto:info@digi-stage.com">info@digi-stage.com</a>, using secure electronic signature.
    </p>

    <p>
      According to the Notification on the Principles and Procedures of Applying to the Data
      Controller, your application must be in Turkish and must include the following details:
    </p>

    <p>
      a) Name, surname and signature if the application is in writing,
      <br/>
      b) Turkish Identification Number for Turkish citizens; and nationality, passport number, or if
      available, identity number for foreigners,
      <br/>
      c) Residential or workplace address for notification,
      <br/>
      d) If available, the email address, telephone, and fax number for communication,
      <br/>
      e) Subject of the request,
      <br/>
      and the relevant information and documents must be attached to the application.
    </p>

    <p>
      In accordance with Article 13 of the Law, Digi-Stage will conclude your application within 30
      (thirty) days at the latest. If the process requires a cost, the tariff determined by the Personal
      Data Protection Board will be applied. If the request is rejected, the reasons for rejection will
      be provided in writing or electronically.
    </p>

    <p>Thank you for your attention.</p>
  </>
}

export default AgreementEN;