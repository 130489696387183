import PreGameLayout from "../../../layouts/PreGameLayout";
import { useTranslate } from "../../hooks/useTranslate";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";

const CloseTabInfo = () => {
  const {translate} = useTranslate();
  const {app} = useContext(AppContext);

  return <PreGameLayout className="close-tab-info">
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      {!app.showFaqModal && <h1 className="text-center px-5 fs-2 m-0"
           dangerouslySetInnerHTML={{ __html: translate("FAQ_PAGE_INFORMATION")?.replaceAll("\\", "") }}/>}
    </div>
  </PreGameLayout>
}

export default CloseTabInfo;