import PreGameLayout from "../../layouts/PreGameLayout";
import AgreementTR from "./lang/tr";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import AgreementEN from "./lang/en";

const Agreement = () => {
  const { app } = useContext(AppContext);
  const hash = window.location.hash;

  if (hash) {
    const element = document.getElementById(hash.substring(1));
    if (element) {
      element.scrollIntoView();
    }
  }

  return (
    <PreGameLayout>
      <div className="agreement-page mb-0 mx-4 p-5 pt-0 overflow-auto mh-100">
        {app.language === "tr" && <AgreementTR/>}
        {app.language === "en" && <AgreementEN/>}
      </div>
    </PreGameLayout>
  );
};

export default Agreement;
