import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import SadMimo from "../../../assets/icons/sad-mimo.svg";
import { useTranslate } from "../../../utils/hooks/useTranslate";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";

const AlignAgainModal = ({ show, onClose, wrongs, corrects }) => {
  const { translate } = useTranslate();

  const { gameState } = useContext(AppContext);

  const isVirtuoso = gameState?.player?.role === "virtuoso";

  return (
    <Modal show={show} toggleModal={onClose} hideCloseButton>
      <div className="d-flex flex-column align-items-center">
        <img className="my-3" width={150} src={SadMimo} alt="Sad Mio" />
        <p className="fw-bold h4 text-center my-2">
          {translate(
            !isVirtuoso
              ? "MODAL_CHECK_TRACK_GUEST_TITLE"
              : "MODAL_CHECK_TRACK_TITLE"
          )}
        </p>
        <p
          className="text-center px-4 mb-4"
          dangerouslySetInnerHTML={{
            __html: translate(
              !isVirtuoso
                ? "MODAL_CHECK_TRACK_GUEST_DESCRIPTION"
                : "MODAL_CHECK_TRACK_DESCRIPTION"
            ),
          }}
        />
      </div>
      <div className="d-flex align-items-center justify-content-around">
        <div className="correct-notes text-center mx-4 py-1">
          <p className="fs-6 mb-0">{translate("MODAL_CHECK_TRACK_CORRECT")}</p>
          <p className="fw-bold fs-3 mb-0">{corrects}</p>
        </div>
        <div className="wrong-notes text-center mx-4 py-1">
          <p className="fs-6 mb-0">{translate("MODAL_CHECK_TRACK_WRONG")}</p>
          <p className="fw-bold fs-3 mb-0">{wrongs}</p>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <Button onClick={onClose}>
          {translate(
            !isVirtuoso
              ? "MODAL_CHECK_TRACK_CLOSE"
              : "MODAL_CHECK_TRACK_TRY_AGAIN"
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default AlignAgainModal;
