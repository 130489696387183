import Card from "../../../components/Card";
import RECORD_PARTS from "../../../utils/constants/RECORD_PARTS";
import Note from "../../Guest/components/Note";

import PlayIcon from "../../../assets/icons/play-icon.svg";
import { useEffect, useState } from "react";
import ROLES from "../../../utils/constants/TUTORIAL";

import { Steps } from "intro.js-react";
import { TYPES } from "../Tutorial";

import { Portal } from "react-portal";

const notes = [
  {
    partId: 1,
    iconId: 1,
    order: 1,
  },
  {
    partId: 2,
    iconId: 2,
    order: 2,
  },
  {
    partId: 3,
    iconId: 3,
    order: 3,
  },
  {
    partId: 4,
    iconId: 4,
    order: 4,
  },
  {
    partId: 5,
    iconId: 5,
    order: 5,
  },
];

const Guest = ({
  translate,
  selectedRole,
  canStart,
  roleId,
  tourOptions,
  onComplete,
}) => {
  const [selectedParts] = useState([3, 5]);

  const [tourEnabled, setTourEnabled] = useState(false);
  const [iconStyles, setIconStyles] = useState({});

  useEffect(() => {
    checkIconPositions();

    window.addEventListener("resize", checkIconPositions, false);

    return () =>
      window.removeEventListener("resize", checkIconPositions, false);
  }, []);

  useEffect(() => {
    if (tourEnabled) {
      const firstNote = document.querySelector(
        "#tutorial-guest-1 .note-holder"
      );

      if (firstNote) {
        const overlay = document.querySelector(
          "#tutorial-guest-1 .selected-notes-overlay"
        );
        const { clientLeft: x, clientTop: y } = firstNote;

        overlay.style.top = `${y}px`;
        overlay.style.left = `${x + 35}px`;
        overlay.style.width = `${(firstNote.offsetWidth + 24) * 2}px`;
        overlay.style.height = `${firstNote.offsetHeight}px`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourEnabled, window.innerWidth]);

  useEffect(() => {
    if (selectedRole === roleId && canStart)
      setTimeout(() => setTourEnabled(true), 400);
    else if (selectedRole !== roleId) setTourEnabled(false);
  }, [selectedRole, canStart, roleId]);

  const checkIconPositions = () => {
    const el = document.getElementById("role-guest");

    if (el) {
      const { width, height, left, top } = el.getBoundingClientRect();
      setIconStyles({
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
      });
    }
  };

  return (
    <>
      {tourEnabled && (
        <Portal>
          <div
            className="icon d-flex justify-content-center align-items-center tutorial-type-icon highlighted position-absolute"
            role="button"
            id="role-1-light"
            style={iconStyles}
          >
            <img src={TYPES[2].icon} alt={TYPES[2].title} className="h-100" />
            <div className="role-name position-absolute text-uppercase fw-bold">
              {translate(TYPES[2]?.title)}
            </div>
          </div>
        </Portal>
      )}
      <Steps
        initialStep={0}
        enabled={tourEnabled}
        steps={ROLES["guest"].map((step) => ({
          element: document.querySelector(step.target),
          intro: translate(step.content),
        }))}
        onExit={() => {
          setTourEnabled(false);
        }}
        options={tourOptions}
        onComplete={onComplete}
      />

      <div className="game-layout w-100 h-100 position-relative">
        <div
          className="gamer container-fluid main d-flex flex-column align-items-center justify-content-between w-100 h-100 py-2 overflow-auto"
          style={{
            backgroundImage: `url(${RECORD_PARTS[2]?.box})`,
          }}
        >
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-10 h-100">
              <Card className="d-flex flex-row align-items-center justify-content-center overflow-auto h-100 pt-4">
                <div
                  className="notes-holder d-flex flex-row justify-content-center align-items-center w-100 h-100 position-relative"
                  id="tutorial-guest-1"
                >
                  <div
                    className="selected-notes-overlay position-absolute"
                    id="tutorial-guest-3"
                  ></div>
                  {notes
                    .filter((note) =>
                      selectedParts?.length
                        ? selectedParts?.includes(note?.partId)
                        : false
                    )
                    .sort(
                      (a, b) =>
                        selectedParts.indexOf(a?.partId) -
                        selectedParts.indexOf(b?.partId)
                    )
                    .map((note) => (
                      <Note
                        key={note?.partId}
                        note={note}
                        isSelected={selectedParts.includes(note?.partId)}
                        selectedOrder={selectedParts.indexOf(note?.partId) + 1}
                        customId={note?.partId === 3 ? "tutorial-guest-2" : ""}
                      />
                    ))}
                  {notes
                    .filter((note) =>
                      selectedParts?.length
                        ? !selectedParts.includes(note?.partId)
                        : true
                    )
                    ?.sort((a, b) => a.order - b.order)
                    ?.map((note) => (
                      <Note
                        key={note?.partId}
                        note={note}
                        isSelected={
                          selectedParts?.length
                            ? selectedParts.includes(note?.partId)
                            : false
                        }
                        selectedOrder={
                          selectedParts?.length
                            ? selectedParts.indexOf(note?.partId) + 1
                            : -1
                        }
                      />
                    ))}
                </div>
                <div className="play-button-area d-none flex-column align-items-center justify-content-center py-2">
                  <div className="play-button d-flex align-items-center justify-content-center">
                    <img
                      className="play-icon"
                      src={PlayIcon}
                      alt="Musing Logo"
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guest;
