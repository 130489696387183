import { useRef, useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import SelectAvatar from "../../../components/SelectAvatar";
import PreGameLayout from "../../../layouts/PreGameLayout";
import introVideoCover from "../../../assets/img/intro-video.png";

import intro from "../../../assets/music/intro-50.mp3";
import isIOS from "../../../utils/helpers/isIOS";

const Welcome = ({ updateDemoUser, nextPage, translate }) => {
  const introVideoCoverRef = useRef(null);
  const audioRef = useRef(null);
  const [audioPlaying, setAudioPlaying] = useState(false);

  const [userInfo, setUserInfo] = useState({
    name: "",
    avatar: 0,
  });

  const handleSubmit = () => {
    updateDemoUser(userInfo);
    introVideoCoverRef?.current?.classList.remove("over");

    if (audioRef?.current && audioPlaying && audioRef?.current?.volume !== 1)
      fadeOut(nextPage);
    else nextPage();
  };

  const fadeOut = (callback) => {
    if (audioRef.current.volume > 0.001 && !isIOS()) {
      audioRef.current.volume -= 0.001;
      setTimeout(() => fadeOut(callback), 15);
    } else {
      audioRef.current.pause();
      callback();
    }
  };

  return (
    <>
      <img
        src={introVideoCover}
        className="position-absolute vw-100 vh-100 intro-video-cover over"
        alt="End video cover"
        ref={introVideoCoverRef}
      />
      <PreGameLayout
        title={translate("DEMO_WELCOME_TITLE")}
        description={translate("DEMO_WELCOME_DESCRIPTION")}
        className="demo demo-sound-check-fix"
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <SelectAvatar
            handleSelect={(avatar) => setUserInfo({ ...userInfo, avatar })}
            avatar={userInfo.avatar}
            translate={translate}
            isDemo
          />
          <div className="mb-4">
            <Input
              placeholder="Mozart"
              name="name"
              label={translate("DEMO_WELCOME_NAME_INPUT")}
              value={userInfo.name}
              onChange={(e) =>
                setUserInfo({ ...userInfo, name: e.target.value })
              }
              inputProps={{
                maxLength: 11,
              }}
            />
          </div>

          <Button
            onClick={handleSubmit}
            disabled={userInfo?.name === "" || userInfo?.avatar === 0}
          >
            {translate("DEMO_WELCOME_START")}
          </Button>
        </div>

        <audio
          autoPlay={false}
          loop
          muted
          playsInline
          ref={audioRef}
          onLoadStart={(e) => {
            e.target.muted = true;
            e.target.volume = 0;

            setTimeout(() => {
              e.target.play();
            }, 200);
          }}
          onPlay={(e) => {
            e.target.volume = 0.05;
            e.target.muted = false;
            setAudioPlaying(true);
          }}
        >
          <source src={intro} type="audio/mpeg" />
        </audio>
      </PreGameLayout>
    </>
  );
};

export default Welcome;
