import { useState } from "react";
import Modal from "./Modal";
import Button from "./Button";
import Select from "./Select";
import { useEffect } from "react";
import { useTranslate } from "../utils/hooks/useTranslate";
import isDevMode from "../utils/helpers/isDevMode";

import { useSelector } from "react-redux";

const AudioCheck = ({
  title,
  isConnected,
  type,
  values,
  onChange,
  onSuccess,
  onError,
  disabled,
  show,
  setShow,
}) => {
  const [testInterval, setTestInterval] = useState(null);
  const [microphoneLevel, setMicrophoneLevel] = useState(0);
  const { localAudioTrack } = useSelector((state) => state.agora);

  const [doMicrophoneHasSound, setDoMicrophoneHasSound] = useState(
    isDevMode()
  );

  const { translate } = useTranslate();

  useEffect(() => {
    if (localAudioTrack?.stop)
      if (type === "microphone" && show) {
        if (!localAudioTrack.isPlaying) {
          localAudioTrack.play();
        }
        checkMicrophoneVolumeInterval();
      } else {
        clearInterval(testInterval);
        setTestInterval(null);
        localAudioTrack.stop();
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, show, values, localAudioTrack]);

  const checkMicrophoneVolumeInterval = () => {
    clearInterval(testInterval);

    const newTestInterval = setInterval(() => {
      if (localAudioTrack?.getVolumeLevel) {
        const level = localAudioTrack.getVolumeLevel();

        if (level > 0) {
          setDoMicrophoneHasSound(true);
        }

        setMicrophoneLevel(Math.floor(level * 10));
      }
    }, 100);

    setTestInterval(newTestInterval);
  };

  return (
    <div
      className="speech-check col-9 d-flex flex-column flex-md-row align-items-center justify-content-between mb-2 py-3 py-md-0 position-relative"
      data-is-disabled={disabled}
    >
      <p className="mb-0 ms-md-4 pb-2 py-md-2 fw-bold">{title}</p>
      {isConnected ? (
        <p
          className="mb-0 me-md-4 py-md-2 text-success cursor-pointer stretched-link"
          role="button"
          onClick={() => setShow(!show)}
        >
          <span className="me-2">{translate("CONNECTED")}</span>
          <svg
            width="15"
            height="10"
            viewBox="0 0 25 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.1095 0.557028C24.6302 1.07773 24.6302 1.92195 24.1095 2.44265L9.44281 17.1093C8.92211 17.63 8.07789 17.63 7.55719 17.1093L0.890524 10.4426C0.369825 9.92195 0.369825 9.07773 0.890524 8.55703C1.41122 8.03633 2.25544 8.03633 2.77614 8.55703L8.5 14.2809L22.2239 0.557028C22.7446 0.0363291 23.5888 0.0363291 24.1095 0.557028Z"
              fill="#267F61"
            />
          </svg>
        </p>
      ) : (
        <p
          className="mb-0 me-md-4 py-md-2 text-danger pointer cursor-pointer stretched-link"
          role="button"
          onClick={() => setShow(!show)}
        >
          {translate("NOTCONNECTED")}
        </p>
      )}

      <Modal
        title={translate(
          type === "microphone"
            ? "MIC_TEST_MIC_MODAL_TITLE"
            : "MIC_TEST_SPEAKER_MODAL_TITLE"
        )}
        show={show}
        toggleModal={() => setShow(!show)}
      >
        {values && (
          <div className="pt-3">
            <Select
              options={values}
              onChange={onChange}
              title={translate(type === "microphone" ? "MICROPHONE" : "SPEAKER")}
              disabled={type === "audio"}
            />
          </div>
        )}

        {type === "microphone" && (
          <div className="d-flex justify-content-between py-2">
            {Array.from({ length: microphoneLevel }, (_, i) => (
              <div className="volume-box" key={i} />
            ))}
            {Array.from({ length: 15 - parseInt(microphoneLevel) }, (_, i) => (
              <div className="volume-box volume-box-empty" key={i + 15} />
            ))}
          </div>
        )}
        <div className="d-flex justify-content-end mt-4">
          <Button
            className="px-4 py-2 me-2"
            onClick={() => {
              onError();
              setShow(false);
            }}
            hasBorder={false}
          >
            {translate("NO")}
          </Button>
          <Button
            className="px-4 py-2"
            onClick={() => {
              onSuccess();
              setShow(false);
            }}
            disabled={type === "microphone" && !doMicrophoneHasSound}
          >
            {translate("YES")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AudioCheck;
