import { useEffect, useRef, useState } from "react";
import AudioCheck from "../../../components/AudioCheck";
import PreGameLayout from "../../../layouts/PreGameLayout";
import Info from "../../../components/Info";

import { useLocation } from "react-router-dom";
import soundTestFile from "../../../assets/music/intro-50.mp3";

const SoundCheck = ({ nextPage, translate }) => {
  const [audioTest, setAudioTest] = useState({
    output: false,
  });

  const [hasError, setHasError] = useState(false);

  const [show, setShow] = useState(false);

  const audioRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.35;
    }
  }, [audioRef]);

  useEffect(() => {
    if (audioTest.output) {
      nextPage();
    }
  }, [audioTest, nextPage]);

  useEffect(() => {
    if (show) {
      audioRef.current.play();
    } else audioRef.current.pause();
  }, [show]);

  return (
    <PreGameLayout
      title={translate("DEMO_SOUND_TEST_TITLE")}
      description={translate("DEMO_SOUND_TEST_DESCRIPTION")}
      className="demo"
    >
      <div
        className="d-flex flex-column align-items-center justify-content-center mw-100"
        style={{ width: "480px" }}
      >
        <AudioCheck
          title={translate("MIC_TEST_TITLE_OUTPUT")}
          isConnected={audioTest.output}
          type="audio"
          onSuccess={() =>
            setAudioTest((prevState) => ({ ...prevState, output: true }))
          }
          onError={() => setHasError(true)}
          show={show}
          setShow={setShow}
        />

        {hasError && translate("DEMO_SOUND_TEST_ERROR") && (
          <div
            className="col-8 mt-4"
            onClick={() => {
              window.open(location.pathname.includes("/en/") ? `/faq` : `/sss`, "_blank");
            }}
            role="button"
          >
            <Info type="error" text={translate("DEMO_SOUND_TEST_ERROR")}/>
          </div>
        )}
      </div>

      <audio src={soundTestFile} loop autoPlay={false} ref={audioRef}/>
    </PreGameLayout>
  );
};

export default SoundCheck;
