import { useEffect, useState } from "react";

import introSong from "../../assets/music/intro-50.mp3";
import { useDispatch, useSelector } from "react-redux";
import {
  init,
  joinRoom,
  leaveRoom,
  pausePlaySingleAudioFile,
  playSingleAudioFile,
  playSingleAudioFileEnd,
} from "../../utils/redux/Agora/agoraSlice";
import API from "../../utils/API";
import { useTranslate } from "../../utils/hooks/useTranslate";

const AgoraTest = ({ setConnectionState, playSong }) => {
  const [isAgoraConnecting, setIsAgoraConnecting] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const { client, audioFileTrack } = useSelector((state) => state.agora);

  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    if (client?.channelName) dispatch(leaveRoom());

    dispatch(init());

    API.getData("Listener/BasicSoundCall").then(({ data: res }) => {
      const { agoraInfo } = res;

      const options = {
        appId: agoraInfo.appId,
        channel: agoraInfo.channel,
        token: agoraInfo.token,
        uid: agoraInfo.uid,
        unmuted: true,
      };

      dispatch(joinRoom(options));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (client) {
      client.on("connection-state-change", (state) => {
        setConnectionState(state);
        if (state === "CONNECTED") {
          setIsSuccess(true);
          setIsAgoraConnecting(false);
        } else if (state === "DISCONNECTED") {
          setIsSuccess(false);
          setIsAgoraConnecting(false);
        } else if (state === "CONNECTING") {
          setIsAgoraConnecting(true);
        }
      });
    }
  }, [client, dispatch, setConnectionState]);

  useEffect(() => {
    if (playSong && !audioFileTrack?.isPlaying && !isPlaying) {
      setIsPlaying(true);
      dispatch(
        playSingleAudioFile({
          track: introSong,
          loop: true,
          onEnd: () => {
            dispatch(playSingleAudioFileEnd());
          },
        })
      );
    } else if (!playSong && audioFileTrack?.isPlaying && isPlaying) {
      setIsPlaying(false);
      dispatch(pausePlaySingleAudioFile({ callback: null }));
    }
  }, [audioFileTrack?.isPlaying, dispatch, playSong, isPlaying]);

  return (
    <div className="text-white m-1" style={{ height: "34px", cursor: "default" }}>
      {isAgoraConnecting ? (
        <span className="d-flex align-items-center justify-content-start">
          <span className="spinner-grow text-primary m-0" role="status" />{" "}
          <span className="ms-2">{translate("AUDIO_TEST_CONNECTING")}</span>
        </span>
      ) : isSuccess ? (
        <span className="d-flex align-items-center justify-content-start">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2401_9)">
              <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#31AF91"
              />
              <path
                d="M13.575 24.3L6.2 16.925C6.1 16.825 6.1 16.675 6.2 16.575L8.325 14.45C8.425 14.35 8.575 14.35 8.675 14.45L13.75 19.525L23.3 9.97499C23.4 9.87499 23.55 9.87499 23.65 9.97499L25.775 12.1C25.875 12.2 25.875 12.35 25.775 12.45L13.925 24.3C13.825 24.4 13.675 24.4 13.575 24.3Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2401_9">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>{" "}
          <span className="ms-2">{translate("AUDIO_TEST_CONNECTED")}</span>
        </span>
      ) : (
        <span className="d-flex align-items-center justify-content-start">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2401_14)">
              <path
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#C93636"
              />
              <path
                d="M25.075 22.6L18.475 16L25.05 9.40001C25.15 9.30001 25.15 9.15001 25.05 9.05001L22.925 6.92501C22.825 6.82501 22.675 6.82501 22.575 6.92501L16 13.525L9.42501 6.95001C9.32501 6.85001 9.17501 6.85001 9.07501 6.95001L6.95001 9.07501C6.85001 9.17501 6.85001 9.32501 6.95001 9.42501L13.5 16L6.92501 22.575C6.82501 22.675 6.82501 22.825 6.92501 22.925L9.05001 25.05C9.15001 25.15 9.32501 25.15 9.40001 25.05L16 18.475L22.575 25.05C22.675 25.15 22.85 25.15 22.95 25.075L25.075 22.95C25.175 22.85 25.175 22.7 25.075 22.6Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2401_14">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>{" "}
          <span className="ms-2">{translate("AUDIO_TEST_FAILED")}</span>
        </span>
      )}
    </div>
  );
};

export default AgoraTest;
