import Card from "../components/Card";
import Logo from "../assets/img/musing-logo.svg";
import FAQ from "../components/FAQ";
import AssistantMio from "../components/AssistantMio";
import { useContext, useEffect } from "react";
import API from "../utils/API";
import { AppContext } from "../context/AppContext";
import { API_GAME_PAGES } from "../utils/constants/GAME_PAGES";
import getKeyByValue from "../utils/helpers/getKeyByValue";
import PageLoader from "../utils/components/PageLoader";

const PreGameLayout = ({
  title,
  description,
  children,
  className,
}) => {
  const { updateMio, gameState, app } = useContext(AppContext);

  useEffect(() => {
    updateMio([]);

    if (window.location.pathname.includes("demo")) return;

    const currentPage = getKeyByValue(API_GAME_PAGES, gameState?.page);

    if (currentPage) {
      API.getData(`MioMessage?screenName=${currentPage}`).then(({ data }) => {
        if (!data && !data?.length) updateMio([]);
        updateMio(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState?.page]);

  return (
    <div
      className={`pre-game-layout container-fluid main d-flex flex-column align-items-center justify-content-between justify-content-md-end ${className}`}
    >

      {!app.viewedWelcomeInfo && <PageLoader />}
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-10 col-lg-7">
          <Card className="d-flex flex-column align-items-center justify-content-center">
            <img className="logo" width={500} src={Logo} alt="Musing Logo" />
            {title || description ? (
              <div className="d-flex flex-column align-items-center mb-2 mx-2">
                {title && (
                  <h1
                    className="card-page-title text-center mb-2"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                )}
                {description && (
                  <p
                    className="card-page-description mb-0 text-center"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
              </div>
            ) : (
              ``
            )}
            <div className="content-holder mh-100 w-100 overflow-auto d-flex justify-content-center align-items-start py-3">
              {children}
            </div>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-start align-items-center mb-2 ms-md-4 mb-md-4">
            <FAQ />
            <AssistantMio />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreGameLayout;
