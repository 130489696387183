import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router";
import Card from "../../components/Card";
import Header from "../../components/Header";
import API from "../../utils/API";
import { useTranslate } from "../../utils/hooks/useTranslate";

import Button from "../../components/Button";
import { Room } from "./components/Room";
import CircleButton from "../../components/CircleButton";

import MutedIcon from "../../assets/icons/muted.svg";
import UnmutedIcon from "../../assets/icons/unmuted.svg";

import toastr from "toastr";
import { AgoraClient } from "../../components/AgoraComponent";
import AgoraRTC from "agora-rtc-sdk-ng";
import { default as CountdownComponent } from "react-countdown";
import dayjs from "dayjs";

const Listener = () => {
  const [gameInfo, setGameInfo] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [joinedRoom, setJoinedRoom] = useState(null);
  const [isAgoraConnected, setIsAgoraConnected] = useState(false);
  const [countdownStarted, setCountdownStarted] = useState(false);
  const [lastFetch, setLastFetch] = useState(null);
  const [countdown, setCountdown] = useState(0);

  const [audioTrack, setAudioTrack] = useState(null);

  const { translate } = useTranslate();

  const { tokenString } = useParams();

  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    getGameInfo().then(({ data: res }) => {
      setGameInfo({ name: res.gameName, startDate: res.gameStartDate });
      setRooms(res.rooms);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenString]);

  useEffect(() => {
    const gameInfoInterval = setInterval(() => {
      getGameInfo().then(({ data: res }) => {
        setRooms(res.rooms);
        setLastFetch(new Date().getTime());
      });
    }, 5000);

    return () => {
      clearInterval(gameInfoInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (joinedRoom?.isEnded && !countdownStarted) {
      const localTimeout = localStorage.getItem("ListenerGameOverTimeout");

      let timeOffset = 0;

      if (localTimeout) {
        const { roomId, timeout } = JSON.parse(localTimeout);

        if (roomId === joinedRoom.roomId) {
          timeOffset = new Date().getTime() - timeout;
        }
      } else {
        localStorage.setItem(
          "ListenerGameOverTimeout",
          JSON.stringify({
            roomId: joinedRoom.roomId,
            timeout: new Date().getTime(),
          })
        );
      }

      if (timeOffset > 17 * 60 * 1000)
        setTimeout(() => {
          toastr.info(translate("THREE_MINUTES_UNTIL_AUDIO_DISCONNECTION"));
        }, 17 * 60 * 1000 - timeOffset); // 17 minutes

      setTimeout(async () => {
        await handleLeaveRoom();
        toastr.info(
          `${translate("GAME_OVER")}, ${translate("YOU_LEFT_THE_GAME")}`
        );
      }, 20 * 60 * 1000 - timeOffset); // 20 minutes

      setCountdownStarted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownStarted, joinedRoom, rooms, translate]);

  useEffect(() => {
    if (joinedRoom?.roomId) {
      const lastFetchedDifference = lastFetch
        ? dayjs().diff(dayjs(lastFetch), "seconds")
        : 0;
      const countdown = joinedRoom?.roomId
        ? dayjs()
            .add(joinedRoom.remainingSeconds - lastFetchedDifference, "seconds")
            .unix() * 1000
        : 0;
      setCountdown(countdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedRoom]);

  const initTracks = async () => {
    const track = await AgoraRTC.createMicrophoneAudioTrack();
    track?.setMuted(true);
    setAudioTrack(track);
    setIsMuted(true);

    return [track];
  };

  const handleUserJoined = (user) => {
    console.log("user joined", user?.uid);
  };

  const handleUserLeft = (user) => {
    console.log("user left", user?.uid);
  };

  const handleUpdateUserInfo = (user, event) => {
    console.log("user info updated", user, event);
  };

  const subscribe = async (user, mediaType) => {
    await AgoraClient.subscribe(user, mediaType);
    console.log("subscribe success", user?.uid);

    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  };

  const handleUserPublished = (user, mediaType) => {
    if (mediaType === "audio") {
      console.log("user published", user?.uid);

      subscribe(user, mediaType);
    }
  };

  const handleUserUnpublished = (user, mediaType) => {
    if (mediaType === "audio") {
      console.log("user-unpublished", user?.uid);
    }
  };

  const getGameInfo = async () => {
    return API.getData(`Listener?gameToken=${tokenString}`);
  };

  const handleJoinRoom = (roomId) => {
    API.postData(`Listener?gameToken=${tokenString}&roomId=${roomId}`).then(
      ({ data: res }) => {
        join(res.agoraInfo);
        const joinedRoom = rooms.find((room) => room.roomId === roomId);
        setJoinedRoom(joinedRoom);
      }
    );
  };

  const handleLeaveRoom = async () => {
    // Leave the channel.

    await AgoraClient.leave();
    setJoinedRoom(null);
    setIsAgoraConnected(false);

    AgoraClient.off("user-published", handleUserPublished);
    AgoraClient.off("user-unpublished", handleUserUnpublished);
    AgoraClient.off("user-joined", handleUserJoined);
    AgoraClient.off("user-left", handleUserLeft);
    AgoraClient.off("user-info-updated", handleUpdateUserInfo);

    getGameInfo().then(({ data: res }) => {
      setRooms(res.rooms);
      setLastFetch(new Date().getTime());
    });
  };

  const join = async (optionsData) => {
    if (AgoraClient?.connectionState !== "DISCONNECTED") return;
    if (!optionsData) return;

    AgoraClient.on("user-published", handleUserPublished);
    AgoraClient.on("user-unpublished", handleUserUnpublished);
    AgoraClient.on("user-joined", handleUserJoined);
    AgoraClient.on("user-left", handleUserLeft);
    AgoraClient.on("user-info-updated", handleUpdateUserInfo);

    await AgoraClient.join(
      optionsData.appId,
      optionsData.channel,
      optionsData.token || null,
      optionsData.uid || null
    );

    const tracks = await initTracks();
    await AgoraClient.publish(tracks);
    console.log("publish success");
    setIsAgoraConnected(true);
  };

  const handleToggleMute = () => {
    if (!audioTrack) return;

    audioTrack.setMuted(!isMuted);
    setIsMuted(!isMuted);
  };

  if (!tokenString) return <Navigate to="/" />;

  return (
    <div>
      <div className="second-main container-fluid d-flex flex-column align-items-center justify-content-between">
        <Header onlyTitle title={gameInfo?.name} />
        <div className="col-8">
          <Card className="align-items-center justify-content-start">
            <div className="rooms d-grid" data-has-joined={joinedRoom?.roomId}>
              {rooms?.map((room, index) => (
                <Room
                  key={room?.roomId}
                  id={room?.roomId}
                  capacity={room?.capacity}
                  players={room?.gamerCount}
                  name={room?.roomName || `#${index + 1}`}
                  onClick={() => {
                    handleJoinRoom(room.roomId);
                  }}
                  isSelected={joinedRoom?.roomId === room?.roomId}
                  joinedRoom={joinedRoom}
                />
              ))}
            </div>
          </Card>
        </div>
        <footer className="footer d-flex align-items-end justify-content-between">
          <div className="content d-flex flex-column align-items-center">
            <div
              className={`countdown-content text-white justify-content-center align-items-center ${
                joinedRoom?.roomId ? `d-flex` : `d-none`
              }`}
            >
              <CountdownComponent
                date={countdown}
                key={countdown}
                renderer={(props) => (
                  <>
                    <span className="label">
                      {translate("ROOM_COUNT_DOWN")}{" "}
                    </span>
                    <span className="time-left">
                      {String(props.minutes).padStart(2, "0")}:
                      {String(props.seconds).padStart(2, "0")}
                    </span>
                  </>
                )}
                daysInHours={true}
                zeroPadTime={1}
              />
            </div>
            <div className="users-content d-flex flex-wrap align-items-center px-5 justify-content-around">
              <Button
                className="px-5"
                onClick={handleLeaveRoom}
                disabled={!joinedRoom?.roomId}
              >
                {translate("LISTENER_LEAVE")}
              </Button>
            </div>
          </div>
          {isAgoraConnected ? (
            <div className="mute-button position-relative mb-4">
              <CircleButton onClick={handleToggleMute}>
                <img
                  width={30}
                  src={isMuted ? MutedIcon : UnmutedIcon}
                  alt="Mute Icon"
                />
              </CircleButton>
            </div>
          ) : (
            ""
          )}
        </footer>
      </div>
    </div>
  );
};

export default Listener;
