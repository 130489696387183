import { useTranslate } from "../../../utils/hooks/useTranslate";

export const Room = ({
  id,
  name,
  capacity,
  players,
  isSelected,
  onClick,
  joinedRoom,
}) => {
  const { translate } = useTranslate();

  return (
    <div
      className={`room w-100 d-flex flex-column align-items-center justify-content-center ${
        isSelected ? "selected" : ""
      }`}
      key={name}
      onClick={onClick}
    >
      <div
        style={{ "--aspect-ratio": "1/1" }}
        className="d-flex w-100 flex-column align-items-center justify-content-center"
      >
        <div className="content w-100 h-100 d-flex flex-column justify-content-center align-items-center position-absolute">
          <div className="room-user-count">
            <span className="fw-semibold mb-0 text-white">
              {players}/{capacity}
            </span>
          </div>
          <div className="room-name text-white my-2 fw-semibold">{name}</div>
          <div
            className={`room-status px-2 py-1 fw-semibold
            ${joinedRoom?.roomId === id ? "room-status-in-game" : "room-status-ready"}
            `}
          >
            {translate(`LISTENER_${joinedRoom?.roomId === id ? `JOINED` : `JOIN`}`)}
          </div>
        </div>
      </div>
    </div>
  );
};
