import { useContext, useEffect, useState } from "react";
import Info from "../../components/Info";
import AudioCheck from "../../components/AudioCheck";

import PreGameLayout from "../../layouts/PreGameLayout";
import { useTranslate } from "../../utils/hooks/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import { connectDevices, devicesRequest, setInputDevice, setOutputDevice } from "../../utils/redux/Agora/agoraSlice";
import AgoraTest from "../../components/AgoraTest";
import { AppContext } from "../../context/AppContext";
import SocketTest from "../../components/SocketTest";

const ConnectionTest = () => {
  const { translate } = useTranslate();
  const [audioTest, setAudioTest] = useState({
    input: false,
    output: false,
  });

  const [output, setOutput] = useState("");
  const [outputs, setOutputs] = useState([]);

  const [input, setInput] = useState("");
  const [inputs, setInputs] = useState([]);

  const [showAudio, setShowAudio] = useState(false);
  const [showMicrophone, setShowMicrophone] = useState(false);

  const [hasError, setHasError] = useState(false);

  const [connectionState, setConnectionState] = useState("CONNECTING");

  const [socketError, setSocketError] = useState(false);

  const { localAudioTrack, connectedInputDevices, connectedOutputDevices } =
    useSelector((state) => state.agora);

  const { app, setShowFaqModal } = useContext(AppContext);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(devicesRequest());
  }, [dispatch]);

  useEffect(() => {
    const selectedInput = connectedInputDevices?.find(
      (i) => i.isDefault,
    )?.value;

    setInputs(connectedInputDevices);
    setInput(selectedInput);

    dispatch(connectDevices());
  }, [connectedInputDevices, dispatch]);

  useEffect(() => {
    const selectedOutput = connectedOutputDevices?.find(
      (i) => i.isDefault,
    )?.value;
    setOutputs(connectedOutputDevices);
    setOutput(selectedOutput);
  }, [connectedOutputDevices]);

  useEffect(() => {
    if (localAudioTrack && output) {
      setAudioTest((prevState) => ({ ...prevState, output: false }));
      dispatch(setOutputDevice(output));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output]);

  useEffect(() => {
    if (localAudioTrack && input) {
      setAudioTest((prevState) => ({ ...prevState, microphone: false }));
      dispatch(setInputDevice(input));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  return (
    <PreGameLayout>
      <div
        className="d-flex flex-column align-items-center justify-content-center mw-100"
        style={{ width: "480px" }}
      >
        <div className="col-9 speech-check">
          <SocketTest onError={err => setSocketError(err)}/>
        </div>
        <div className="col-9 speech-check my-2">
          <AgoraTest
            setConnectionState={setConnectionState}
            connectionState={connectionState}
            playSong={showAudio}
          />
        </div>
        <AudioCheck
          title={translate("AUDIO_TEST_SPEAKER")}
          isConnected={audioTest.output}
          type="audio"
          value={output}
          values={outputs}
          onChange={(e) => setOutput(e.target.value)}
          onSuccess={() =>
            setAudioTest((prevState) => ({ ...prevState, output: true }))
          }
          onError={() => setHasError(true)}
          disabled={connectionState !== "CONNECTED"}
          show={showAudio}
          setShow={setShowAudio}
        />
        <AudioCheck
          title={translate("AUDIO_TEST_MICROPHONE")}
          isConnected={audioTest.input}
          type="microphone"
          value={input}
          values={inputs}
          onChange={(e) => setInput(e.target.value)}
          onSuccess={() =>
            setAudioTest((prevState) => ({ ...prevState, input: true }))
          }
          onError={() => setHasError(true)}
          disabled={connectionState !== "CONNECTED"}
          show={showMicrophone}
          setShow={setShowMicrophone}
        />

        {hasError && translate("MIC_TEST_ERROR") && (
          <div
            className="col-8 mt-4"
            onClick={() => {
              setShowFaqModal(!app.showFaqModal);
            }}
            role="button"
          >
            <Info type="error" text={translate("MIC_TEST_ERROR")}/>
          </div>
        )}

        {socketError && (
          <div
            className="col-8 mt-4"
            onClick={() => {
              setShowFaqModal(!app.showFaqModal);
            }}
            role="button"
          >
            <Info type="error" text={translate("SOCKET_TEST_ERROR")}/>
          </div>
        )}
      </div>
    </PreGameLayout>
  );
};

export default ConnectionTest;
