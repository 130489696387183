import { useContext, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Card from "../../components/Card";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import GuestAlertModal from "./components/GuestAlertModal";
import GameLayout from "../../layouts/GameLayout";
import { SignalRContext } from "../../context/SignalRContext";
import { AppContext } from "../../context/AppContext";
import { useTranslate } from "../../utils/hooks/useTranslate";

import endVideoCover from "../../assets/img/end-video.png";
import introVideoCover from "../../assets/img/intro-video.png";

import JboxIntro from "../../assets/videos/jbox-intro.mp4";
import FullScreenVideo from "../../components/FullScreenVideo";
import { useDispatch, useSelector } from "react-redux";
import { setWatchedIntroVideo } from "../../utils/redux/Game/gameSlice";

export const Rooms = ({ selectedRoom, setSelectedRoom }) => {
  const { gameState } = useContext(AppContext);
  const [showGuestModal, setShowGuestModal] = useState(false);

  return (
    <Card className="align-items-center justify-content-start">
      <div className="rooms d-grid">
        {gameState?.rooms?.map((room, index) => (
          <Room
            key={room?.roomId}
            id={room?.roomId}
            inGame={room?.isStarted}
            capacity={room?.capacity}
            players={room?.gamerCount}
            name={room?.roomName || `#${index + 1}`}
            onClick={() => {
              if (room?.isStarted || room?.gamerCount === room?.capacity) {
                setShowGuestModal(true);
              }

              if (room?.roomId === selectedRoom) {
                setSelectedRoom(null);
              } else {
                setSelectedRoom(room?.roomId);
              }
            }}
            isSelected={selectedRoom === room?.roomId}
          />
        ))}
        <AddRoom/>
      </div>

      <GuestAlertModal
        show={showGuestModal}
        roomId={selectedRoom}
        onClose={() => {
          setSelectedRoom(null);
          setShowGuestModal(false);
        }}
      />
    </Card>
  );
};

export const Room = ({
  inGame = false,
  name,
  capacity,
  players,
  isSelected,
  onClick,
}) => {
  const { translate } = useTranslate();

  return (
    <div
      className={`room w-100 d-flex flex-column align-items-center justify-content-center ${
        isSelected ? "selected" : ""
      }`}
      key={name}
      onClick={onClick}
    >
      <div
        style={{ "--aspect-ratio": "1/1" }}
        className="d-flex w-100 flex-column align-items-center justify-content-center"
      >
        <div
          className="content w-100 h-100 d-flex flex-column justify-content-center align-items-center position-absolute">
          <div className="room-user-count">
            <span className="fw-semibold mb-0 text-white">
              {players}/{capacity}
            </span>
          </div>
          <div className="room-name text-white my-2 fw-semibold">{name}</div>
          <div
            className={`room-status px-2 py-1 fw-semibold
        ${
              inGame || capacity === players
                ? "room-status-in-game"
                : "room-status-ready"
            }
        `}
          >
            {translate(
              capacity === players
                ? "ROOM_LIST_FULL"
                : inGame
                  ? "ROOM_LIST_IN_GAME"
                  : "ROOM_LIST_READY",
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const AddRoom = () => {
  const { connection } = useContext(SignalRContext);
  const { translate } = useTranslate();

  const handleAddRoom = () => {
    connection.send("CreateRoom");
  };

  return (
    <div
      className="add-room room w-100 d-flex flex-column align-items-center justify-content-center"
      onClick={handleAddRoom}
      role="button"
    >
      <div
        style={{ "--aspect-ratio": "1/1" }}
        className="d-flex w-100 flex-column align-items-center justify-content-center"
      >
        <div
          className="content w-100 h-100 d-flex flex-column justify-content-center align-items-center position-absolute">
          <div className="add-room-icon mb-3 p-2">
            <img className="w-100" src={PlusIcon} alt="add"/>
          </div>
          <div className="add-room-text text-white fw-semibold">
            {translate("ROOM_LIST_CREATE")}
          </div>
        </div>
      </div>
    </div>
  );
};

const RoomSelect = ({ translate }) => {
  const [guestAlertModal, setGuestAlertModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const [playVideo, setPlayVideo] = useState(true);

  const [startFadeOut, setStartFadeOut] = useState(false);

  const endVideoCoverRef = useRef(null);
  const introVideoCoverRef = useRef(null);

  const { gameState } = useContext(AppContext);
  const { joinRoom } = useContext(SignalRContext);
  const {watchedIntroVideo} = useSelector((state) => state.game);
  const dispatch = useDispatch();

  useEffect(() => {
    if (gameState?.connected && playVideo && !watchedIntroVideo)
      setTimeout(() => {
        endVideoCoverRef.current.classList.add("over");
      }, 1000);
  }, [gameState?.connected, playVideo, watchedIntroVideo]);

  useEffect(() => {
    if (watchedIntroVideo) {
      setPlayVideo(false);
    }
  }, [watchedIntroVideo])

  const fadeOut = (e) => {
    if (e.target.volume > 0.05) {
      e.target.volume -= 0.05;
      setTimeout(() => fadeOut(e), 100);
    }
  };

  return (
    <GameLayout>
      {!watchedIntroVideo && <FullScreenVideo
        isIn={playVideo}
        onEnded={() => dispatch(setWatchedIntroVideo())}
        onPlay={() => {
          introVideoCoverRef.current.classList.add("over");
        }}
        onTimeUpdate={(e) => {
          if (e.target.currentTime >= e.target.duration - 1.8) {
            if (!startFadeOut) {
              setStartFadeOut(true);
              fadeOut(e);
            }
          }
        }}
        src={JboxIntro}
      />}

      {!watchedIntroVideo && <img
        src={introVideoCover}
        className="position-absolute vw-100 vh-100 intro-video-cover"
        alt="End video cover"
        ref={introVideoCoverRef}
      />}
      {!watchedIntroVideo && <img
        src={endVideoCover}
        className="position-absolute vw-100 vh-100 end-video-cover over"
        alt="End video cover"
        ref={endVideoCoverRef}
      />}
      <div className="second-main container-fluid d-flex flex-column align-items-center justify-content-between">
        <Header
          onlyTitle
          title={translate("ROOM_LIST_TITLE")}
          extraTitle={gameState?.headerPlayerInfo}
        />
        <div className="col-8">
          <Rooms
            setSelectedRoom={(value) => setSelectedRoom(value)}
            selectedRoom={selectedRoom}
          />
        </div>
        <GuestAlertModal
          show={guestAlertModal}
          onClose={() => setGuestAlertModal(false)}
          roomId={selectedRoom}
        />
        <Footer
          contentType="singleButton"
          buttonText={translate("ROOM_LIST_JOIN_ROOM")}
          disableSingleButton={!selectedRoom}
          hideMicrophone={true}
          showTimer={false}
          onClick={() => {
            joinRoom(selectedRoom);
          }}
        />
      </div>
    </GameLayout>
  );
};

export default RoomSelect;
