import { useContext } from "react";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { SignalRContext } from "../../context/SignalRContext";
import GameLayout from "../../layouts/GameLayout";

const RoomStart = ({ translate }) => {
  const { startGame } = useContext(SignalRContext);

  return (
    <GameLayout>
      <div className="second-main container-fluid d-flex flex-column align-items-center justify-content-between">
        <Header />
        <div className="col-10 col-lg-8">
          <Card className="d-flex flex-column align-items-center justify-content-between overflow-auto py-3">
            <div className="d-flex flex-column align-items-center pt-5 px-4 mt-auto mb-auto">
              {translate("ROOM_START_TITLE") && (
                <h1
                  className="mb-2 text-center"
                  dangerouslySetInnerHTML={{
                    __html: translate("ROOM_START_TITLE"),
                  }}
                />
              )}
              {translate("ROOM_START_DESCRIPTION") && (
                <p
                  className="description fs-5 pt-3 text-center px-5"
                  dangerouslySetInnerHTML={{
                    __html: translate("ROOM_START_DESCRIPTION"),
                  }}
                />
              )}
            </div>
            <Button onClick={startGame}>
              {translate("ROOM_START_START_GAME")}
            </Button>
          </Card>
        </div>

        <Footer
          contentType="default"
          showTimer={false}
          onClick={() => {
            return;
          }}
        />
      </div>
    </GameLayout>
  );
};

export default RoomStart;
