import { AgoraClient } from "../../components/AgoraComponent";
import { store } from "../../store";
import { setAudioFileTracks } from "../redux/Agora/agoraSlice";

const pauseMultipleAudioFiles = async ({
  audioFileTracks,
  index,
  pause,
  callback,
}) => {
  if (!audioFileTracks[index]) return;

  const dispatch = store.dispatch;
  if (!dispatch) return;

  if (pause) {
    audioFileTracks[index].pauseProcessAudioBuffer();
    audioFileTracks[index].stop();
    await AgoraClient.unpublish(audioFileTracks[index]);
  } else {
    audioFileTracks[index]?.play();
    audioFileTracks[index].resumeProcessAudioBuffer();
    await AgoraClient.publish(audioFileTracks[index]);
  }

  dispatch(setAudioFileTracks(audioFileTracks));
  callback && callback();
};

export default pauseMultipleAudioFiles;
