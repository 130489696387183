import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraClient } from "../../components/AgoraComponent";
import { store } from "../../store";
import { setAudioFileTracks } from "../redux/Agora/agoraSlice";

const playMultipleAudioFiles = async ({
  tracks,
  setCurrentIndex,
  offset,
  onTracksEnd,
  updateGameState,
  gameState,
  callback,
}) => {
  if (tracks.length === 0) return;

  const dispatch = store.dispatch;

  if (!dispatch) return;

  const newAudioFileTracks = await Promise.all(
    tracks.map(async (track) => {
      const audioFileTrack = await AgoraRTC.createBufferSourceAudioTrack({
        source: track,
      });

      audioFileTrack?.setVolume(10);

      return audioFileTrack;
    })
  );

  if (newAudioFileTracks.length === 0) return;

  // Start processing the audio data from the audio file.
  newAudioFileTracks[0].startProcessAudioBuffer({ startPlayTime: 0 });

  // Publish audioFileTrack and localAudioTrack together.
  await AgoraClient.publish(newAudioFileTracks[0]);

  newAudioFileTracks[0]?.play();

  if (setCurrentIndex) setCurrentIndex(0 + offset);

  updateGameState &&
    updateGameState({
      player: { ...gameState?.player, isPlayingTrack: true },
    });

  const allAudioFileTracks = newAudioFileTracks.map((audioFileTrack, index) => {
    audioFileTrack.on("source-state-change", async (state) => {
      if (state === "stopped") {
        audioFileTrack.stopProcessAudioBuffer();
        audioFileTrack.off("source-state-change");

        if (index < newAudioFileTracks.length - 1) {
          setTimeout(async () => {
            newAudioFileTracks[index + 1].startProcessAudioBuffer({
              startPlayTime: 0,
            });

            await AgoraClient.publish(newAudioFileTracks[index + 1]);

            newAudioFileTracks[index + 1]?.play();

            if (setCurrentIndex) setCurrentIndex(index + 1 + offset);
          }, 1);
        } else {
          updateGameState &&
            updateGameState({
              player: {
                ...gameState?.player,
                isPlayingTrack: false,
              },
            });

          if (onTracksEnd) onTracksEnd();
        }
      }
    });

    return audioFileTrack;
  });

  dispatch(setAudioFileTracks(allAudioFileTracks));

  callback && callback(allAudioFileTracks);
};

export default playMultipleAudioFiles;
