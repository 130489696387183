import NOTES from "../../../utils/constants/NOTES";

const Note = ({ note, isSelected, selectedOrder, customId }) => {
  return (
    <div
      className="note-holder mh-100 position-relative d-flex justify-content-center align-items-center"
      data-is-selected={isSelected}
      id={customId || ""}
    >
      <div className="note-border note-border--1 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--2 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--3 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--4 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--5 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--6 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--7 w-100 h-100 position-absolute"></div>

      <img
        src={NOTES[note?.iconId] || NOTES[0]}
        alt={`Note ${note?.partId}`}
        className="note position-absolute"
      />

      {selectedOrder > 0 && (
        <div className="note-selected-order position-absolute fw-bold">
          {parseInt(selectedOrder)}
        </div>
      )}
      {process.env.REACT_APP_DEV_MODE === "true" ? (
        <span className="text-white position-absolute" style={{ bottom: 0 }}>
          {note?.originalOrder}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Note;
