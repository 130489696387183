import { useEffect } from "react";
import { useContext, useState } from "react";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Input from "../../components/Input";
import { AppContext } from "../../context/AppContext";
import { SignalRContext } from "../../context/SignalRContext";
import GameLayout from "../../layouts/GameLayout";

const RoomName = ({ translate }) => {
  const [roomName, setRoomName] = useState("");

  const { gameState, updateRoomState, roomState } = useContext(AppContext);
  const { connection } = useContext(SignalRContext);

  useEffect(() => {
    if (roomState?.name) {
      setRoomName(roomState?.name);
    }
  }, [roomState?.name]);

  const handleSetRoomName = () => {
    connection.send("SetRoomName", roomName);
    updateRoomState({ name: roomName });
  };

  return (
    <GameLayout>
      <div className="second-main container-fluid d-flex flex-column align-items-center justify-content-between">
        <Header />
        <div className="col-10 col-lg-8">
          <Card className="d-flex flex-column align-items-center justify-content-center overflow-auto py-3">
            <div className="d-flex flex-column align-items-center pt-5 px-4">
              <h1
                className="mb-2"
                dangerouslySetInnerHTML={{
                  __html: translate("MODERATOR_HELLO").replace(
                    "$gamer",
                    gameState?.player?.name
                  ),
                }}
              />
              <p
                className="fs-5 py-3 text-center"
                dangerouslySetInnerHTML={{
                  __html: translate("MODERATOR_DESCRIPTION"),
                }}
              />
            </div>
            <div className="mb-5">
              <Input
                placeholder="Beatles"
                label={translate("MODERATOR_ROOM_NAME")}
                value={roomName}
                onChange={({ target }) => setRoomName(target.value)}
              />
            </div>
            <Button onClick={handleSetRoomName} disabled={roomName?.length < 1}>
              {translate("MODERATOR_CONTINUE")}
            </Button>
          </Card>
        </div>

        <Footer contentType="default" showTimer={false} />
      </div>
    </GameLayout>
  );
};

export default RoomName;
