import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import AVATARS from "../utils/constants/AVATARS";
import { ModalContext } from "../context/ModalContext";

export const AvatarModal = ({
  translate,
  isDemo,
  handleSelect,
  hideModal,
  selectedAvatar,
  setSelectedAvatar,
}) => {
  const [currentAvatar, setCurrentAvatar] = useState(selectedAvatar);
  return (
    <div className="select-avatar-content d-flex flex-column overflow-hidden h-100">
      <h2 className="text-center mb-4 flex-shrink-0">
        {translate(`${isDemo ? `DEMO` : `GAMER_INFO`}_SELECT_AVATAR`)}
      </h2>
      <div className="d-flex flex-wrap align-items-center justify-content-center h-100 overflow-auto">
        {Object.keys(AVATARS)
          .filter((key) => key !== "default")
          .map((key) => (
            <div
              className={`avatar mx-2 mb-4 overflow-hidden ${
                currentAvatar === key ? "selected-avatar" : ""
              }`}
              onClick={() => setCurrentAvatar(key)}
              key={key}
            >
              <img src={AVATARS[key]} alt="Default Avatar" />
            </div>
          ))}
      </div>
      <div className="w-100 d-flex justify-content-end mt-1">
        <Button
          onClick={() => {
            setSelectedAvatar(currentAvatar);
            handleSelect(currentAvatar);
            hideModal();
          }}
          disabled={currentAvatar === 0}
        >
          {translate(`${isDemo ? `DEMO` : `GAMER_INFO`}_CONFIRM_AVATAR`)}
        </Button>
      </div>
    </div>
  );
};

const SelectAvatar = ({ avatar, handleSelect, translate, isDemo }) => {
  const [selectedAvatar, setSelectedAvatar] = useState(avatar);

  const { showModal, hideModal } = useContext(ModalContext);

  useEffect(() => {
    if (!showModal && selectedAvatar !== avatar) {
      setSelectedAvatar(avatar);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <div className="row d-flex flex-column align-items-center avatar-selecter-holder">
      <div
        className="select-avatar d-flex flex-column align-items-center justify-content-center overflow-hidden"
        onClick={() =>
          showModal({
            content: (
              <AvatarModal
                handleSelect={handleSelect}
                hideModal={hideModal}
                isDemo={isDemo}
                selectedAvatar={selectedAvatar}
                setSelectedAvatar={setSelectedAvatar}
                translate={translate}
              />
            ),
            className: "avatar-selecter-holder modal-fix",
          })
        }
        role="button"
      >
        {selectedAvatar ? (
          <img
            src={selectedAvatar ? AVATARS[selectedAvatar] : AVATARS["default"]}
            alt="avatar"
            className="select-avatar-image"
          />
        ) : (
          ""
        )}
      </div>
      <Button
        className="select-avatar-button"
        onClick={() =>
          showModal({
            content: (
              <AvatarModal
                handleSelect={handleSelect}
                hideModal={hideModal}
                isDemo={isDemo}
                selectedAvatar={selectedAvatar}
                setSelectedAvatar={setSelectedAvatar}
                translate={translate}
              />
            ),
            className: "avatar-selecter-holder modal-fix",
          })
        }
      >
        {translate(`${isDemo ? `DEMO` : `GAMER_INFO`}_SELECT_AVATAR`)}
      </Button>
    </div>
  );
};

export default SelectAvatar;
