import { useState } from "react";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import Star from "../../../assets/icons/star.svg";
import FilledStar from "../../../assets/icons/filled-star.svg";
import Textarea from "../../../components/Textarea";
import { useTranslate } from "../../../utils/hooks/useTranslate";
import API from "../../../utils/API";
import toastr from "toastr";

const FeedbackModal = ({ show, onClose }) => {
  const [stars, setStars] = useState(0);
  const [comment, setComment] = useState("");
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { translate } = useTranslate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (stars === 0) return;

    setIsSending(true);

    const data = {
      starCount: stars,
      text: comment,
    };

    API.postData("Opinion", data)
      .then(() => {
        toastr.clear();
        toastr.success(translate("MODAL_OPINION_SUCCESS"));

        setTimeout(onClose, 1250);

        setTimeout(() => {
          setIsFormDisabled(true);

          setIsSending(false);
        }, 1300);
      })
      .catch(() => {
        toastr.clear();
        toastr.error("Something went wrong. Please try again later.");

        setIsSending(false);
      });
  };

  return (
    <Modal show={show} toggleModal={onClose}>
      <div className="FeedbackModal">
        <div className="d-flex flex-column align-items-center">
          <p className="fw-bold h4 text-center my-3">
            {translate("MODAL_OPINION_TITLE")}
          </p>
          <p
            className="text-center px-4 mb-3"
            dangerouslySetInnerHTML={{
              __html: translate("MODAL_OPINION_DESCRIPTION"),
            }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          {Array.from({ length: 5 }, (_, i) => (
            <img
              key={i}
              width={70}
              src={i < stars ? FilledStar : Star}
              alt="Star"
              onClick={() => (isFormDisabled ? false : setStars(i + 1))}
              role="button"
              data-disabled={isFormDisabled || isSending}
              className="stars"
            />
          ))}
        </div>
        <div>
          <Textarea
            placeholder={translate("MODAL_OPINION_TEXTAREA")}
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            readOnly={isFormDisabled || isSending}
            disabled={isFormDisabled || isSending}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            onClick={isFormDisabled ? onClose : handleSubmit}
            disabled={isSending}
          >
            {isFormDisabled
              ? translate("MODAL_OPINION_CLOSE")
              : translate("MODAL_OPINION_SEND")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
