import { useContext, useState } from "react";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import DIFFICULTIES from "../../../utils/constants/DIFFICULTIES";
import { AppContext } from "../../../context/AppContext";
import { SignalRContext } from "../../../context/SignalRContext";
import { useTranslate } from "../../../utils/hooks/useTranslate";

const SelectDifficultyModal = ({ show, type = "SetRoomLevel", stopSong }) => {
  const { updateApp, updateRoomState } = useContext(AppContext);
  const { connection } = useContext(SignalRContext);

  const { translate } = useTranslate();

  const [selectedDifficulty, setSelectedDifficulty] = useState(1);
  return (
    <Modal show={show} hideCloseButton={true}>
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="d-flex flex-column my-1 w-100">
          <p className="fw-bold fs-2 text-center px-4">
            {translate("ROOM_LEVEL_TITLE")}
          </p>
          <p
            className="text-center fs-4"
            dangerouslySetInnerHTML={{
              __html: translate("ROOM_LEVEL_DESCRIPTION"),
            }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between fs-1 fw-bold mb-4 w-100">
          {DIFFICULTIES.map((difficult, index) => (
            <div
              className="musing-checkbox"
              onClick={() => setSelectedDifficulty(difficult.code)}
              key={index}
              data-is-selected={difficult.code === selectedDifficulty}
            >
              <div className="circle"></div>
              <p className="mb-0 ms-2">
                {translate(`ROOM_LEVEL_${difficult.name}`)}
              </p>
            </div>
          ))}
        </div>
        <Button
          onClick={() => {
            connection.send(type, selectedDifficulty);
            stopSong();
            updateRoomState({
              level: selectedDifficulty,
            });
            updateApp({ modal: null });
          }}
        >
          {translate("ROOM_LEVEL_CONTINUE")}
        </Button>
      </div>
    </Modal>
  );
};

export default SelectDifficultyModal;
