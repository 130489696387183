import { useContext } from "react";
import GameLayout from "../../layouts/GameLayout";
import GAME_PAGES from "../../utils/constants/GAME_PAGES";
import { AppContext } from "../../context/AppContext";
import { useTranslate } from "../../utils/hooks/useTranslate";

const GameContainer = () => {
  const { gameState } = useContext(AppContext);
  const { translate } = useTranslate();

  const Component = GAME_PAGES[gameState.page];
  return (
    <GameLayout>
      <Component translate={translate} />
    </GameLayout>
  );
};

export default GameContainer;
