import InfoIcon from "../assets/icons/info.svg";
import InfoRedIcon from "../assets/icons/info-red.svg";

const Info = ({ type = "info", text }) => {
  return (
    <div className="info d-flex align-items-center justify-content-center py-2 px-3">
      <img
        className="info-icon"
        src={type === "error" ? InfoRedIcon : InfoIcon}
        alt="Info Icon"
      />
      <p className="ms-2 mb-0" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default Info;
