import loading from "../../../assets/videos/loading.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import { useTranslate } from "../../hooks/useTranslate";

const PageLoader = () => {
  const { updateApp } = useContext(AppContext);

  const { translate } = useTranslate();

  useEffect(() => {
    setTimeout(() => {
      updateApp({ viewedWelcomeInfo: true });
    }, 3500);
  }, [updateApp]);

  return (
    <div
      className="vw-100 vh-100 d-flex justify-content-center align-items-center flex-column position-absolute top-0"
      style={{
        zIndex: 9999,
        background: "linear-gradient(to top, #6554c0fc, #e85ac0fc)",
      }}
    >
      <Player
        autoplay
        loop
        src={loading}
        style={{ height: "300px", width: "300px" }}
        controls={false}
      ></Player>
      <h5 className="mt-3 text-center">{translate("LOADING_TIP_TEXT")}</h5>
    </div>
  );
};

export default PageLoader;
