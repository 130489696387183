import { useEffect } from "react";
import { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";
import DIFFICULTIES from "../utils/constants/DIFFICULTIES";
import { useTranslate } from "../utils/hooks/useTranslate";

const Header = ({ title = "Musing", onlyTitle = false, extraTitle = null }) => {
  const { roomState } = useContext(AppContext);
  const { translate } = useTranslate();

  const [difficulty, setDifficulty] = useState(null);
  const [flashingExtraTitle, setFlashingExtraTitle] = useState(false);

  useEffect(() => {
    setDifficulty(DIFFICULTIES.find((d) => roomState?.difficulty === d?.code));
  }, [roomState?.difficulty]);

  useEffect(() => {
    const el = document.querySelector(".extra-title");

    if (!el) return;

    const toggleFlashing = () => {
      setFlashingExtraTitle(!flashingExtraTitle);

      if (el.classList.contains("flashing")) {
        el.classList.remove("flashing");
        el.removeEventListener("animationend", toggleFlashing);
      } else {
        el.classList.add("flashing");
      }
    };

    if (extraTitle) {
      toggleFlashing();
      el.addEventListener("animationend", toggleFlashing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraTitle]);

  return (
    <div className="header position-relative">
      <div className="container h-100">
        <div className="row align-items-center h-100">
          {!onlyTitle && (
            <div className="col-4 d-flex justify-content-start align-items-center">
              <p className="mb-0 fw-bold fs-5 d-flex flex-column flex-lg-row align-items-center">
                {translate("ROOM_SCORE")}{" "}
                <span className="score fw-light ms-2">{roomState?.score}</span>
              </p>
            </div>
          )}
          <div
            className={`${
              onlyTitle ? `col-12` : `col-4`
            } d-flex justify-content-center align-items-center`}
          >
            <p className="mb-0 fw-bold fs-3 text-center">
              {onlyTitle ? title : roomState?.name || title}
            </p>
          </div>

          {!onlyTitle && (
            <div className="col-4 d-flex justify-content-end align-items-center">
              <p className="mb-0 fw-bold fs-5 d-flex flex-column flex-lg-row align-items-center">
                {translate("ROOM_DIFFICULTY")}{" "}
                <span className="difficulty fw-light ms-2">
                  {difficulty
                    ? translate(`ROOM_LEVEL_${difficulty?.name}`)
                    : ""}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
      {extraTitle && (
        <p className="extra-title m-0 text-end position-absolute">
          {extraTitle || ""}
        </p>
      )}
    </div>
  );
};

export default Header;
