import AgoraRTC from "agora-rtc-sdk-ng";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import { AppContext } from "../../context/AppContext";
import {
  setConnectionState,
  setShowMutedInfo,
  toggleMute,
} from "../../utils/redux/Agora/agoraSlice";
import { useTranslate } from "../../utils/hooks/useTranslate";

export const AgoraClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
AgoraRTC.setLogLevel(2);

const AgoraComponent = () => {
  const { roomState, updateRoomState, app } = useContext(AppContext);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [audioTrack, setAudioTrack] = useState(null);
  const [joined, setJoined] = useState(false);
  const [showToastr, setShowToastr] = useState(false);
  //   const [audioFileTrack, setAudioFileTrack] = useState(null);

  const { isMuted } = useSelector((state) => state.agora);
  const { translate } = useTranslate();

  const dispatch = useDispatch();

  AgoraRTC.onMicrophoneChanged = (info) => handleMicrophoneChanged();

  useEffect(() => {
    AgoraClient.on("connection-state-change", handleConnectionStateChange);

    return () =>
      AgoraClient.off("connection-state-change", handleConnectionStateChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.text]);

  useEffect(() => {
    const showToastr =
      window.location.hostname.includes("localhost") ||
      window.location.hostname.includes("webstage");
    setShowToastr(showToastr);

    if (showToastr) {
      toastr.options = {
        newestOnTop: true,
        positionClass: "toast-bottom-left",
      };
    }
  }, []);

  useEffect(() => {
    updateRoomState({ remoteUsers });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteUsers]);

  useEffect(
    () => () => {
      if (joined) leave();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (roomState?.agora && !joined) join();
    else if (!roomState?.agora && joined) leave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomState?.agora, joined]);

  const initTracks = async () => {
    const track = await AgoraRTC.createMicrophoneAudioTrack();
    track?.setMuted(true);
    setAudioTrack(track);
    dispatch(toggleMute(true));
    dispatch(setShowMutedInfo(true));

    return [track];
  };

  useEffect(() => {
    if (isMuted) mute();
    else unmute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMuted]);

  const handleUserJoined = (user) => {
    console.log("user joined", user?.uid);
    showToastr && toastr.info("user-joined", user?.uid);

    setRemoteUsers((pre) => [
      ...pre,
      { ...user, published: false, muted: true },
    ]);
  };

  const handleUserLeft = (user) => {
    console.log("user left", user?.uid);
    showToastr && toastr.info("user-left", user?.uid);

    setRemoteUsers((pre) => pre?.filter((item) => item.uid !== user.uid));
  };

  const handleUpdateUserInfo = (user, event) => {
    console.log("user info updated", user, event);

    setRemoteUsers((pre) =>
      pre?.map((item) => {
        if (item.uid === user) {
          return { ...item, muted: event === "mute-audio" };
        }

        return item;
      })
    );
  };

  const subscribe = async (user, mediaType) => {
    await AgoraClient.subscribe(user, mediaType);
    console.log("subscribe success", user?.uid);
    showToastr && toastr.info("subscribe success", user?.uid);

    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  };

  const handleUserPublished = (user, mediaType) => {
    if (mediaType === "audio") {
      console.log("user published", user?.uid);
      showToastr && toastr.info("user-published", user?.uid);

      setRemoteUsers((pre) =>
        pre?.map((item) => {
          if (item.uid === user.uid) {
            return { ...item, published: true };
          }

          return item;
        })
      );

      subscribe(user, mediaType);
    }
  };

  const handleUserUnpublished = (user, mediaType) => {
    if (mediaType === "audio") {
      console.log("user-unpublished", user?.uid);
      showToastr && toastr.info("user-unpublished", user?.uid);

      setRemoteUsers((pre) =>
        pre?.map((item) => {
          if (item.uid === user.uid) {
            return { ...item, published: false };
          }

          return item;
        })
      );
    }
  };

  const handleConnectionStateChange = (curState, prevState, reason) => {
    dispatch(setConnectionState(curState));

    if (curState === "RECONNECTING") {
      console.log("Reconnecting...");
      toastr.clear();
      toastr.warning(translate("AGORA_RECONNECTING"), null, {
        timeOut: 0,
        extendedTimeOut: 0,
      });
    }

    if (prevState === "RECONNECTING" && curState === "CONNECTED") {
      console.log("Connected!");
      toastr.clear();
      toastr.success(translate("AGORA_RECONNECTED"));
    }
  };

  const handleMicrophoneChanged = (event) => {
    console.log("microphone-changed", event);
    toastr.warning(translate("AGORA_MICROPHONE_CHANGED"), null, {
      timeOut: 0,
      closeButton: true,
    });
  };

  const join = async () => {
    try {
      if (joined || AgoraClient?.connectionState !== "DISCONNECTED") return;

      const options = roomState?.agora;
      // Add event listeners to the client.
      AgoraClient.on("user-published", handleUserPublished);
      AgoraClient.on("user-unpublished", handleUserUnpublished);
      AgoraClient.on("user-joined", handleUserJoined);
      AgoraClient.on("user-left", handleUserLeft);
      AgoraClient.on("user-info-updated", handleUpdateUserInfo);
      AgoraClient.on("microphone-changed", handleMicrophoneChanged);

      // Join a channel
      options.uid = await AgoraClient.join(
        options.appId,
        options.channel,
        options.token || null,
        options.uid || null
      );

      const tracks = await initTracks();
      await AgoraClient.publish(tracks);
      console.log("publish success");
      setJoined(true);
    } catch (error) {
      toastr.error(error.message);
      console.error(error);
    }
  };

  const leave = async () => {
    // Remove event listeners from the client
    AgoraClient.off("user-published", handleUserPublished);
    AgoraClient.off("user-unpublished", handleUserUnpublished);
    AgoraClient.off("user-joined", handleUserJoined);
    AgoraClient.off("user-left", handleUserLeft);
    AgoraClient.off("user-info-updated", handleUpdateUserInfo);
    AgoraClient.off("microphone-changed", handleMicrophoneChanged);

    audioTrack?.close();
    setAudioTrack(null);
    setRemoteUsers([]);
    await AgoraClient.leave();
    setJoined(false);
    const msg = "client leaves channel success!";
    console.log(msg);
  };

  const mute = async () => {
    if (!audioTrack) return;

    audioTrack?.setMuted(true);
    dispatch(toggleMute(true));
  };

  const unmute = async () => {
    if (!audioTrack) return;

    audioTrack?.setMuted(false);
    dispatch(toggleMute(false));
    dispatch(setShowMutedInfo(false));
  };

  return null;
};

export default AgoraComponent;
