import PlayIcon from "../assets/icons/play-icon.svg";
import PauseIcon from "../assets/icons/pause-icon.svg";

const PlayerButton = ({
  width = 25,
  isPlaying,
  onClick,
  customText = null,
  disabled = false,
  tooltip = null,
  customId = null,
  tourStep,
}) => {
  return (
    <button
      className="play-button d-flex align-items-center justify-content-center position-relative"
      onClick={onClick}
      disabled={disabled}
      id={customId || ""}
      data-tour-step={tourStep}
    >
      {customText ? (
        customText
      ) : isPlaying ? (
        <img
          className="play-icon"
          width={width}
          src={PauseIcon}
          alt="Musing Logo"
        />
      ) : (
        <img
          className="play-icon"
          width={width}
          src={PlayIcon}
          alt="Musing Logo"
        />
      )}

      {tooltip && <span className="tooltip position-absolute">{tooltip}</span>}
    </button>
  );
};

export default PlayerButton;
