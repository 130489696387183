import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraClient } from "../../components/AgoraComponent";
import { store } from "../../store";
import { setAudioFileTrack } from "../redux/Agora/agoraSlice";

const playSingleAudioFile = async ({
  track,
  gameOver,
  loop,
  onTrackEnd,
  callback,
  updateGameState,
  gameState,
}) => {
  if (!track) return;

  const dispatch = store.dispatch;

  if (!dispatch) return;

  const newAudioFileTrack = await AgoraRTC.createBufferSourceAudioTrack({
    source: track,
  });

  if (gameOver) newAudioFileTrack?.setVolume(4);
  else newAudioFileTrack?.setVolume(10);

  // Start processing the audio data from the audio file.
  newAudioFileTrack.startProcessAudioBuffer({
    startPlayTime: 0,
    loop: loop,
  });
  // Publish audioFileTrack and localAudioTrack together.
  AgoraClient.publish(newAudioFileTrack).then(() => {
    newAudioFileTrack?.play();
  });

  updateGameState &&
    updateGameState({
      player: { ...gameState?.player, isPlayingTrack: true },
    });

  newAudioFileTrack.on("source-state-change", async (state) => {
    if (state === "stopped") {
      updateGameState &&
        updateGameState({
          player: {
            ...gameState?.player,
            isPlayingTrack: false,
          },
        });

      newAudioFileTrack.stopProcessAudioBuffer();
      await AgoraClient.unpublish(newAudioFileTrack);
      newAudioFileTrack.off("source-state-change");

      if (onTrackEnd && !gameOver) onTrackEnd();
    }
  });

  dispatch(setAudioFileTrack(newAudioFileTrack));

  if (callback) callback(newAudioFileTrack);
};

export default playSingleAudioFile;
