import Modal from "./Modal";
import Button from "./Button";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { SignalRContext } from "../context/SignalRContext";
import { useTranslate } from "../utils/hooks/useTranslate";

const CongratulationsModal = ({
  show,
  roundPoint,
  gamePoint,
  artistName,
  trackName,
  cover,
}) => {
  const { gameState, roomState } = useContext(AppContext);
  const { startNewGame } = useContext(SignalRContext);
  const { translate } = useTranslate();

  return (
    <Modal show={show} hideCloseButton>
      <div className="d-flex flex-column align-items-center">
        <p className="fw-bold h4 text-center my-3">
          {translate("MODAL_TRACK_END_TITLE").replace(
            "$teamName",
            roomState?.name
          )}
        </p>
        <p
          className="text-center px-4 mb-4"
          dangerouslySetInnerHTML={{
            __html: translate("MODAL_TRACK_END_DESCRIPTION"),
          }}
        />
      </div>
      <div className="d-flex align-items-center justify-content-around">
        <div className="mini-box w-100 text-center mx-4 py-2">
          <p className="fs-6 mb-0">{translate("MODAL_TRACK_END_POINT")}</p>
          <p className="fw-bold fs-3 mb-0">{roundPoint}</p>
        </div>
        <div className="mini-box w-100 text-center mx-4 py-2">
          <p className="fs-6 mb-0">{translate("MODAL_TRACK_END_GAME_POINT")}</p>
          <p className="fw-bold fs-3 mb-0">{gamePoint}</p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-start mt-4">
        <img
          className="ms-4 rounded-circle overflow-hidden"
          width={100}
          src={cover}
          alt={`${artistName} - ${trackName} Cover`}
        />
        <p className="mt-4 ms-3">
          <span className="fw-bold">{artistName}</span>
          <br />
          {trackName}
        </p>
      </div>
      {roomState?.moderator?.gamerId === gameState?.player?.id && (
        <div className="d-flex justify-content-center mt-4">
          <Button onClick={() => startNewGame(false)}>
            {translate("MODAL_TRACK_END_CONTINUE")}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default CongratulationsModal;
