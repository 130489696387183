import Card from "../../components/Card";
import Header from "../../components/Header";

import MimoDj from "../../assets/img/mimo-dj.svg";
import RecordBreak from "../../assets/videos/record-break.mp4";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import recordBreak from "../../assets/img/record-break.png";
import PlayIcon from "../../assets/icons/play-icon.svg";

const RoomBoxSelect = ({ translate }) => {
  const { navigate, roomState } = useContext(AppContext);

  const [showVideo, setShowVideo] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const frameRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (!isVideoPlaying) {
      frameRef?.current?.setAttribute("data-is-in", false);
      videoRef?.current?.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnEnd = () => {
    setShowVideo(false);

    const duration = roomState.round > 1 ? 3750 : 7500;

    setTimeout(
      () => {
        navigate("room-box-select");
      },
      duration
    );
  }

  return (
    <div className="second-main container-fluid d-flex flex-column align-items-center justify-content-between">
      <Header />
      <div className="col-10 col-lg-8">
        <Card className="record-box-select-card-holder align-items-center justify-content-center">
          {!showVideo && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={MimoDj} alt="mimo-dj" />
              <div className="d-flex flex-column align-items-center pt-5 px-4">
                <h1
                  className="mb-2 fs-2"
                  dangerouslySetInnerHTML={{
                    __html: translate("ROOM_MIO_SELECT_BOX_INFO_TITLE"),
                  }}
                />
                <p
                  className="fs-5 py-3 text-center"
                  dangerouslySetInnerHTML={{
                    __html: translate("ROOM_MIO_SELECT_BOX_INFO_DESCRIPTION"),
                  }}
                />
              </div>
            </div>
          )}

          <img
            src={recordBreak}
            alt="Record Break First Frame"
            className="record-break-video first-frame position-absolute w-100 h-100"
            data-is-in={true}
            ref={frameRef}
          />
          {!isVideoPlaying && showVideo && (
            <div
              className="video-play-button position-absolute rounded-circle"
              role="button"
              onClick={() => {
                videoRef?.current?.play();
              }}
            >
              <img src={PlayIcon} alt="Play Icon" className="w-100" />
            </div>
          )}
          <video
            className="record-break-video position-absolute w-100 h-100"
            ref={videoRef}
            onPlay={(e) => {
              e.target.volume = 0.4;
              e.target.muted = false;
              setIsVideoPlaying(true);
            }}
            onPause={(e) => {
              setIsVideoPlaying(false);
            }}
            data-is-in={showVideo}
            autoPlay={false}
            onEnded={handleOnEnd}
            muted={true}
            playsInline
          >
            <source src={RecordBreak} type="video/mp4" />
          </video>
        </Card>
      </div>

      <div className="footer-placeholder">&nbsp;</div>
    </div>
  );
};

export default RoomBoxSelect;
