import GameScreen from "./pages/GameScreen";
import ResolutionInfo from "../../components/ResolutionInfo";
import { Steps } from "intro.js-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslate } from "../../utils/hooks/useTranslate";
import { AppContext } from "../../context/AppContext";

const STEPS = [
  {
    index: 1,
    content: "TUTORIAL_VIRTUOSO_1",
    target: "#tutorial-virtuoso-1",
  },
  {
    index: 2,
    content: "TUTORIAL_VIRTUOSO_2",
    target: "#tutorial-virtuoso-2",
  },
  {
    index: 3,
    content: "TUTORIAL_VIRTUOSO_3",
    target: "#tutorial-virtuoso-3",
  },
  {
    index: 4,
    content: "TUTORIAL_VIRTUOSO_4",
    target: "#tutorial-virtuoso-4",
  },
  {
    index: 5,
    content: "TUTORIAL_VIRTUOSO_5",
    target: "#tutorial-virtuoso-5",
  },
  {
    index: 6,
    content: "TUTORIAL_VIRTUOSO_6",
    target: "#tutorial-virtuoso-6",
  },
  {
    index: 7,
    content: "TUTORIAL_VIRTUOSO_7",
    target: "#tutorial-virtuoso-7-8",
  },
  {
    index: 8,
    content: "TUTORIAL_VIRTUOSO_8",
    target: "#tutorial-virtuoso-7-8",
  },
];

const Index = () => {
  const [enabled, setEnabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { translate } = useTranslate();
  const { app } = useContext(AppContext);

  const steps = useRef(null);

  const tourOptions = {
    exitOnOverlayClick: false,
    exitOnEsc: false,
    overlayOpacity: 0.9,
    scrollToElement: true,
    tooltipClass: "tutorial-tooltip",
    nextLabel: translate("TUTORIAL_NEXT_BUTTON") || "",
    prevLabel: translate("TUTORIAL_PREV_BUTTON") || "",
    doneLabel: translate("TUTORIAL_DONE_BUTTON") || "",
    scrollPadding: 100,
    disableInteraction: false,
    highlightClass: "tutorial-highlight",
  };

  useEffect(() => {
    if (app.translateReady) {
      setTimeout(() => {
        setEnabled(true);
      }, 10);
    }
  }, [app.translateReady]);

  useEffect(() => {
    if (!enabled) return;

    setTimeout(() => {
      const overlay = document.querySelector(".introjs-overlay");
      if (overlay) {
        overlay.style.display = "none";
      }

      const highlight = document.querySelector(".tutorial-highlight");
      if (highlight) {
        highlight.style.display = "none";
      }
    }, 200);
  }, [enabled, activeStep]);

  const updateStep = (step) => {
    if (steps?.current?.introJs?.goToStepNumber && step !== activeStep) {
      steps.current.introJs.goToStepNumber(step);
    }
  };

  return (
    <>
      <Steps
        ref={steps}
        initialStep={0}
        enabled={enabled}
        steps={STEPS.map((step) => ({
          element: step.target,
          intro: translate(step.content),
        }))}
        onStart={() => {
          setActiveStep(1);
        }}
        onChange={(step) => {
          setActiveStep(step + 1);
        }}
        onExit={() => {}}
        options={tourOptions}
      />
      <ResolutionInfo/>
      <GameScreen
        updateStep={updateStep}
        activeStep={activeStep}
        finish={() => steps?.current?.introJs?.exit()}
        restart={() => {
          setEnabled(false)
          setTimeout(() => {
            steps?.current?.introJs?.refresh();
            setEnabled(true);
          }, 200);
        }}
      />
    </>
  );
};

export default Index;
