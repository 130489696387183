/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import toastr from "toastr";

let cancelSource = axios.CancelToken.source();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const handleCancelRequest = () => {
  cancelSource.cancel("Operation canceled by the user.");
};

export default {
  setupInterceptors: () => {
    instance.interceptors.request.use(
      function (config) {
        cancelSource = axios.CancelToken.source();
        config.cancelToken = cancelSource.token;

        const accessToken = localStorage.getItem("access_token");

        if (accessToken && !config.url.startsWith("Auth")) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }

        config.headers["ngrok-skip-browser-warning"] = "123";

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (axios.isCancel(error)) {
          return Promise.reject(error.message || "Request canceled");
        }
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          toastr.error(error.response?.data?.friendlyMessage);
          console.error(
            error.response?.data?.user_message ||
              `${error.response.status} ${error.response.statusText}`
          );

          return Promise.reject(new Error("Bad status code"));
        }

        return Promise.reject(error);
      }
    );
  },
  getData: (url) =>
    instance({
      method: "GET",
      url,
    }),
  postData: (url, data = {}) =>
    instance({
      method: "POST",
      url,
      data,
    }),
  putData: (url, data = {}) =>
    instance({
      method: "PUT",
      url,
      data,
    }),
};
