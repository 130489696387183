import { useContext, useEffect, useState } from "react";
import { SignalRContext } from "../../context/SignalRContext";
import { AppContext } from "../../context/AppContext";
import Modal from "../../components/Modal";

import Mio from "../../assets/icons/assistant-mio.svg";
import Button from "../../components/Button";
import { useTranslate } from "../hooks/useTranslate";
import { useSelector } from "react-redux";
import stopSingleAudioFile from "../helpers/stopSingleAudioFile";
import stopMultipleAudioFiles from "../helpers/stopMultipleAudioFiles";

const SkipSong = ({ canSkip }) => {
  const [show, setShow] = useState(false);
  const { startNewGame, stopPlaying } = useContext(SignalRContext);
  const { roomState } = useContext(AppContext);
  const { audioFileTrack, audioFileTracks } = useSelector(
    (state) => state.agora
  );

  const { translate } = useTranslate();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleSkip = () => {
    if (!canSkip) return;

    setIsButtonDisabled(true);
    startNewGame(true);

    if (audioFileTrack?.isPlaying) {
      stopSingleAudioFile(audioFileTrack).then(() => {
        stopPlaying();
      });
    }

    if (audioFileTracks?.length) {
      stopMultipleAudioFiles(audioFileTracks).then(() => {
        stopPlaying();
      });
    }
  };

  useEffect(() => {
    setIsButtonDisabled(false);
  }, [show]);

  return (
    <>
      <button
        className="skip-song-button position-absolute text-uppercase"
        onClick={() => setShow(true)}
        disabled={roomState?.numberOfSkip < 1 || !canSkip}
      >
        {translate(`SKIP`)} ({roomState?.numberOfSkip}/3)
        {!canSkip ? (
          <span className="tooltip tooltip--left position-absolute text-center">
            {translate(`ONLY_MODERATOR_CAN_SKIP`)}
          </span>
        ) : roomState?.numberOfSkip < 1 ? (
          <span className="tooltip tooltip--left position-absolute text-center">
            {translate(`NO_SKIP_LEFT`)}
          </span>
        ) : (
          <span
            className="tooltip tooltip--left position-absolute text-center"
            style={{ maxWidth: "230px" }}
          >
            {translate(`ONLY_YOU_CAN_SKIP`)}
          </span>
        )}
      </button>

      <Modal
        show={show}
        hideCloseButton={false}
        toggleModal={() => setShow(false)}
      >
        <div className="d-flex align-items-center mt-3">
          <img className="mb-4" src={Mio} width={134} alt="Mio" />
          <div>
            <h4 className="speech-bubble ms-4 mb-3 ps-3 border-gradient border-gradient-purple">
              {translate(`MODAL_SKIP_TITLE`)}
            </h4>
            <p
              className="speech-bubble ms-4 ps-3 border-gradient border-gradient-purple"
              dangerouslySetInnerHTML={{
                __html: translate(`MODAL_SKIP_TEXT`),
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="mt-3"
            onClick={handleSkip}
            disabled={roomState?.numberOfSkip < 1 || isButtonDisabled}
          >
            {translate(`CONTINUE`)}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SkipSong;
