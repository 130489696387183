import Card from "../../components/Card";
import Header from "../../components/Header";

import MimoDj from "../../assets/img/mimo-dj-bigger.svg";
import { useContext, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import { useEffect } from "react";

import recordBreak from "../../assets/img/record-break.png";
import { useDispatch } from "react-redux";
import { stopSingleAudioFile } from "../../utils/redux/Agora/agoraSlice";

const RoomTrackSelect = ({ translate }) => {
  const { navigate } = useContext(AppContext);
  const dispatch = useDispatch();

  const frameRef = useRef(null);

  useEffect(() => {
    dispatch(stopSingleAudioFile());
  }, [dispatch]);

  useEffect(() => {
    // if (process.env.REACT_APP_DEV_MODE === "true") {
    //   setTimeout(() => {
    //     frameRef.current.setAttribute("data-is-in", true);
    //   }, 750);
    //   setTimeout(() => {
    //     navigate("room-box-select-info");
    //   }, 1000);
    //   return;
    // }

    const timeoutDuration = 3000;

    setTimeout(() => {
      frameRef.current.setAttribute("data-is-in", true);
    }, timeoutDuration - 250);
    setTimeout(() => {
      frameRef.current.setAttribute("data-is-in", true);
      navigate("room-box-select-info");
    }, timeoutDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="second-main container-fluid d-flex flex-column align-items-center justify-content-between">
      <Header />
      <div className="col-10 col-lg-8">
        <Card className="d-flex flex-column align-items-center justify-content-center record-box-select-card-holder">
          <img
            ref={frameRef}
            src={recordBreak}
            alt="Record Break First Frame"
            className="record-break-video first-frame position-absolute w-100 h-100"
            data-is-in={false}
          />
          <img src={MimoDj} alt="mimo-dj" />
          <div className="d-flex flex-column align-items-center pt-5 px-4 mt-4">
            <h1
              className="mb-2 fs-2 text-center"
              style={{ maxWidth: "480px" }}
              dangerouslySetInnerHTML={{
                __html: translate("ROOM_MIO_SELECTING_TRACK"),
              }}
            />
          </div>
        </Card>
      </div>
      <div className="footer-placeholder">&nbsp;</div>
    </div>
  );
};
export default RoomTrackSelect;
