import { createSlice } from "@reduxjs/toolkit";

const localStorageKey = "game";

const localStorageData = JSON.parse(localStorage.getItem(localStorageKey));

const initialState = {
  completedTutorials: {
    virtuoso: false,
    gamer: false,
    guest: false,
  },
  token: "",
  watchedIntroVideo: false,
  ...localStorageData,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setToken: (state, action) => {
      if (action.payload !== state.token) {
        state.completedTutorials = {
          virtuoso: false,
          gamer: false,
          guest: false,
        };
      }
      state.token = action.payload;
      localStorage.setItem(localStorageKey, JSON.stringify(state));
    },
    setCompletedTutorial: (state, action) => {
      state.completedTutorials[action.payload] = true;
      localStorage.setItem(localStorageKey, JSON.stringify(state));
    },
    setWatchedIntroVideo: (state) => {
      state.watchedIntroVideo = true;
      localStorage.setItem(localStorageKey, JSON.stringify(state));
    }
  },
});

export const {
  setToken,
  setCompletedTutorial,
  setWatchedIntroVideo
} = gameSlice.actions;

export default gameSlice.reducer;
