import { useContext, useEffect } from "react";
import Modal from "./Modal";
import Accordion from "./Accordion";
import RobotIcon from "../assets/icons/robot.svg";
import CircleButton from "./CircleButton";
import InformationIcon from "../assets/icons/question-mark.svg";
import { AppContext } from "../context/AppContext";
import { useTranslate } from "../utils/hooks/useTranslate";

const FAQ = () => {

  const { app, setShowFaqModal } = useContext(AppContext);
  const { translate } = useTranslate();

  useEffect(() => {
    if (app.showFaqModal) {
      document.querySelectorAll("details").forEach((D, _, A) => {
        D.ontoggle = (_) => handleToggle(A, D);
      });
    }

    return () => {
      document.querySelectorAll("details").forEach((D, _, A) => {
        D.ontoggle = null;
        A.forEach((d) => {
          d.open = false;
        });
      });
    };
  }, [app.showFaqModal]);

  const handleToggle = (A, D) => {
    if (D.open)
      A.forEach((d) => {
        if (d !== D) d.open = false;
      });
  };

  return (
    <div className="faq">
      <CircleButton onClick={() => setShowFaqModal(true)}>
        <img width={16} src={InformationIcon} alt="question" />
      </CircleButton>
      <Modal show={app.showFaqModal} toggleModal={() => setShowFaqModal(false)}>
        <div className="d-flex align-items-center">
          <img className="mb-4" src={RobotIcon} alt="Robot Icon" />
          <p className="speech-bubble ms-4 p-3 border-gradient border-gradient-purple">
            {translate("MIO_FAQ_TEXT")}
          </p>
        </div>
        {app?.faqs?.map((faq, index) => (
          <Accordion summary={faq.question} details={faq.answer} key={index} />
        ))}
      </Modal>
    </div>
  );
};

export default FAQ;
