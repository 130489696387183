const AgreementTR = () => {
  return <>
    <h1 className="text-center h4 mb-4">
      Beyond Eğitim ve Danışmanlık Hizmetleri Ltd. Şti. (“Digi-Stage”)
    </h1>
    <h2 className="text-center h5 mb-4">
      Musing© Kişisel Verilerin İşlenmesi ve Korunması Hakkında Aydınlatma
      Metni
    </h2>
    <h4>1. Veri Sorumlusunun Kimliği</h4>
    <p>
      Beyond Eğitim ve Danışmanlık Hizmetleri Ltd. Şti. (“Digi-Stage”)
      olarak, Musing© oyununa (“Musing”) katılan tüm katılımcılara ilişkin
      elde ettiğimiz kişisel verileri veri sorumlusu sıfatıyla işlemekteyiz.
      Bu kişisel verilerin türü, işlenme şekli, amacı ve hukuki
      dayanaklarına ilişkin bilgileri 6698 sayılı Kişisel Verilerin
      Korunması Kanunu’na (“Kanun”) ve ilgili düzenlemelere uygun hareket
      edebilmemizi teminen bu sayfada yer alan aydınlatma metninde
      bulabilirsiniz.
    </p>
    <h4>2. Kişisel Verilerin İşlenmesi ve İşleme Amaçları</h4>
    <p>
      Musing’de oluşturacağınız hesaba ilişkin olarak kullanıcı e-posta
      hesabınız, seçtiğiniz kullanıcı adı ve avatar, oluşturduğunuz takımın
      adı, oyundaki performansınıza ilişkin veriler ve (eğer bize feedback
      bırakmak isterseniz) öneri ve şikayetlerinizi elektronik ortamda
      (yazılım vasıtasıyla) işliyor ve sözleşmesel yükümlülüklerimizin
      yerine getirilmesi, ticari faaliyetlerimizin yürütülmesi ve müşteri
      memnuniyetinin sağlanması amaçlarıyla işlenmektedir ve bu amaçlar
      dışında kullanılmaz.{" "}
    </p>
    <p>
      Musing’i kullanırken oyuncuların sesli iletişim kurulmasını temin
      etmek için Digi-Stage olarak Agora Lab, Inc. şirketinin Voice Calling
      ürününü kullanmaktayız. Voice Calling aracılığıyla tarafımızca veri
      sorumlusu sıfatıyla yukarıda sayılanlara ilave bir kişisel veri
      işlenmemekle birlikte, Agora Lab Inc. sözleşmesel yükümlülükleri
      kapsamında hizmetlerini sunabilmek ve hizmet kalitesini sağlayabilmek
      için veri işleyen olarak kendi veri politikası kapsamında bazı kişisel
      verilerinizi işlemekte ve yurtdışında yer alan sunucularında
      saklamaktadır. Bu veriler IP adresiniz, Agora konfigürasyon verileri,
      kullanım verileri ve metadata, sistem performans verileri, sistem
      loglarından ibarettir. Bu konuda daha detaylı bilgi almak için Agora
      Lab Inc.’ın kişisel verilerin işlenmesine ilişkin aydınlatma metnine{" "}
      <a
        href="https://www.agora.io/en/agora-processor-privacy-statement/"
        target="_blank"
        rel="noreferrer nofolloew"
      >
        bu link
      </a>
      ’ten ulaşabilirsiniz.
    </p>
    <h4 id="cerezler">2/a. Çerezlerle İşlenen Kişisel Veriler</h4>
    <p>
      Oyunun browser üzerinde çalışması sebebiyle, ayrıca oyunun
      çalışabilmesi ve server güvenliğinin temini için zorunlu veyahut
      gerekli olan bazı çerezler URL adresimizde çalışmaktadır. Söz konusu
      çerezler aşağıdakilerden ibarettir:
    </p>
    <p>
      AspNetCore.Antiforgery: Server güvenliği amacıyla kullanılmaktadır.
      Sunucuya bağlanan her bir client için anahtar oluşturur.
      <br/>
      websdk_ng_global_parameter: Agora Lab Inc.’a ait bir çerezdir. Agora
      Voice Calling’in tarayıcınızda çalışabilmesi için gerekli bir
      parametredir.
    </p>
    <p>
      İşlenen verilerin saklanma süreleri Digi-Stage Veri İşleme ve İmha
      Politikası çerçevesinde belirlenmiştir. Söz konusu sürelere ilişkin
      detaylı bilgiyi tarafımızla iletişime geçerek edinebilirsiniz.{" "}
    </p>
    <h4>
      4. İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçlar ile
      Aktarılabileceği
    </h4>
    <p>
      Musing’i herhangi bir şekilde bilgisayarınıza indirmeksizin oyuna
      erişilebilmeniz ve oyunun stabilitesi için, Musing bir bulut bilişim
      sistemi olan AWS© (Amazon Web Services) üzerinde çalışmakta olup, bu
      kapsamda yukarıda sayılan kişisel verileriniz Amazon Web Services,
      Inc’ın Almanya’da yer alan sunucularına otomatik olarak aktarılmakta
      ve burada saklanmaktadır.{" "}
    </p>
    <p>
      Agora Lab Inc. tarafından işlenen veriler ise şirketin Amerika
      Birleşik Devletleri’nde yer alan sunucularına otomatik olarak
      aktarılmakta ve burada saklanmaktadır.{" "}
    </p>
    <p>
      Tarafımızca doğrudan veya veri işleyen kuruluşlar vasıtasıyla işlenen
      kişisel verileriniz, işbu Aydınlatma Metni’nin 2. maddesinde açıklanan
      amaçlarla ve ilave olarak Digi-Stage’in kanuni yükümlülüklerinin
      yerine getirilmesi veya yasal haklarının korunması veya tesisi
      amaçlarıyla; diğer resmi kurum ve kuruluşlara, bağımsız denetim
      şirketlerine, mahkemelere, arabuluculara, tahkim hakem ve
      komisyonlarına, Digi-Stage’in avukatlarına, mali müşavir ve gümrük
      müşavirlerine ve diğer danışmanlarına ve Digi-Stage’in hissedarlarına
      Kanun’da yer alan ölçülülük ve amaçla bağlılık prensipleri
      çerçevesinde aktarılabilecektir.{" "}
    </p>
    <h4>5. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</h4>
    <p>
      Digi-Stage’in olağan ticari faaliyetlerinin yürütülmesi, muhasebe ve
      finans işlemlerinin yürütülmesi ve müşteri memnuniyeti süreçleri
      kapsamında işlenen ve yukarıda 2. maddede sayılan tüm kişisel veriler
      “sözleşmenin kurulması ve ifasıyla doğrudan ilgili olması”, “hukuki
      yükümlülüklerimizi yerine getirmemiz için zorunluluk arz etmesi”,
      “meşru menfaatlerimiz için veri işlemenin zorunlu olması” ve varsa
      “açık rıza” hukuki sebeplerine dayanarak işlenmektedir.
    </p>
    <p>
      Söz konusu kişisel veriler tarafınızca Digi-Stage’e iletilen yazılı
      bilgilerden ve Şirket faaliyetleri kapsamında kullanılan uygulama ve
      yazılımlardan toplanmaktadır. Tüm veri kategorilerinde işlenen veriler
      hem otomatik hem de otomatik olmayan yollarla işlenebilmektedir
    </p>
    <h4>6. Kişisel Verilerin Korunmasına Yönelik Haklarınız</h4>
    <p>
      Kişisel verilerin korunması mevzuatı çerçevesinde kişisel
      verilerinizin işlenip işlenmediğini öğrenme, işlenmişse buna ilişkin
      bilgi talep etme, işlenme amacını ve tarafımızca bu amaçlara uygun
      olarak kullanıp kullanılmadığını öğrenme, yurt içinde veya yurt
      dışında aktarıldığı üçüncü kişileri öğrenme, eksik veya yanlış
      işlenmiş olması halinde düzeltilmesini ve aktarılmışsa aktarılan
      üçüncü kişilere de bildirilmesini talep etme, işlenme şartlarının
      ortadan kalkması halinde silinmesini veya yok edilmesini ve
      aktarılmışsa aktarılan üçüncü kişilere de bildirilmesini talep etme,
      işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
      edilmesi suretiyle aleyhinize bir sonucun ortaya çıktığını
      düşünüyorsanız bu duruma itiraz etme, Kanuna aykırı olarak işlenmesi
      sebebiyle bir zarara uğramanız halinde bu zararın giderilmesini talep
      etme haklarınız mevcuttur.
      <br/>
      Kanun kapsamında, kişisel verilerinizle ilgili başvurularınızı
      Digi-Stage’e aşağıda belirtilen kanallardan birini kullanarak
      yapabilirsiniz:
      <br/>
      - Esentepe Mh. Talatpaşa Cd. No: 5, Kapı no: 1 Şişli/ İSTANBUL
      adresine kimliğinizi tespit edici belgeleri de ilave ederek bizzat
      elden veya noter kanalıyla iletebilir, yahut ilgili formu elektronik
      olarak veya,
      <br/>- Digi-Stage’de tanımlı e-posta adresiniz ile{" "}
      <a href="mailto:info@digi-stage.com">info@digi-stage.com</a> e-posta
      adresine güvenli elektronik imza kullanarak iletebilirsiniz.
    </p>

    <p>
      Veri Sorumlusuna Başvuru Esas ve Usulleri Hakkında Tebliğ uyarınca
      başvurunuzun Türkçe olması ve başvuruda:
    </p>

    <p>
      a) Ad, soyad ve başvuru yazılı ise imza,
      <br/>
      b) Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numarası,
      yabancılar için uyruğu, pasaport numarası veya varsa kimlik numarası,
      <br/>
      c) Tebligata esas yerleşim yeri veya iş yeri adresi,
      <br/>
      ç) Varsa bildirime esas elektronik posta adresi, telefon ve faks
      numarası,
      <br/>
      d) Talep konusu,
      <br/>
      bulunması, konuya ilişkin bilgi ve belgelerin başvuruya eklenmesi
      zorunludur.
    </p>

    <p>
      Digi-Stage, Kanun’un 13. maddesine uygun olarak, başvuru taleplerini,
      en geç 30 (otuz) gün içinde sonuçlandıracaktır. İşlemin maliyet
      gerektirmesi halinde, Kişisel Verilerin Korunması Kurulu tarafından
      belirlenen tarife uygulanacaktır. Talebin reddedilmesi halinde, ret
      nedeni/nedenleri yazılı olarak veya elektronik ortamda
      gerekçelendirilecektir.
    </p>

    <p>Bilgi edinilmesini rica ederiz.</p>
  </>
}

export default AgreementTR;