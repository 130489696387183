import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "./pages/NotFound";

import "./assets/app.scss";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "./context/AppContext";
import API from "./utils/API";
import GAME_PAGES, { API_GAME_PAGES } from "./utils/constants/GAME_PAGES";
import Game from "./Game";
import InjectAxiosInterceptors from "./utils/components/InjectAxiosInterceptors";
import Agreement from "./pages/Agreement/Agreement";
import ConnectionTest from "./pages/ConnectionTest";
import Listener from "./pages/Listener/Listener";
import Demo from "./pages/Demo/Demo";
import Modal from "./components/Modal";
import { ModalContext } from "./context/ModalContext";
import AgoraComponent from "./components/AgoraComponent";
import TutorialDemo from "./pages/TutorialDemo";
import Tutorial from "./pages/Tutorial/Tutorial";
import { useParams } from "react-router";

const VERSION = "1.1.58";

const App = () => {
  const {
    gameState,
    updateGameState,
    updateApp,
    navigate,
    setShowFaqModal,
    app,
  } = useContext(AppContext);

  const [isGameLoaded, setIsGameLoaded] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [preloaded, setPreloaded] = useState(true);

  const location = useLocation();

  const { tokenString } = useParams();

  const {
    isShowing,
    className,
    hideCloseButton,
    hideModal,
    modalContent,
    modalTitle,
  } = useContext(ModalContext);

  useEffect(() => {
    const enExists = location.pathname.includes("/en/");

    if (enExists && app.language !== "en") {
      updateApp({ language: "en" });
      localStorage.setItem("language", "en");
    } else if (!enExists && app.language !== "tr") {
      updateApp({ language: "tr" });
      localStorage.setItem("language", "tr");
    }
  }, [app.language, location.pathname, updateApp]);

  useEffect(() => {
    const shouldGameLoad = [
      "/en/demo",
      "/demo",
      "/aydinlatma-metni",
      "/disclosure-text",
      "/en/disclosure-text",
      "/baglanti-testi",
      "/connection-check",
      "/en/connection-check",
      "/dinleyici",
      "/listener",
      "/en/listener",
      "/bilgi-ekrani",
      "/tutorial",
      "/en/tutorial",
      "/sss",
      "/faq",
      "/en/faq",
    ];

    shouldGameLoad.some((path) => location.pathname.includes(path)) &&
    setIsGameLoaded(true);
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname === "/faq" ||
      location.pathname === "/en/faq" ||
      location.pathname === "/sss"
    ) {
      setShowFaqModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const el = document.querySelector("meta[name=version]");
    if (el) return;

    const versionMeta = document.createElement("meta");
    versionMeta.name = "version";
    versionMeta.content = VERSION;
    document.head.appendChild(versionMeta);
  }, []);

  useEffect(() => {
    const shouldNotConnectAgoraRoutes = [
      "/en/demo/",
      "/en/demo/started",
      "/demo",
      "/demo/started",
      "/demo/basla",
      "/aydinlatma-metni",
      "/disclosure-text",
      "/en/disclosure-text",
      "/bilgi-ekrani",
      "/tutorial",
      "/en/tutorial",
      "/",
      "/en",
      "/sss",
      "/faq",
      "/en/faq",
    ];

    if (shouldNotConnectAgoraRoutes.includes(location.pathname)) {
      updateGameState({ shouldConnectAgora: false });
    } else {
      updateGameState({ shouldConnectAgora: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (process.env.REACT_APP_DEV_MODE === "true") {
      // setIsLoading(false);
      setPreloaded(true);
    }
  }, []);

  useEffect(() => {
    if (!tokenString) {
      localStorage.removeItem("access_token");
    }
  }, [tokenString])

  useEffect(() => {
    if (isGameLoaded && !gameState.token) {
      API.getData(`Faq?lang=${app.language}`).then(({ data: res }) => {
        updateApp({ faqs: res.data });
      });

      API.getData(`ScreenText?lang=${app.language}`).then(({ data: res }) => {
        updateApp({ text: res, translateReady: true });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState.token, isGameLoaded, app.language]);

  useEffect(() => {
    if (gameState.token && !gameState.auth) {
      API.getData(`Auth?gameToken=${gameState.token}`)
        .then(({ data: res }) => {
          if (!res?.data) return;

          const { accessToken, activeScreen } = res.data;
          localStorage.setItem("access_token", accessToken);
          updateGameState({
            auth: true,
            page: activeScreen ? API_GAME_PAGES[activeScreen] : "welcome",
          });

          API.getData(`Faq?lang=${app.language}`).then(({ data: res }) => {
            updateApp({ faqs: res.data });
          });

          API.getData(`ScreenText?lang=${app.language}`).then(
            ({ data: res }) => {
              updateApp({ text: res, translateReady: true });
            },
          );
        })
        .then(() => {
          setIsLoading(false);
        });
    }

    if (gameState.token && gameState.auth && gameState.connected) {
      window.onbeforeunload = () => true;
    }

    if (gameState.token && gameState.auth && !gameState.connected) {
      window.onbeforeunload = null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState.token, gameState.auth, app.language]);

  return (
    <div
      className="App"
      data-is-touch-device={
        "ontouchstart" in window ||
        navigator.maxTouchPoints ||
        navigator.msMaxTouchPoints
      }
    >
      {gameState?.shouldConnectAgora && <AgoraComponent/>}
      {process.env.REACT_APP_DEV_MODE === "true" &&
        !location.pathname.includes("demo") && (
          <select
            onChange={(e) => navigate(e.target.value)}
            className="position-fixed"
            style={{ top: 0, left: 0, zIndex: 9999 }}
            value={gameState?.page}
          >
            {Object.keys(GAME_PAGES).map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
          </select>
        )}
      <InjectAxiosInterceptors/>
      <Routes>
        <Route path="/demo" element={<Demo/>}>
          <Route path=":started" element={<Demo/>}/>
        </Route>
        <Route path="/en/demo" element={<Demo/>}>
          <Route path=":started" element={<Demo/>}/>
        </Route>
        <Route path="/dinleyici" element={<Listener/>}>
          <Route path=":tokenString" element={<Listener/>}/>
        </Route>
        <Route path="/listener" element={<Listener/>}>
          <Route path=":tokenString" element={<Listener/>}/>
        </Route>
        <Route path="/en/listener" element={<Listener/>}>
          <Route path=":tokenString" element={<Listener/>}/>
        </Route>
        <Route path="/aydinlatma-metni" element={<Agreement />} />
        <Route path="/disclosure-text" element={<Agreement />} />
        <Route path="/en/disclosure-text" element={<Agreement />} />
        <Route path="/baglanti-testi" element={<ConnectionTest />} />
        <Route path="/connection-check" element={<ConnectionTest />} />
        <Route path="/en/connection-check" element={<ConnectionTest />} />
        <Route path="/bilgi-ekrani" element={<TutorialDemo/>}/>
        <Route path="/tutorial" element={<TutorialDemo/>}/>
        <Route path="/en/tutorial" element={<TutorialDemo/>}/>
        <Route path="/eski-bilgi-ekrani" element={<Tutorial />}/>
        <Route
          path="/sss"
          element={
            <Game
              loaded={preloaded && !isLoading}
              setIsGameLoaded={setIsGameLoaded}
            />
          }
        />
        <Route
          path="/faq"
          element={
            <Game
              loaded={preloaded && !isLoading}
              setIsGameLoaded={setIsGameLoaded}
            />
          }
        />
        <Route
          path="/en/faq"
          element={
            <Game
              loaded={preloaded && !isLoading}
              setIsGameLoaded={setIsGameLoaded}
            />
          }
        />
        {GAME_PAGES[gameState.page] ? (
          <Route
            path="*"
            element={
              <Game
                loaded={preloaded && !isLoading}
                setIsGameLoaded={setIsGameLoaded}
              />
            }
          >
            <Route
              path=":lang?/:tokenString"
              element={
                <Game
                  loaded={preloaded && !isLoading}
                  setIsGameLoaded={setIsGameLoaded}
                />
              }
            />
          </Route>
        ) : (
          <Route path="*" element={<NotFound/>}/>
        )}
      </Routes>

      <Modal
        title={modalTitle}
        show={isShowing}
        toggleModal={hideModal}
        className={className}
        hideCloseButton={hideCloseButton}
      >
        {modalContent}
      </Modal>

      {(window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("webstage")) && (
        <div className="version-number position-absolute">
          Version: {VERSION}
        </div>
      )}
    </div>
  );
};

export default App;
