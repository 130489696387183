import { useContext, useState } from "react";
import Header from "../../components/Header";
import Card from "../../components/Card";
import { AppContext } from "../../context/AppContext";
import RECORD_PARTS from "../../utils/constants/RECORD_PARTS";
import UserInfo from "../../components/UserInfo";
import { SignalRContext } from "../../context/SignalRContext";
import { useEffect } from "react";

const RecordSelect = () => {
  const { gameState, roomState } = useContext(AppContext);
  const { connection } = useContext(SignalRContext);
  const [playerSelectedBox, setPlayerSelectedBox] = useState(false);

  useEffect(() => {
    if (gameState?.boxes) {
      const selectedBox = gameState?.boxes.find(
        (box) => box.selectedGamerId === gameState?.player?.id
      );

      if (selectedBox) {
        setPlayerSelectedBox(true);
      } else {
        setPlayerSelectedBox(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState?.boxes]);

  const handleSelectRecord = (record) => {
    if (playerSelectedBox) return false;

    selectTrackPart(record);
  };

  const selectTrackPart = (record) => {
    connection.send("SelectBox", record);
  };

  return (
    <div className="record-select second-main container-fluid d-flex flex-column align-items-center justify-content-between px-0">
      <Header />
      <div className="col-10 col-lg-8">
        <Card className="record-select-card-holder d-flex flex-row align-items-start justify-content-center py-4">
          <div
            className="records d-grid overflow-auto"
            data-has-selected={playerSelectedBox}
          >
            {gameState?.boxes.map((box) => (
              <div
                key={box.boxId}
                className="record d-flex flex-column align-items-center justify-content-center position-relative"
                onClick={() => handleSelectRecord(box.boxId)}
                role="button"
                data-is-selected={Boolean(box.selectedGamerId)}
                data-is-disabled={gameState?.player?.isGuest}
              >
                <img
                  className="record-part position-absolute"
                  src={RECORD_PARTS[box.boxId - 1]?.part}
                  alt="Record Part"
                />

                <div className="record-cover position-relative">
                  <img
                    className={`w-100 ${
                      Boolean(box.selectedGamerId) ? `opacity-0` : `opacity-100`
                    }`}
                    src={RECORD_PARTS[box.iconId - 1]?.box}
                    alt="Record Box"
                  />
                  {Boolean(box.selectedGamerId) && (
                    <div className="selected-user position-absolute d-flex justify-content-center align-items-center w-100">
                      <UserInfo
                        user={roomState?.players.find(
                          (player) => player?.gamerId === box.selectedGamerId
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>

      <div className="footer-placeholder">&nbsp;</div>
    </div>
  );
};

export default RecordSelect;
