import { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import API from "../../utils/API";

const InjectAxiosInterceptors = () => {
  const { gameState } = useContext(AppContext);

  useEffect(() => {
    API.setupInterceptors();
  }, [gameState?.token]);

  return null;
};

export default InjectAxiosInterceptors;
