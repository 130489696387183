import { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Card from "../../components/Card";
import PlayIcon from "../../assets/icons/play-icon.svg";
import CongratulationsModal from "../../components/CongratulationsModal";
import Confetti from "react-confetti";
import Note from "./components/Note";
import { AppContext } from "../../context/AppContext";
import GameDescriptionModal from "../../components/GameDescriptionModal";
import RECORD_PARTS from "../../utils/constants/RECORD_PARTS";
import playSingleAudioFile from "../../utils/helpers/playSingleAudioFile";
import pauseSingleAudioFile from "../../utils/helpers/pauseSingleAudioFile";
import { SignalRContext } from "../../context/SignalRContext";

const Gamer = () => {
  const { gameState, app, roomState, updateGameState } = useContext(AppContext);
  const { startPlaying, stopPlaying } = useContext(SignalRContext);
  const [audioFileTrack, setAudioFileTrack] = useState(null);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (gameState?.player?.role !== "gamer")
      updateGameState({ player: { ...gameState.player, role: "gamer" } });
  }, [gameState.player, updateGameState]);

  useEffect(() => {
    if (isPlaying && audioFileTrack) pauseSingleAudioFile({ audioFileTrack });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState.page]);

  useEffect(() => {
    if (!gameState?.player?.isPlayingTrack && isPlaying) {
      setIsPlaying(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState]);

  const handlePlay = (note) => {
    if (isPlaying) {
      if (isPlaying === note?.partId) {
        pauseSingleAudioFile({
          audioFileTrack,
          callback: () => {
            setIsPlaying(false);
            stopPlaying();
          },
        });
      } else {
        setIsPlaying(false);

        playSingleAudioFile({
          track: note?.recordUrl,
          updateGameState,
          gameState,
          callback: (audioFileTrack) => {
            setAudioFileTrack(audioFileTrack);
            startPlaying();
          },
          onTrackEnd: () => {
            stopPlaying();
          },
        });

        setIsPlaying(note?.partId);
      }
    } else {
      playSingleAudioFile({
        track: note?.recordUrl,
        updateGameState,
        gameState,
        callback: (audioFileTrack) => {
          setAudioFileTrack(audioFileTrack);
          startPlaying();
        },
        onTrackEnd: () => {
          stopPlaying();
        },
      });
      setIsPlaying(note?.partId);
    }
  };

  return (
    <div
      className="gamer container-fluid main d-flex flex-column align-items-center justify-content-between"
      style={{
        backgroundImage: `url(${
          RECORD_PARTS[gameState?.player?.box - 1]?.box || RECORD_PARTS[0]?.box
        })`,
      }}
    >
      <Header/>
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-10 col-lg-8">
          <Card className="d-flex align-items-center justify-content-between">
            <div className="notes-holder d-flex flex-row justify-content-center align-items-center w-100 h-100">
              {roomState?.records
                ?.sort((a, b) => a.order - b.order)
                ?.map((note) => (
                  <Note
                    key={note?.partId}
                    note={note}
                    isPlaying={isPlaying === note?.partId}
                    handlePlay={() => handlePlay(note)}
                  />
                ))}
            </div>
            <div className="play-button-area d-none flex-column align-items-center justify-content-center py-2">
              <div className="play-button d-flex align-items-center justify-content-center">
                <img className="play-icon" src={PlayIcon} alt="Musing Logo"/>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className="footer-placeholder">&nbsp;</div>

      <GameDescriptionModal type="GAMER"/>
      {app?.modal?.modalType === "TrackSuccess" && (
        <CongratulationsModal
          show={app?.modal?.modalType === "TrackSuccess"}
          roundPoint={app?.modal?.data?.trackPoint}
          gamePoint={app?.modal?.data?.gamePoint}
          artistName={app?.modal?.data?.artistName}
          trackName={app?.modal?.data?.trackName}
          cover={app?.modal?.data?.artistImageUrl}
        />
      )}
      {app?.modal?.modalType === "TrackSuccess" && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200}
          recycle={app?.modal?.modalType === "TrackSuccess"}
          gravity={0.1}
        />
      )}
    </div>
  );
};

export default Gamer;
