import { AgoraClient } from "../../components/AgoraComponent";
import { store } from "../../store";
import { setAudioFileTrack } from "../redux/Agora/agoraSlice";

const stopSingleAudioFile = async (audioFileTrack) => {
  if(!audioFileTrack) return;

  const dispatch = store.dispatch;
  if(!dispatch) return;

    audioFileTrack.stop();
    audioFileTrack.stopProcessAudioBuffer();
    await AgoraClient.unpublish(audioFileTrack);

    dispatch(setAudioFileTrack({}));
}

export default stopSingleAudioFile