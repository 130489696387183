import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ConnectionQuality = () => {
  const { connectionQuality } = useSelector((state) => state.agora);

  const [showQualityError, setShowQualityError] = useState(false);

  useEffect(() => {
    if (connectionQuality === "poor") {
      if (!showQualityError) {
        setShowQualityError(true);
      }
    } else if (connectionQuality === "good") {
      if (showQualityError) {
        setShowQualityError(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionQuality]);

  return (
    <div
      className="connection-error position-fixed text-center"
      data-is-poor={connectionQuality === "poor"}
      data-is-in={showQualityError}
    >
      {connectionQuality === "poor"
        ? `Bağlantı hızınız düşük. Ses sorunları yaşayabilirsiniz.`
        : `Bağlantınız düzeldi.`}
    </div>
  );
};

export default ConnectionQuality;
