const Textarea = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  readOnly = false,
  disabled = false,
}) => {
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <textarea
        className="form-control textarea"
        id={name}
        name={name}
        value={value}
        rows="5"
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
      />
    </div>
  );
};

export default Textarea;
