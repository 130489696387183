import { createContext, useState } from "react";
import TRANSLATE from "../utils/constants/TRANSLATE";

const defaultValue = {
  gameState: {
    page: "welcome",
    token: "",
    gameStarted: true,
    startTime: null,
    serverTime: null,
    auth: false,
    connected: false,
    shouldConnectAgora: false,
    audio: false,
    microphone: false,
    player: {
      id: "",
      name: "",
      role: "",
      avatar: "",
      isPlayingTrack: false,
      box: null,
      isGuest: false,
      roomId: "",
    },
    rooms: [],
    boxes: [],
    gameEndData: {
      tracks: [],
      meetingLink: "https://google.com",
      spotifyLink: "",
    },
    headerPlayerInfo: "",
  },
  roomState: {
    id: "",
    name: "",
    moderator: null,
    players: [],
    guests: [],
    records: [],
    fullRecordUrl: "",
    score: 0,
    difficulty: "",
    round: 0,
    roundTime: 1200,
    roundStarted: false,
    startDate: null,
    totalSeconds: 0,
    endData: null,
    selectedParts: [],
    agora: null,
    playingTrack: [],
    listCorrectOrWrong: [],
  },
  app: {
    faqs: [],
    showFaqModal: false,
    loading: false,
    modal: null,
    mio: [],
    text: { ...TRANSLATE },
    viewedWelcomeInfo: false,
    language: "tr",
    translateReady: false,
  },
};

const AppContext = createContext(defaultValue);

let playingTrack = [];

const AppContextProvider = ({ children }) => {
  const [gameState, setGameState] = useState({ ...defaultValue.gameState });
  const [roomState, setRoomState] = useState({ ...defaultValue.roomState });
  const [app, setApp] = useState({ ...defaultValue.app });

  const handleSetGameState = (newState) => {
    setGameState((prevState) => ({ ...prevState, ...newState }));
    localStorage.setItem(
      "gameState",
      JSON.stringify({ ...gameState, ...newState }),
    );
  };

  const handleSetApp = (newState) => {
    setApp((prevState) => ({ ...prevState, ...newState }));
    localStorage.setItem("app", JSON.stringify({ ...app, ...newState }));
  };

  const handleSetRoomState = (newState) => {
    setRoomState((prevState) => ({ ...prevState, ...newState }));
    localStorage.setItem(
      "roomState",
      JSON.stringify({ ...roomState, ...newState }),
    );
  };

  const updateMio = (newMio) => {
    handleSetApp({ mio: newMio });
  };

  const navigate = (page) => {
    handleSetGameState({ page });
  };

  const handleUpdatePlayingTrack = (user, type) => {
    if (type === "play") {
      playingTrack = [...playingTrack, user];
      handleSetRoomState({
        playingTrack,
      });
    } else {
      playingTrack = playingTrack.filter((track) => track !== user);
      handleSetRoomState({
        playingTrack,
      });
    }
  };

  const setShowFaqModal = (showFaqModal) => {
    if (showFaqModal !== app.showFaqModal)
      handleSetApp({ showFaqModal });
  };

  return (
    <AppContext.Provider
      value={{
        gameState,
        updateGameState: handleSetGameState,
        navigate,
        app,
        updateApp: handleSetApp,
        roomState,
        updateRoomState: handleSetRoomState,
        updateMio,
        setShowFaqModal,
        handleUpdatePlayingTrack,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
