import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Avatar from "./Avatar";
import { useTranslate } from "../hooks/useTranslate";

const GuestList = () => {
  const { roomState } = useContext(AppContext);
  const {translate} = useTranslate();

  return roomState?.guests?.filter((guest) => guest?.isConnected)?.length ? (
    <div className="GuestList position-absolute overflow-hidden">
      <h3 className="list-title text-center text-uppercase text-white mb-0">
        {translate("GUEST_LIST_TITLE")}
      </h3>
      <div className="list position-relative">
        {roomState?.guests
          ?.filter((guest) => guest?.isConnected)
          .map((guest) => (
            <div className="list-item d-flex justify-content-start align-items-center">
              <div className="avatar rounded-circle">
                <Avatar
                  id={guest?.avatarId}
                  alt={`${guest?.name} Avatar`}
                  className="rounded-circle"
                />
              </div>
              <div className="name text-white">{guest?.name}</div>
            </div>
          ))}
      </div>
    </div>
  ) : null;
};

export default GuestList;
