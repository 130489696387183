import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Gamer from "../Gamer/Gamer";
import Guest from "../Guest/Guest";
import Virtuoso from "../Virtuoso/Virtuoso";

const GameStarted = () => {
  const { gameState } = useContext(AppContext);

  switch (gameState.role) {
    case "gamer":
      return <Gamer />;
    case "virtuoso":
      return <Virtuoso />;
    case "guest":
      return <Guest />;
    default:
      return <Guest />;
  }
};

export default GameStarted;
