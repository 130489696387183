const ROLES = {
  virtuoso: [
    {
      index: 1,
      content: "TUTORIAL_VIRTUOSO_1",
      target: "#tutorial-virtuoso-1",
    },
    {
      index: 2,
      content: "TUTORIAL_VIRTUOSO_2",
      target: "#tutorial-virtuoso-2",
    },
    {
      index: 3,
      content: "TUTORIAL_VIRTUOSO_3",
      target: "#tutorial-virtuoso-3",
    },
    {
      index: 4,
      content: "TUTORIAL_VIRTUOSO_4",
      target: "#tutorial-virtuoso-4",
    },
    {
      index: 5,
      content: "TUTORIAL_VIRTUOSO_5",
      target: "#tutorial-virtuoso-5",
    },
    {
      index: 6,
      content: "TUTORIAL_VIRTUOSO_6",
      target: "#tutorial-virtuoso-6",
    },
    {
      index: 7,
      content: "TUTORIAL_VIRTUOSO_7",
      target: "#tutorial-virtuoso-7-8",
    },
    {
      index: 8,
      content: "TUTORIAL_VIRTUOSO_8",
      target: "#tutorial-virtuoso-7-8",
    },
  ],
  gamer: [
    {
      index: 1,
      content: "TUTORIAL_GAMER_1",
      target: "#tutorial-gamer-1",
    },
    {
      index: 2,
      content: "TUTORIAL_GAMER_2",
      target: "#tutorial-gamer-2",
    },
  ],
  guest: [
    {
      index: 1,
      content: "TUTORIAL_GUEST_1",
      target: "#tutorial-guest-1",
    },
    {
      index: 2,
      content: "TUTORIAL_GUEST_3",
      target: "#tutorial-guest-3",
    },
    {
      index: 3,
      content: "TUTORIAL_GUEST_2",
      target: "#tutorial-guest-2",
    },
  ],
};

export default ROLES;
