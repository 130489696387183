import { createSlice } from "@reduxjs/toolkit";

import toastr from "toastr";

const initialState = {
  client: null,
  localAudioTrack: null,
  remoteAudioTrack: null,
  options: {
    appId: "",
    channel: "",
    token: "",
    uid: 0,
  },
  connectionQuality: "good",
  isConnected: false,
  isMuted: false,
  audioFileTrack: {},
  audioFileTracks: [],
  audioFileTrackDuration: 0,
  audioFileTracksDuration: 0,
  microphonePermission: false,
  connectedInputDevices: [],
  connectedOutputDevices: [],
  showMutedInfo: true,
  connectionState: "DISCONNECTED",
};

export const agoraSlice = createSlice({
  name: "agora",
  initialState,
  reducers: {
    init: () => {},
    initSuccess: (state, action) => {
      state.client = action.payload;
    },
    joinRoom: () => {},
    joinRoomSuccess: (state, action) => {
      state.options = action.payload;
      state.isConnected = true;
    },
    leaveRoom: () => {},
    leaveRoomSuccess: (state) => {
      state.options = initialState.options;
      state.audioFileTrack = initialState.audioFileTrack;
      state.audioFileTracks = initialState.audioFileTracks;
      state.audioFileTrackDuration = initialState.audioFileTrackDuration;
      state.audioFileTracksDuration = initialState.audioFileTracksDuration;
      state.isConnected = false;
    },
    connectToPlayer: () => {},
    devicesRequest: () => {},
    devicesRequestSuccess: (state, action) => {
      state.connectedInputDevices = action.payload.input;
      state.connectedOutputDevices = action.payload.output;
    },

    connectDevices: () => {},
    connectDevicesSuccess: (state, action) => {
      state.localAudioTrack = action.payload;
      state.microphonePermission = true;
    },
    connectDevicesFail: (state) => {
      state.microphonePermission = false;

      toastr.clear();
      toastr.error("Mikrofon erişimi sağlanamadı.");
    },
    setInputDevice: () => {},
    setInputDeviceSuccess: (state, action) => {
      state.localAudioTrack = action.payload;
    },

    setOutputDevice: () => {},

    mutePlayer: () => {},
    mutePlayerSuccess: (state) => {
      state.isMuted = true;
    },
    unmutePlayer: () => {},
    unmutePlayerSuccess: (state) => {
      state.isMuted = false;
    },
    toggleMute: (state, action) => {
      state.isMuted = action.payload;
    },
    resetPlayerAudioFix: () => {},
    setConnectionQuality: (state, action) => {
      state.connectionQuality = action.payload;
    },
    playSingleAudioFile: () => {},
    playSingleAudioFileStart: (state, action) => {
      state.audioFileTrack = action.payload;
    },
    playSingleAudioFileEnd: (state) => {
      state.audioFileTrack = {};
    },
    stopSingleAudioFile: () => {},
    stopSingleAudioFileSuccess: (state) => {
      state.audioFileTrack = {};
    },
    playMultipleAudioFiles: () => {},
    playMultipleAudioFilesStart: (state, action) => {
      state.audioFileTracks = action.payload;
      state.audioFileTracksDuration = action.payload.reduce(
        (acc, curr) => acc + curr.duration,
        0
      );
    },
    playMultipleAudioFilesEnd: (state) => {
      state.audioFileTracks = [];
    },
    pausePlayMultipleAudioFiles: () => {},
    pausePlaySingleAudioFile: () => {},
    seekSingleAudioFile: () => {},
    setShowMutedInfo: (state, action) => {
      state.showMutedInfo = action.payload;
    },
    setConnectionState: (state, action) => {
      state.connectionState = action.payload;
    },
    setAudioFileTrack: (state, action) => {
      state.audioFileTrack = action.payload;
    },
    setAudioFileTracks: (state, action) => {
      state.audioFileTracks = action.payload;
    }
  },
});

export const {
  init,
  initSuccess,
  joinRoom,
  joinRoomSuccess,
  connectToPlayer,
  devicesRequest,
  devicesRequestSuccess,
  connectDevices,
  connectDevicesSuccess,
  connectDevicesFail,
  setInputDevice,
  setInputDeviceSuccess,
  setOutputDevice,
  mutePlayer,
  mutePlayerSuccess,
  unmutePlayer,
  unmutePlayerSuccess,
  resetPlayerAudioFix,
  setConnectionQuality,
  playSingleAudioFile,
  playSingleAudioFileStart,
  playSingleAudioFileEnd,
  stopSingleAudioFile,
  stopSingleAudioFileSuccess,
  playMultipleAudioFiles,
  playMultipleAudioFilesStart,
  playMultipleAudioFilesEnd,
  pausePlayMultipleAudioFiles,
  pausePlaySingleAudioFile,
  seekSingleAudioFile,
  leaveRoom,
  leaveRoomSuccess,
  setShowMutedInfo,
  toggleMute,
  setConnectionState,
  setAudioFileTrack,
  setAudioFileTracks
} = agoraSlice.actions;

export default agoraSlice.reducer;
