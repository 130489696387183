/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import Card from "../../../components/Card";
import RECORD_PARTS from "../../../utils/constants/RECORD_PARTS";
import Note from "../../Gamer/components/Note";

import PlayIcon from "../../../assets/icons/play-icon.svg";
import ROLES from "../../../utils/constants/TUTORIAL";

import { Steps } from "intro.js-react";
import { TYPES } from "../Tutorial";

import { Portal } from "react-portal";

const notes = [
  {
    partId: 1,
    iconId: 1,
    order: 1,
  },
  {
    partId: 2,
    iconId: 2,
    order: 2,
  },
];

const Gamer = ({
  translate,
  selectedRole,
  canStart,
  roleId,
  tourOptions,
  onComplete,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [tourEnabled, setTourEnabled] = useState(false);

  const [iconStyles, setIconStyles] = useState({});

  useEffect(() => {
    checkIconPositions();

    window.addEventListener("resize", checkIconPositions, false);

    return () =>
      window.removeEventListener("resize", checkIconPositions, false);
  }, []);

  useEffect(() => {
    if (activeStep === 2) {
      setIsPlaying(2);
    }
  }, [activeStep]);

  useEffect(() => {
    if (selectedRole === roleId && canStart)
      setTimeout(() => setTourEnabled(true), 400);
    else if (selectedRole !== roleId) setTourEnabled(false);
  }, [selectedRole, canStart, roleId]);

  const checkIconPositions = () => {
    const el = document.getElementById("role-gamer");

    if (el) {
      const { width, height, left, top } = el.getBoundingClientRect();
      setIconStyles({
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
      });
    }
  };

  return (
    <>
      {tourEnabled && (
        <Portal>
          <div
            className="icon d-flex justify-content-center align-items-center tutorial-type-icon highlighted position-absolute"
            role="button"
            id="role-1-light"
            style={iconStyles}
          >
            <img src={TYPES[1].icon} alt={TYPES[1].title} className="h-100" />
            <div className="role-name position-absolute text-uppercase fw-bold">
              {translate(TYPES[1]?.title)}
            </div>
          </div>
        </Portal>
      )}
      <Steps
        initialStep={0}
        enabled={tourEnabled}
        steps={ROLES["gamer"].map((step) => ({
          element: document.querySelector(step.target),
          intro: translate(step.content),
        }))}
        onExit={() => {
          setTourEnabled(false);
        }}
        onChange={(step) => {
          setActiveStep(step + 1);
        }}
        options={tourOptions}
        onComplete={onComplete}
      />

      <div className="game-layout w-100 h-100 position-relative">
        <div
          className="gamer container-fluid main d-flex flex-column align-items-center justify-content-between w-100 h-100 py-2 overflow-auto"
          style={{
            backgroundImage: `url(${RECORD_PARTS[1]?.box})`,
          }}
        >
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-10 col-lg-8 h-100">
              <Card className="d-flex align-items-center justify-content-between overflow-auto h-100 pt-4">
                <div
                  className="notes-holder d-flex flex-row justify-content-center align-items-center w-100 h-100"
                  id="tutorial-gamer-1"
                >
                  {notes
                    ?.sort((a, b) => a.order - b.order)
                    ?.map((note) => (
                      <Note
                        key={note?.partId}
                        note={note}
                        isPlaying={isPlaying === note?.partId}
                        handlePlay={() => false}
                        customId={note?.partId === 2 ? "tutorial-gamer-2" : ""}
                      />
                    ))}
                </div>
                <div className="play-button-area d-none flex-column align-items-center justify-content-center py-2">
                  <div className="play-button d-flex align-items-center justify-content-center">
                    <img
                      className="play-icon"
                      src={PlayIcon}
                      alt="Musing Logo"
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gamer;
