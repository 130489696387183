const Select = ({ title, secondTitle, options, value, onChange, disabled }) => {
  return (
    <div
      className="musing-select custom-select my-3"
      data-is-disabled={disabled}
    >
      <div className="d-flex align-items-center justify-content-between px-1">
        {title && <p className="mb-0">{title}</p>}
        {secondTitle && <p className="mb-0 text-primary">{secondTitle}</p>}
      </div>
      <select className="form-select my-1" value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
