import AVATARS from "../constants/AVATARS";
import PropTypes from "prop-types";

const Avatar = ({ id, alt, ...rest }) => (
  <img
    src={id ? AVATARS[id] : AVATARS["default"]}
    alt={alt || "Default Avatar"}
    {...rest}
  />
);

Avatar.propTypes = {
  id: PropTypes.string,
  alt: PropTypes.string,
};

export default Avatar;
