import { useEffect, useState } from "react";
import { useTranslate } from "../../utils/hooks/useTranslate";

const ResolutionInfo = () => {
  const [show, setShow] = useState(false);
  const [isPortrait, setIsPortrait] = useState(true);

  const { translate } = useTranslate();

  const checkSize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const ua = navigator.userAgent;
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      );

    if (isMobile) {
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      setIsPortrait(isPortrait);
      if (!isPortrait) {
        if (width < 360 || height < 580) setShow(true);
      } else setShow(false);
    } else {
      if (width < 360 || height < 580) setShow(true);
      else setShow(false);
    }
  };

  useEffect(() => {
    checkSize();

    window.addEventListener("resize", checkSize, false);

    return () => window.removeEventListener("resize", checkSize, false);
  }, []);

  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "auto";
  }, [show]);

  return (
    show && (
      <div className="resolution-info-holder vw-100 vh-100 position-fixed top-0 justify-content-center align-items-center">
        <h2 className="text text-center p-5">
          {/* {!isPortrait
            ? "Lütfen mobil cihazınızı dikey konuma getirin."
            : "En iyi deneyim için tarayıcınızın boyutu 360x580 pikselden büyük olmalıdır!"} */}
          {!isPortrait
            ? translate("MOBILE_ORIENTATION_ERROR")
            : translate("WINDOW_RESOLUTION_ERROR")}
        </h2>
      </div>
    )
  );
};

export default ResolutionInfo;
