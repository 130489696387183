import Button from "../../components/Button";
import Input from "../../components/Input";
import SelectAvatar from "../../components/SelectAvatar";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import PreGameLayout from "../../layouts/PreGameLayout";
import API from "../../utils/API";

const EnterGame = ({ translate }) => {
  const { navigate, updateGameState, gameState } = useContext(AppContext);

  const [userInfo, setUserInfo] = useState({
    name: "",
    avatar: 0,
  });
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = () => {
    const { name, avatar: avatarId } = userInfo;
    API.putData("Gamer", { name, avatarId })
      .then(() => {
        updateGameState({
          player: {
            ...gameState.player,
            name,
            avatar: avatarId,
          },
        });
        navigate("before-start-game");
      })
      .catch(() => setIsSending(false));
  };

  return (
    <PreGameLayout
      title={translate("GAMER_INFO_TITLE")}
      description={translate("GAMER_INFO_DESCRIPTION")}
    >
      <div className="d-flex flex-column align-items-center justify-content-center gap-4">
        <SelectAvatar
          handleSelect={(avatar) => setUserInfo({ ...userInfo, avatar })}
          avatar={userInfo.avatar}
          translate={translate}
        />
        <div className="mb-3">
          <Input
            placeholder="Mozart"
            name="name"
            label={translate("GAMER_INFO_NAME_INPUT")}
            value={userInfo.name}
            onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
            inputProps={{
              maxLength: 11,
            }}
          />
        </div>
        {translate("GAMER_INFO_START") && (
          <Button
            onClick={handleSubmit}
            disabled={
              isSending || userInfo?.name === "" || userInfo?.avatar === 0
            }
            className="py-2"
          >
            {translate("GAMER_INFO_START")}
          </Button>
        )}
      </div>
    </PreGameLayout>
  );
};

export default EnterGame;
