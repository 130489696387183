import CircleButton from "./CircleButton";
import MutedIcon from "../assets/icons/muted.svg";
import UnmutedIcon from "../assets/icons/unmuted.svg";
import { useDispatch, useSelector } from "react-redux";

import closeIcon from "../assets/icons/close-icon.svg";
import { setShowMutedInfo, toggleMute } from "../utils/redux/Agora/agoraSlice";
import { useTranslate } from "../utils/hooks/useTranslate";

const MuteButton = ({ hide }) => {
  const { isMuted, showMutedInfo } = useSelector((state) => state.agora);
  const { translate } = useTranslate();

  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(toggleMute(!isMuted));
  };

  return (
    <div className="mute-button position-relative" data-hide={hide}>
      <div
        className="show-muted-info position-absolute top-0 right"
        data-show={showMutedInfo && !hide}
      >
        {translate("MUTE_BUTTON_TOOLTIP")}
        <div
          className="close position-absolute"
          role="button"
          onClick={() => dispatch(setShowMutedInfo(false))}
        >
          <img src={closeIcon} alt="Close Icon" />
        </div>
      </div>
      <CircleButton onClick={handleToggle}>
        <img
          width={30}
          src={isMuted ? MutedIcon : UnmutedIcon}
          alt="Mute Icon"
        />
      </CircleButton>
    </div>
  );
};

export default MuteButton;
