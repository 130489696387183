const TRANSLATE = {
  COPIED: "",
  MIO_FAQ_TEXT: "",
  WELCOME_TITLE: "",
  WELCOME_DESCRIPTION: "",
  WELCOME_START: "",
  AGGREMENT_CONFIRM: "",
  GAMER_INFO_TITLE: "",
  GAMER_INFO_DESCRIPTION: "",
  GAMER_INFO_SELECT_AVATAR: "",
  GAMER_INFO_CONFIRM_AVATAR: "",
  GAMER_INFO_NAME_INPUT: "",
  GAMER_INFO_START: "",
  MIC_TEST_TITLE: "",
  MIC_TEST_DESCRIPTION: "",
  MIC_TEST_READY: "",
  MIC_TEST_INFO: "",
  MIC_TEST_ERROR: "",
  CONNECTED: "",
  NOTCONNECTED: "",
  MIC_TEST_SPEAKER_MODAL_TITLE: "",
  MIC_TEST_MIC_MODAL_TITLE: "",
  ROOM_LIST_JOIN_ROOM: "",
  ROOM_LIST_ERROR_1: "",
  MODERATOR_HELLO: "",
  MODERATOR_DESCRIPTION: "",
  MODERATOR_ROOM_NAME: "",
  MODERATOR_CONTINUE: "",
  ROOM_LIST_TITLE: "",
  ROOM_LIST_CREATE: "",
  ROOM_LIST_READY: "",
  ROOM_LIST_IN_GAME: "",
  ROOM_LIST_FULL: "",
  ROOM_LEVEL_TITLE: "",
  ROOM_LEVEL_DESCRIPTION: "",
  ROOM_LEVEL_EASY: "",
  ROOM_LEVEL_NORMAL: "",
  ROOM_LEVEL_HARD: "",
  ROOM_LEVEL_CONTINUE: "",
  ROOM_WAITING_TITLE: "",
  ROOM_WAITING_DESCRIPTION: "",
  ROOM_WAITING_NEXT_TRACK_TITLE: "",
  ROOM_WAITING_NEXT_TRACK_DESCRIPTION: "",
  ROOM_START_TITLE: "",
  ROOM_START_DESCRIPTION: "",
  ROOM_START_START_GAME: "",
  ROOM_MIO_SELECTING_TRACK: "",
  ROOM_MIO_SELECT_BOX_INFO_TITLE: "",
  ROOM_MIO_SELECT_BOX_INFO_DESCRIPTION: "",
  ROOM_COUNT_DOWN: "",
  ROOM_SCORE: "",
  ROOM_DIFFICULTY: "",
  MODAL_TRACK_END_TITLE: "",
  MODAL_TRACK_END_DESCRIPTION: "",
  MODAL_TRACK_END_POINT: "",
  MODAL_TRACK_END_GAME_POINT: "",
  MODAL_TRACK_END_CONTINUE: "",
  MODAL_GAME_END_TITLE: "",
  MODAL_GAME_END_DESCRIPTION: "",
  MODAL_GAME_END_POINT: "",
  MODAL_GAME_END_RANKING: "",
  MODAL_GAME_END_CONTINUE: "",
  MODAL_GAMER_WELCOME_TITLE: "",
  MODAL_GAMER_WELCOME_DESCRIPTION: "",
  MODAL_GAMER_WELCOME_CONTINUE: "",
  MODAL_VIRTUOSO_WELCOME_TITLE: "",
  MODAL_VIRTUOSO_WELCOME_DESCRIPTION: "",
  MODAL_VIRTUOSO_WELCOME_CONTINUE: "",
  MODAL_CHECK_TRACK_TITLE: "",
  MODAL_CHECK_TRACK_DESCRIPTION: "",
  MODAL_CHECK_TRACK_CORRECT: "",
  MODAL_CHECK_TRACK_WRONG: "",
  MODAL_CHECK_TRACK_TRY_AGAIN: "",
  MODAL_GAMER_DISCONNECT_MIO: "",
  MODAL_GAMER_DISCONNECT_TITLE: "",
  MODAL_GAMER_DISCONNECT_DESCRIPTION: "",
  MODAL_GAMER_DISCONNECT_CONTINUE: "",
  MODAL_MODERATOR_DISCONNECT_MIO: "",
  MODAL_MODERATOR_DISCONNECT_TITLE: "",
  MODAL_MODERATOR_DISCONNECT_DESCRIPTION: "",
  MODAL_MODERATOR_DISCONNECT_CONTINUE: "",
  MODAL_GUEST_DISCONNECT_MIO: "",
  MODAL_GUEST_DISCONNECT_TITLE: "",
  MODAL_GUEST_DISCONNECT_DESCRIPTION: "",
  MODAL_GUEST_DISCONNECT_CONTINUE: "",
  GAME_END_TITLE: "",
  GAME_END_DESCRIPTION: "",
  GAME_END_MEET_LINK: "",
  GAME_END_SPOTIFY_TEXT: "",
  GAME_END_SPOTIFY_LINK: "",
  GAME_END_SHARE_OPINION: "",
  MODAL_OPINION_TITLE: "",
  MODAL_OPINION_DESCRIPTION: "",
  MODAL_OPINION_TEXTAREA: "",
  MODAL_OPINION_SEND: "",
  MODAL_OPINION_SUCCESS: "",
  MODAL_OPINION_CLOSE: "",
  VIRTUOSO_TRACK_ORDER_TOOLTIP: "",
  CONTINUE: "",
  PAUSE: "",
  SPEAKER: "",
  MICROPHONE: "",
  LISTEN_MUSIC: "",
  CHECK_PART_ORDER: "",
  LISTEN_PARTS: "",
  MORE_THAN_24_HOURS_TO_START: "",
  HOURS_LEFT_TO_START: "",
  AGREEMENT_SUMMARY: "",
  LISTENER_JOIN: "",
  LISTENER_LEAVE: "",
  LISTENER_JOINED: "",
  MODAL_ROOM_LIST_GUEST_JOIN: "",
  MODAL_ROOM_LIST_GUEST_DESCRIPTION: "",
  MODAL_ROOM_LIST_GUEST_TITLE: "",
  MODAL_GUEST_WELCOME_CONTINUE: "",
  MODAL_GUEST_WELCOME_DESCRIPTION: "",
  MODAL_GUEST_WELCOME_TITLE: "",
  MODAL_GAMER_CANT_CONTINUE_MIO: "",
  MODAL_GAMER_CANT_CONTINUE_TITLE: "",
  MODAL_GAMER_CANT_CONTINUE_DESCRIPTION: "",
  MODAL_MODERATOR_CANT_CONTINUE_MIO: "",
  MODAL_MODERATOR_CANT_CONTINUE_TITLE: "",
  MODAL_MODERATOR_CANT_CONTINUE_DESCRIPTION: "",
  MODAL_GUEST_CANT_CONTINUE_MIO: "",
  MODAL_GUEST_CANT_CONTINUE_TITLE: "",
  MODAL_GUEST_CANT_CONTINUE_DESCRIPTION: "",
  GAME_END_MEET_MODAL_TITLE: "",
  GAME_END_MEET_MODAL_DESCRIPTION: "",
  GAME_END_MEET_MODAL_CODE: "",
  GAME_END_MEET_MODAL_PASSWORD: "",
  GAME_END_MEET_MODAL_JOIN: "",
  GAME_USER_DISCONNECTED_TOOLTIP: "",
  GAME_USER_WAITING_TOOLTIP: "",
  DEMO_SOUND_TEST_TITLE: "",
  DEMO_SOUND_TEST_DESCRIPTION: "",
  DEMO_SOUND_TEST_ERROR: "",
  DEMO_WELCOME_TITLE: "",
  DEMO_WELCOME_DESCRIPTION: "",
  DEMO_WELCOME_NAME_INPUT: "",
  DEMO_WELCOME_START: "",
  DEMO_SELECT_AVATAR: "",
  DEMO_CONFIRM_AVATAR: "",
  DEMO_MIO_SELECTING_TRACK: "",
  DEMO_END_TITLE: "",
  DEMO_END_DESCRIPTION: "",
  DEMO_END_GAME_POINT: "",
  DEMO_TRACK_PART_PLAY_TOOLTIP: "",
  THREE_MINUTES_UNTIL_AUDIO_DISCONNECTION: "",
  GAME_OVER: "",
  YOU_LEFT_THE_GAME: "",
  TUTORIAL_NEXT_BUTTON: "",
  TUTORIAL_PREV_BUTTON: "",
  TUTORIAL_DONE_BUTTON: "",
  WINDOW_RESOLUTION_ERROR: "",
  MOBILE_ORIENTATION_ERROR: "",
  AGORA_RECONNECTING: "",
  AGORA_RECONNECTED: "",
  MODAL_SKIP_TITLE: "",
  MODAL_SKIP_TEXT: "",
  SKIP: "",
  NO_SKIP_LEFT: "",
  YES: "",
  NO: "",
  AGORA_MICROPHONE_CHANGED: "",
  LOADING_TIP_TEXT: "",
  MODAL_LEAVE_ROOM_TITLE: "",
  MODAL_LEAVE_ROOM_TEXT: "",
  LEAVE_ROOM: "",
  FAQ_PAGE_INFORMATION: "",
  MUTE_BUTTON_TOOLTIP: "",
  LEAVE_ROOM_TOOLTIP: "",
  ONLY_MODERATOR_CAN_SKIP: "",
  INFORMATION: "",
  COOKIE_AGREEMENT_TEXT: "",
  COOKIE_AGREEMENT_ACCEPT: "",
  TUTORIAL_MODAL_TITLE: "",
  ONLY_YOU_CAN_SKIP: "",
  AUDIO_TEST_CONNECTING: "",
  AUDIO_TEST_CONNECTED: "",
  AUDIO_TEST_FAILED: "",
  AUDIO_TEST_SPEAKER: "",
  AUDIO_TEST_MICROPHONE: "",
  ASSISTANT_MIO_NEXT: "",
  GUEST_LIST_TITLE: "",
  BACKEND_CONNECTION_LOST: "",
  BACKEND_RECONNECTING: "",
  BACKEND_RECONNECTED: "",
  AUDIO_CONNECTED_WITH_USER: "",
  SOCKET_TEST_CONNECTING: "",
  SOCKET_TEST_CONNECTED: "",
  SOCKET_TEST_FAILED: "",
  SOCKET_TEST_ERROR: "",
  TUTORIAL_DEMO_TITLE: ""
};
export default TRANSLATE;
