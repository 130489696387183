import { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Card from "../../components/Card";
import PlayIcon from "../../assets/icons/play-icon.svg";
import RECORD_PARTS from "../../utils/constants/RECORD_PARTS";
import { AppContext } from "../../context/AppContext";
import Note from "./components/Note";
import GameDescriptionModal from "../../components/GameDescriptionModal";
import CongratulationsModal from "../../components/CongratulationsModal";
import ReactConfetti from "react-confetti";
import { useParams } from "react-router";
import Tutorial from "../Tutorial/Tutorial";
import { useDispatch, useSelector } from "react-redux";
import { setCompletedTutorial } from "../../utils/redux/Game/gameSlice";

const Guest = () => {
  const { gameState, roomState, app, updateGameState } = useContext(AppContext);

  const [firstTimeGuest, setFirstTimeGuest] = useState(true);

  const { tokenString } = useParams();
  const { completedTutorials } = useSelector((state) => state.game);
  const dispatch = useDispatch();

  useEffect(() => {
    updateGameState({ player: { ...gameState.player, role: "guest" } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const localGuestToken = localStorage.getItem("guest-player-token");

    setFirstTimeGuest(!localGuestToken || localGuestToken !== tokenString);

    if (!localGuestToken || localGuestToken !== tokenString)
      localStorage.setItem("guest-player-token", tokenString);
  }, [tokenString]);

  return (
    <div
      className="gamer container-fluid main d-flex flex-column align-items-center justify-content-between"
      style={{
        backgroundImage: `url(${
          RECORD_PARTS[gameState?.player?.box - 1]?.box || RECORD_PARTS[0]?.box
        })`,
      }}
    >
      {process.env.REACT_APP_DEV_MODE !== "true" &&
        !completedTutorials?.guest && (
          <Tutorial
            handleContinueGame={() => dispatch(setCompletedTutorial("guest"))}
            defaultRole="guest"
          />
        )}
      <Header />
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-10 col-lg-8">
          <Card className="d-flex align-items-center justify-content-between">
            <div className="notes-holder d-flex flex-row justify-content-center align-items-center w-100 h-100">
              {roomState?.records
                .filter((note) =>
                  roomState?.selectedParts?.length
                    ? roomState?.selectedParts?.includes(note?.partId)
                    : false
                )
                .sort(
                  (a, b) =>
                    roomState?.selectedParts.indexOf(a?.partId) -
                    roomState?.selectedParts.indexOf(b?.partId)
                )
                .map((note) => (
                  <Note
                    key={note?.partId}
                    note={note}
                    isSelected={roomState.selectedParts.includes(note?.partId)}
                    selectedOrder={
                      roomState.selectedParts.indexOf(note?.partId) + 1
                    }
                  />
                ))}
              {roomState?.records
                .filter((note) =>
                  roomState?.selectedParts?.length
                    ? !roomState?.selectedParts.includes(note?.partId)
                    : true
                )
                ?.sort((a, b) => a.order - b.order)
                ?.map((note) => (
                  <Note
                    key={note?.partId}
                    note={note}
                    isSelected={
                      roomState.selectedParts?.length
                        ? roomState.selectedParts.includes(note?.partId)
                        : false
                    }
                    selectedOrder={
                      roomState.selectedParts?.length
                        ? roomState.selectedParts.indexOf(note?.partId) + 1
                        : -1
                    }
                  />
                ))}
            </div>
            <div className="play-button-area d-none flex-column align-items-center justify-content-center py-2">
              <div className="play-button d-flex align-items-center justify-content-center">
                <img className="play-icon" src={PlayIcon} alt="Musing Logo" />
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className="footer-placeholder">&nbsp;</div>

      {firstTimeGuest && <GameDescriptionModal type="GUEST" />}
      {app?.modal?.modalType === "TrackSuccess" && (
        <CongratulationsModal
          show={app?.modal?.modalType === "TrackSuccess"}
          roundPoint={app?.modal?.data?.trackPoint}
          gamePoint={app?.modal?.data?.gamePoint}
          artistName={app?.modal?.data?.artistName}
          trackName={app?.modal?.data?.trackName}
          cover={app?.modal?.data?.artistImageUrl}
        />
      )}
      {app?.modal?.modalType === "TrackSuccess" && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200}
          recycle={app?.modal?.modalType === "TrackSuccess"}
          gravity={0.1}
        />
      )}
    </div>
  );
};

export default Guest;
