const Input = ({ label, name, value, placeholder, onChange, inputProps }) => {
  return (
    <div className="input-holder d-flex flex-column align-items-center">
      <label className="text-white mb-1 fs-5 fw-bold">{label}</label>
      <div className="input-area position-relative">
        <input
          className="input"
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          {...inputProps}
        />
        {inputProps?.maxLength && (
          <span className="input-char-limit position-absolute">
            {parseInt(inputProps.maxLength) - value.length}/
            {inputProps.maxLength}
          </span>
        )}
      </div>
    </div>
  );
};

export default Input;
