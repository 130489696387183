/* eslint-disable no-unused-vars */
import Card from "../../../components/Card";
import NOTES from "../../../utils/constants/NOTES";
import RECORD_PARTS from "../../../utils/constants/RECORD_PARTS";
import Note from "../../Virtuoso/components/Note";

import RemoveButton from "../../../assets/icons/remove-button.svg";
import PlayerButton from "../../../components/PlayerButton";
import { useEffect, useState } from "react";
import ROLES from "../../../utils/constants/TUTORIAL";

import { Steps } from "intro.js-react";
import { TYPES } from "../Tutorial";

import { Portal } from "react-portal";

const notes = [
  {
    partId: 1,
    iconId: 1,
    order: 1,
    onPlayer: false,
  },
  {
    partId: 2,
    iconId: 2,
    order: 2,
    onPlayer: false,
  },
  {
    partId: 3,
    iconId: 3,
    order: 3,
    onPlayer: false,
  },
  {
    partId: 4,
    iconId: 4,
    order: 4,
    onPlayer: false,
  },
  {
    partId: 5,
    iconId: 5,
    order: 5,
    onPlayer: false,
  },
];

const onPlayerNotesEmpty = [{}, {}, {}, {}, {}];

const onPlayerNotesFilled = [
  {
    partId: 3,
    iconId: 3,
    order: 3,
    onPlayer: true,
  },
  {
    partId: 5,
    iconId: 5,
    order: 5,
    onPlayer: true,
  },
  {},
  {},
  {},
];

const onPlayerNotesFull = [
  {
    partId: 3,
    iconId: 3,
    order: 3,
    onPlayer: true,
  },
  {
    partId: 5,
    iconId: 5,
    order: 5,
    onPlayer: true,
  },
  {
    partId: 2,
    iconId: 2,
    order: 2,
    onPlayer: true,
  },
  {
    partId: 1,
    iconId: 1,
    order: 1,
    onPlayer: true,
  },
  {
    partId: 4,
    iconId: 4,
    order: 4,
    onPlayer: true,
  },
];

const Virtuoso = ({
  translate,
  selectedRole,
  canStart,
  roleId,
  tourOptions,
  onComplete,
}) => {
  const [isPlaying] = useState(false);
  const [isPaused] = useState(false);
  const [isPlayingSong, setIsPlayingSong] = useState(false);
  const [isPausedSong] = useState(false);
  const [songProgress, setSongProgress] = useState(0);
  const [songTime, setSongTime] = useState("00:00/00:00");
  const [onPlayerNotes, setOnPlayerNotes] = useState(onPlayerNotesEmpty);

  const [activeStep, setActiveStep] = useState(0);
  const [tourEnabled, setTourEnabled] = useState(false);

  const [iconStyles, setIconStyles] = useState({});

  useEffect(() => {
    checkIconPositions();
    checkIfElementIsVisible();

    window.addEventListener("resize", checkIconPositions, false);
    window.addEventListener("resize", checkIfElementIsVisible, false);

    return () => {
      window.removeEventListener("resize", checkIconPositions, false);
      window.removeEventListener("resize", checkIfElementIsVisible, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeStep < 2) {
      setIsPlayingSong(false);
      setSongProgress(0);
      setSongTime("00:00/00:00");
    } else if (activeStep >= 2 && activeStep < 3) {
      setIsPlayingSong(true);
      setSongProgress(45);
      setSongTime("00:13/00:30");
    } else if (activeStep < 5) setOnPlayerNotes(onPlayerNotesEmpty);
    else if (activeStep >= 5 && activeStep < 8)
      setOnPlayerNotes(onPlayerNotesFilled);
    else if (activeStep >= 8) setOnPlayerNotes(onPlayerNotesFull);
  }, [activeStep]);

  useEffect(() => {
    if (selectedRole === roleId && canStart)
      setTimeout(() => setTourEnabled(true), 400);
    else if (selectedRole !== roleId) setTourEnabled(false);
  }, [selectedRole, canStart, roleId]);

  const checkIconPositions = () => {
    const el = document.getElementById("role-virtuoso");

    if (el) {
      const { width, height, left, top } = el.getBoundingClientRect();
      setIconStyles({
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
      });
    }
  };

  const checkIfElementIsVisible = (step = activeStep) => {
    const els = document.querySelectorAll("[data-tour-step]");

    let stepEl = null;

    els.forEach((el) => {
      const tourStep = el.getAttribute("data-tour-step");

      if (tourStep) {
        if (tourStep.includes(step)) stepEl = el;
      }
    });

    if (stepEl) {
      const { top, left, width, height } = stepEl.getBoundingClientRect();
      const helperEl = document.querySelector(".introjs-helperLayer");

      if (helperEl) {
        const { top: helperTop, left: helperLeft } =
          helperEl.getBoundingClientRect();

        if (top !== helperTop || left !== helperLeft) {
          helperEl.style.top = `${top - 5}px`;
          helperEl.style.left = `${left - 5}px`;
          helperEl.style.width = `${width + 10}px`;
          helperEl.style.height = `${height + 10}px`;
        }
      }
    }
  };

  return (
    <>
      {tourEnabled && (
        <Portal>
          <div
            className="icon d-flex justify-content-center align-items-center tutorial-type-icon highlighted position-absolute"
            role="button"
            id="role-1-light"
            style={iconStyles}
          >
            <img src={TYPES[0].icon} alt={TYPES[0].title} className="h-100" />
            <div className="role-name position-absolute text-uppercase fw-bold">
              {translate(TYPES[0]?.title)}
            </div>
          </div>
        </Portal>
      )}
      <Steps
        initialStep={0}
        enabled={tourEnabled}
        steps={ROLES["virtuoso"].map((step) => ({
          element: document.querySelector(step.target),
          intro: translate(step.content),
        }))}
        onExit={() => {
          setTourEnabled(false);
        }}
        onChange={(step) => {
          setActiveStep(step + 1);
        }}
        options={tourOptions}
        onComplete={onComplete}
        onBeforeChange={(step) => {
          checkIfElementIsVisible(step + 2);
        }}
        onAfterChange={(step) => {
          setTimeout(() => {
            checkIfElementIsVisible(step + 1);
          }, 100);
        }}
      />
      <div className="game-layout w-100 h-100 position-relative">
        <div
          className="virtuoso container-fluid main d-flex flex-column align-items-center justify-content-between w-100 h-100 py-2 overflow-auto"
          style={{
            backgroundImage: `url(${RECORD_PARTS[0]?.box})`,
          }}
        >
          <div className="row d-flex align-items-center justify-content-center h-100 overflow-auto">
            <div className="col-10 col-lg-9 h-100">
              <Card className="d-flex flex-column align-items-center overflow-auto h-100 pt-4 tutorial-card-holder">
                <div
                  className="notes-holder d-flex flex-row justify-content-center align-items-center w-100 h-100"
                  id="tutorial-virtuoso-3"
                  data-id="3"
                  data-tour-step={3}
                >
                  {notes
                    ?.sort((a, b) => a.order - b.order)
                    .map((note, index) => (
                      <Note
                        key={index}
                        note={note}
                        isAdded={
                          !!onPlayerNotes?.find(
                            (playerNote) => playerNote?.partId === note?.partId
                          )
                        }
                        onClick={() => {
                          return false;
                        }}
                        draggable={!note?.onPlayer}
                        onDragStart={() => false}
                        customId={
                          index + 1 === notes?.length
                            ? "tutorial-virtuoso-4"
                            : ""
                        }
                        tourStep={index + 1 === notes?.length ? 4 : ""}
                      />
                    ))}
                </div>
                <div
                  className="pitch-area position-relative player-bg d-flex flex-row align-items-center justify-content-center my-3"
                  id="tutorial-virtuoso-5"
                  data-tour-step={5}
                  data-id="5"
                >
                  {onPlayerNotes.map((playerNote, i) => (
                    <div
                      className={`pitch d-flex flex-column align-items-center justify-content-between cursor-pointer position-relative py-3 ${
                        playerNote?.partId !== null ? "pitch-blue" : ""
                      }`}
                      onDragOver={() => false}
                      onDrop={() => false}
                      key={i}
                      data-index={i}
                      onClick={() => {
                        return false;
                      }}
                      id={i === 1 ? `tutorial-virtuoso-6` : ""}
                      data-tour-step={i === 0 ? [6] : undefined}
                      data-id="6"
                    >
                      <div
                        className="single-track-progress-bar position-absolute w-100 h-100"
                        data-progress={0}
                      ></div>
                      {playerNote?.iconId && (
                        <img
                          src={NOTES[playerNote?.iconId]}
                          alt="note"
                          className="note-icon flex-shrink-0"
                        />
                      )}
                      {playerNote?.partId && (
                        <div
                          className="remove-button d-flex align-items-center justify-content-center"
                          role="button"
                          onClick={() => {
                            return false;
                          }}
                        >
                          <img src={RemoveButton} alt="Remove icon" />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div
                  className="col-10 d-flex align-items-center my-3"
                  id="tutorial-virtuoso-2"
                  data-tour-step={[2]}
                  data-id="2"
                >
                  <div
                    className="play-song-button d-flex flex-shrink-0 align-items-center justify-content-center text-white"
                    id="tutorial-virtuoso-1"
                    data-tour-step={[1]}
                    data-id="1"
                  >
                    <PlayerButton
                      isPlaying={isPlayingSong && !isPausedSong}
                      onClick={() => false}
                      customText={
                        isPlayingSong
                          ? isPausedSong
                            ? translate(`CONTINUE`)
                            : translate(`PAUSE`)
                          : translate(`LISTEN_MUSIC`)
                      }
                      disabled={isPlaying && !isPaused}
                    />
                  </div>
                  <div className="progress mx-3 position-relative">
                    <div
                      className="overlay position-absolute w-100 h-100"
                      role="button"
                      onClick={() => false}
                    ></div>
                    <div
                      className="progress-bar col-12"
                      role="progressbar"
                      aria-valuenow={songProgress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      data-progress={songProgress}
                    ></div>
                    <div
                      className="progress-ball position-absolute"
                      role="button"
                      style={{
                        left: `${songProgress}%`,
                      }}
                    ></div>
                  </div>

                  <p className="progress-text text-white mb-0">{songTime}</p>
                </div>
                <div className="play-button-area d-flex flex-column align-items-center justify-content-center py-2">
                  <PlayerButton
                    customText={
                      isPlaying
                        ? isPaused
                          ? translate(`CONTINUE`)
                          : translate(`PAUSE`)
                        : onPlayerNotes.filter((note) => note?.partId)
                            .length === notes.length
                        ? translate(`CHECK_PART_ORDER`)
                        : translate(`LISTEN_PARTS`)
                    }
                    disabled={
                      onPlayerNotes.filter((note) => note?.partId).length < 1 ||
                      (isPlayingSong && !isPausedSong)
                    }
                    onClick={() => false}
                    //   tooltip={translate("VIRTUOSO_TRACK_ORDER_TOOLTIP")}
                    customId={"tutorial-virtuoso-7-8"}
                    tourStep={[7, 8]}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Virtuoso;
