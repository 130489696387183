import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import GuestAlertMimo from "../../../assets/icons/guest-alert-mimo.svg";
import { useContext } from "react";
import { SignalRContext } from "../../../context/SignalRContext";
import { useTranslate } from "../../../utils/hooks/useTranslate";

const GuestAlertModal = ({ show, onClose, roomId }) => {
  if (show && !roomId) onClose();

  const { joinRoom } = useContext(SignalRContext);
  const { translate } = useTranslate();

  const handleJoin = () => {
    joinRoom(roomId, true);
  };

  return (
    <Modal show={show} toggleModal={onClose}>
      <div className="d-flex flex-column align-items-center">
        <img className="mb-4" width={134} src={GuestAlertMimo} alt="Sad Mimo" />
        <h4
          className="fw-bold text-center my-2"
          dangerouslySetInnerHTML={{
            __html: translate("MODAL_ROOM_LIST_GUEST_TITLE"),
          }}
        />
        <p
          className="text-center px-4 mb-3"
          dangerouslySetInnerHTML={{
            __html: translate("MODAL_ROOM_LIST_GUEST_DESCRIPTION"),
          }}
        />
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Button onClick={handleJoin}>
          {translate("MODAL_ROOM_LIST_GUEST_JOIN")}
        </Button>
      </div>
    </Modal>
  );
};

export default GuestAlertModal;
