import Button from "../../components/Button";
import Countdown from "../../components/Countdown";
import Modal from "../../components/Modal";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import PreGameLayout from "../../layouts/PreGameLayout";
import API from "../../utils/API";

import intro from "../../assets/music/intro-50.mp3";
import isIOS from "../../utils/helpers/isIOS";

const Welcome = ({ translate }) => {
  const { navigate, gameState, updateGameState, updateApp } = useContext(AppContext);
  const [countdown, setCountdown] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [agreementCheckbox, setAgreementCheckbox] = useState(false);
  const [showCookieAgreement, setShowCookieAgreement] = useState(false);

  const audioRef = useRef(null);

  useEffect(() => {
    const cookieAgreement = localStorage.getItem("cookie-agreement");
    if (!cookieAgreement) {
      setShowCookieAgreement(true);
    }
  }, []);

  useEffect(() => {
    if (gameState.token && gameState.auth) {
      API.getData(`Game`).then(({ data: res }) => {
        const { totalSeconds, serverTime, started, language } = res.data;

        if (totalSeconds && serverTime) {
          setCountdown(Date.now() + totalSeconds * 1000);
          updateGameState({
            startTime: totalSeconds,
            serverTime,
            gameStarted: started,
          });
          if (language) updateApp({ language });
        } else {
          console.log(res?.friendlyMessage);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState.token, gameState.auth]);

  const fadeOut = (callback) => {
    if (audioRef.current.volume > 0.001 && !isIOS()) {
      audioRef.current.volume -= 0.001;
      setTimeout(() => fadeOut(callback), 15);
    } else {
      audioRef?.current?.pause();
      callback();
    }
  };

  const handleAgree = (e) => {
    setAgreementCheckbox(e.target.checked);
    setIsSending(true);

    API.postData(`Gamer/agreement`)
      .then(() => {
        fadeOut(() => navigate("enter-game"));
      })
      .catch(() => {
        setIsSending(false);
        setAgreementCheckbox(false);
      });
  };

  return (
    <PreGameLayout
      title={!showCookieAgreement && translate("WELCOME_TITLE")}
      description={!showCookieAgreement && translate("WELCOME_DESCRIPTION")}
      className="welcome"
    >
      {gameState?.startTime &&
      gameState?.serverTime &&
      !gameState?.gameStarted ? (
        <Countdown
          startTime={countdown}
          serverTime={gameState.serverTime}
          onComplete={() => {
            updateGameState({ gameStarted: true });
          }}
        />
      ) : (
        ""
      )}
      {/** 24 saatten çok ise mesaj gösterilecek */}
      {gameState.gameStarted && gameState.auth ? (
        <div
          className={`mt-4 w-100 d-flex justify-content-center ${
            showCookieAgreement ? `opacity-0` : ``
          }`}
        >
          <Button onClick={() => setShowModal(true)}>
            {translate("WELCOME_START")}
          </Button>
        </div>
      ) : (
        ""
      )}

      <Modal show={showModal} hideCloseButton={true}>
        <p
          className="fs-5"
          dangerouslySetInnerHTML={{ __html: translate(`AGREEMENT_SUMMARY`) }}
        />
        <div className="d-flex align-items-center">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            checked={agreementCheckbox}
            id="kvkk"
            name="kvkk"
            onChange={handleAgree}
          />
          <label className="ms-2 fs-5 pt-1 cursor-pointer" htmlFor="kvkk">
            {translate("AGGREMENT_CONFIRM")}{" "}
            {isSending && (
              <span
                className="spinner-border"
                style={{
                  width: "14px",
                  height: "14px",
                  "--bs-spinner-border-width": "0.15rem",
                  "--bs-spinner-width": "14px",
                  "--bs-spinner-height": "14px",
                }}
              />
            )}
          </label>
        </div>
      </Modal>

      <Modal show={showCookieAgreement} hideCloseButton={true}>
        <div className="d-flex flex-column align-items-center p-4 pb-0">
          <p
            className="fs-5 text-center mb-4"
            dangerouslySetInnerHTML={{
              __html: translate(`COOKIE_AGREEMENT_TEXT`),
            }}
          />

          <Button
            className="mt-1"
            onClick={() => {
              localStorage.setItem("cookie-agreement", "true");
              setShowCookieAgreement(false);
            }}
          >
            {translate("COOKIE_AGREEMENT_ACCEPT")}
          </Button>
        </div>
      </Modal>

      <audio
        autoPlay={false}
        loop
        muted
        playsInline
        ref={audioRef}
        onLoadStart={(e) => {
          e.target.muted = true;
          e.target.volume = 0;

          setTimeout(() => {
            e.target.play();
          }, 200);
        }}
        onPlay={(e) => {
          e.target.volume = 0.05;
          e.target.muted = false;
        }}
      >
        <source src={intro} type="audio/mpeg" />
      </audio>
    </PreGameLayout>
  );
};

export default Welcome;
