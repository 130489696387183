import NOTES from "../../../utils/constants/NOTES";

const Note = ({
  note,
  isAdded,
  onClick,
  draggable,
  onDragStart,
  customId,
  tourStep,
}) => {
  return (
    <div
      className="note-holder position-relative d-flex justify-content-center align-items-center"
      onClick={onClick}
      role={isAdded ? "none" : "button"}
      data-is-added={isAdded}
      draggable={draggable}
      onDragStart={onDragStart}
      id={customId || ""}
      data-tour-step={tourStep}
      data-id={tourStep}
    >
      <div className="note-border note-border--1 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--2 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--3 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--4 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--5 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--6 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--7 w-100 h-100 position-absolute"></div>

      <img
        src={NOTES[note?.iconId] || NOTES[0]}
        alt={`Note ${note?.partId}`}
        className="note position-absolute"
      />
      {process.env.REACT_APP_DEV_MODE === "true" ? (
        <span className="text-white position-absolute" style={{ bottom: 0 }}>
          {note?.originalOrder}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Note;
