import { configureStore } from "@reduxjs/toolkit";
import agoraReducer from "./utils/redux/Agora/agoraSlice";
import gameReducer from "./utils/redux/Game/gameSlice";

import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    agora: agoraReducer,
    game: gameReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
