const records = require.context("../../../src/assets/records", true, /\.svg$/);

const RECORD_PARTS = [
  {
    id: 1,
    box: records(`./cover-1.svg`),
    part: records(`./record-part-1.svg`),
  },
  {
    id: 2,
    box: records(`./cover-2.svg`),
    part: records(`./record-part-2.svg`),
  },
  {
    id: 3,
    box: records(`./cover-3.svg`),
    part: records(`./record-part-3.svg`),
  },
  {
    id: 4,
    box: records(`./cover-4.svg`),
    part: records(`./record-part-4.svg`),
  },
  {
    id: 5,
    box: records(`./cover-5.svg`),
    part: records(`./record-part-5.svg`),
  },
  {
    id: 6,
    box: records(`./cover-6.svg`),
    part: records(`./record-part-6.svg`),
  },
  {
    id: 7,
    box: records(`./cover-7.svg`),
    part: records(`./record-part-7.svg`),
  },
  {
    id: 8,
    box: records(`./cover-8.svg`),
    part: records(`./record-part-8.svg`),
  },
];

export default RECORD_PARTS;
