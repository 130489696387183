import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { AppContext } from "./context/AppContext";
import Loading from "./utils/components/Loading";
import GAME_PAGES from "./utils/constants/GAME_PAGES";
import { useTranslate } from "./utils/hooks/useTranslate";
import GameContainer from "./pages/GameContainer";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./utils/redux/Game/gameSlice";
import { useLocation } from "react-router-dom";
import CloseTabInfo from "./utils/components/CloseTabInfo";

const shouldUseGameContainer = (page) => {
  const pages = [
    "room-waiting-next-track",
    "room-track-select-info",
    "room-box-select-info",
    "room-box-select",
    "game-gamer",
    "game-virtuoso",
    "game-guest",
    "game-started",
  ];

  return pages.includes(page);
};

const excludeToken = [
  "en/demo",
  "demo",
  "aydinlatma-metni",
  "disclosure-text",
  "en/disclosure-text",
  "baglanti-testi",
  "connection-check",
  "en/connection-check",
  "dinleyici",
  "listener",
  "en/listener",
  "bilgi-ekrani",
  "tutorial",
  "en/tutorial",
  "sss",
  "faq",
  "en/faq",
]

const Game = ({ loaded, setIsGameLoaded }) => {
  const { tokenString } = useParams();

  const { gameState, updateGameState } = useContext(AppContext);
  const { translate } = useTranslate();
  const {token: stateToken} = useSelector((state) => state.game);
  const dispatch = useDispatch();
  const location = useLocation();
  const isFaqPage = location.pathname?.includes("/faq") || location.pathname?.includes("/sss");

  useEffect(() => {
    if (excludeToken.includes(location.pathname)) {
      return;
    }

    const token = tokenString || localStorage.getItem("access_token");
    const localTimeout = localStorage.getItem("GameOverTimeout");

    if (localTimeout) {
      const { token } = JSON.parse(localTimeout);

      if (token !== tokenString) {
        localStorage.removeItem("GameOverTimeout");
      }
    }

    if (token && tokenString) {
      updateGameState({ token: tokenString });

      if (token !== stateToken) dispatch(setToken(token));
    } else {
      localStorage.removeItem("access_token");
    }
    if (setIsGameLoaded) setIsGameLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenString]);

  const Component = GAME_PAGES[gameState.page];

  if (isFaqPage) return <CloseTabInfo/>;

  return loaded ? (
    shouldUseGameContainer(gameState.page) ? (
      <GameContainer/>
    ) : (
      <Component translate={translate}/>
    )
  ) : (
    <Loading/>
  );
};

export default Game;
