import {
  Children,
  cloneElement,
  isValidElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import GameEndModal from "../components/GameEndModal";
import GamePausedModal from "../components/GamePausedModal";
import { AppContext } from "../context/AppContext";
import { SignalRContext } from "../context/SignalRContext";
import SelectDifficultyModal from "../pages/RoomSelect/components/SelectDifficultyModal";
import ConnectionQuality from "../utils/components/ConnectionQuality";
import AlignAgainModal from "../pages/Virtuoso/components/AlignAgainModal";
import GuestList from "../utils/components/GuestList";
import isIOS from "../utils/helpers/isIOS";
import SkipSong from "../utils/components/SkipSong";
import RemoteUsersList from "../utils/components/RemoteUsers";
import Footer from "../components/Footer";
import { AgoraClient } from "../components/AgoraComponent";

const skippablePages = ["game-gamer", "game-virtuoso", "game-started"];

const noFooterPages = [
  "room-name",
  "room-select",
  "room-start",
  "room-waiting-moderator",
];

const showTimerPages = ["room-track-select-info", "room-box-select-info", "room-track-select", "room-box-select", "game-virtuoso", "game-gamer", "game-guest", "game-started"];

const GameLayout = ({ children }) => {
  const { gameState, updateGameState, roomState, app, updateApp } =
    useContext(AppContext);
  const { connection } = useContext(SignalRContext);
  const [songPlaying, setSongPlaying] = useState(null);

  const songRef = useRef(null);

  useEffect(() => {
    if (!gameState?.connected) updateGameState({ connected: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState?.connected]);

  useEffect(() => {
    if (connection?.state === "Connected") {
      connection.on("ReceiveMessage", (message) => {
        console.log(message);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection?.state]);

  useEffect(() => {
    if (
      app?.modal?.modalType === "TrackSuccess" &&
      !songPlaying &&
      roomState?.fullRecordUrl
    ) {
      songRef.current.src = roomState?.fullRecordUrl;
      songRef.current.load();
      songRef.current.play();
    }
  }, [app?.modal?.modalType, roomState?.fullRecordUrl, songPlaying]);

  useEffect(() => {
    stopSong();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState?.page]);

  const stopSong = () => {
    setSongPlaying(null);
    fadeOut();
  };

  const fadeOut = () => {
    if (!songRef.current) return;

    if (songRef.current.volume > 0.0005 && !isIOS()) {
      songRef.current.volume -= 0.0005;
      setTimeout(() => fadeOut(), 15);
    } else {
      songRef.current.pause();
      songRef.current.src = "";
      songRef.current.load();
    }
  };

  return (
    <div className="game-layout">
      {!isNaN(Number(roomState?.numberOfSkip)) &&
        skippablePages.includes(gameState.page) && (
          <SkipSong
            canSkip={roomState?.moderator?.gamerId === gameState?.player?.id}
          />
        )}
      <GuestList />

      {(window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("webstage")) &&
        AgoraClient.connectionState === "CONNECTED" && <RemoteUsersList />}

      {Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids a
        // typescript error too.
        if (isValidElement(child)) {
          return cloneElement(child, {});
        }
        return child;
      })}

      {!noFooterPages.includes(gameState.page) && (
        <Footer
          showTimer={
            showTimerPages.includes(gameState.page)
          }
        />
      )}
      <ConnectionQuality />
      {roomState.endData && (
        <GameEndModal
          show={Boolean(roomState.endData)}
          leaderboardOrder={roomState.endData?.leaderboardOrder}
          gamePoint={roomState.endData?.gamePoint}
          artistName={roomState.endData?.artistName}
          trackName={roomState.endData?.trackName}
          cover={roomState.endData?.artistImageUrl}
          stopSong={stopSong}
        />
      )}

      {app?.modal?.modalType === "TrackPartCheck" && (
        <AlignAgainModal
          show={app?.modal?.modalType === "TrackPartCheck"}
          onClose={() => {
            updateApp({ modal: null });
          }}
          corrects={app?.modal?.data?.correctCount}
          wrongs={app?.modal?.data?.wrongCount}
        />
      )}

      <GamePausedModal
        show={app?.modal?.modalType === "GamerDisconnect"}
        gamerType="GAMER"
      />

      <GamePausedModal
        show={app?.modal?.modalType === "ModeratorDisconnect"}
        gamerType="MODERATOR"
      />

      <GamePausedModal
        show={app?.modal?.modalType === "GuestDisconnect"}
        gamerType="GUEST"
      />

      <SelectDifficultyModal
        show={app?.modal?.modalType === "RoomLevel"}
        type={
          gameState?.page === "room-name" ? "SetRoomLevel" : "SetNewRoomLevel"
        }
        stopSong={stopSong}
      />

      <audio
        ref={songRef}
        playsInline
        autoPlay={false}
        muted
        onPlay={(e) => {
          e.target.volume = 0.04;
          e.target.muted = false;
          setSongPlaying(true);
        }}
        onPause={(e) => {
          e.target.volume = 0;
          e.target.muted = true;
        }}
        loop
      ></audio>
    </div>
  );
};

export default GameLayout;
