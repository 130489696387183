import { useContext, useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import Info from "../../components/Info";
import { AppContext } from "../../context/AppContext";
import PreGameLayout from "../../layouts/PreGameLayout";

// eslint-disable-next-line no-unused-vars
import AudioCheck from "../../components/AudioCheck";

import introVideoCover from "../../assets/img/intro-video.png";
import isDevMode from "../../utils/helpers/isDevMode";
import { useDispatch, useSelector } from "react-redux";
import { connectDevices, devicesRequest, setInputDevice, setOutputDevice } from "../../utils/redux/Agora/agoraSlice";
import AgoraTest from "../../components/AgoraTest";
import SocketTest from "../../components/SocketTest";

const BeforeStartGame = ({ translate }) => {

  const [output, setOutput] = useState("");
  const [outputs, setOutputs] = useState([]);

  const [input, setInput] = useState("");
  const [inputs, setInputs] = useState([]);

  const [showAudio, setShowAudio] = useState(false);
  const [showMicrophone, setShowMicrophone] = useState(false);

  const [hasError, setHasError] = useState(false);

  const [connectionState, setConnectionState] = useState("CONNECTING");

  const { gameState, navigate, updateGameState, app, setShowFaqModal } = useContext(AppContext);

  const [socketError, setSocketError] = useState(false);

  const { localAudioTrack, connectedInputDevices, connectedOutputDevices } =
    useSelector((state) => state.agora);
  const introVideoCoverRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(devicesRequest());
  }, [dispatch]);

  useEffect(() => {
    const selectedInput = connectedInputDevices?.find(
      (i) => i.isDefault,
    )?.value;

    setInputs(connectedInputDevices);
    setInput(selectedInput);

    dispatch(connectDevices());
  }, [connectedInputDevices, dispatch]);

  useEffect(() => {
    const selectedOutput = connectedOutputDevices?.find(
      (i) => i.isDefault,
    )?.value;
    setOutputs(connectedOutputDevices);
    setOutput(selectedOutput);
  }, [connectedOutputDevices]);

  useEffect(() => {
    if (localAudioTrack && output) {
      updateGameState({ audio: false });
      dispatch(setOutputDevice(output));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output]);

  useEffect(() => {
    if (localAudioTrack && input) {
      updateGameState({ microphone: false });
      dispatch(setInputDevice(input));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const handleAction = (e) => {
    e.preventDefault();

    introVideoCoverRef?.current?.classList?.remove("over");

    setTimeout(() => navigate("room-select"), 600);
  };

  return (
    <>
      <PreGameLayout>
        <div
          className="d-flex flex-column align-items-center justify-content-center mw-100"
          style={{ width: "480px" }}
        >
          <div className="col-9 speech-check">
            <SocketTest onError={err => setSocketError(err)}/>
          </div>
          <div className="col-9 speech-check my-2">
            <AgoraTest
              setConnectionState={setConnectionState}
              connectionState={connectionState}
              playSong={showAudio}
            />
          </div>
          <AudioCheck
            title={translate("MIC_TEST_TITLE_OUTPUT")}
            isConnected={gameState.audio}
            type="audio"
            value={output}
            values={outputs}
            onChange={(e) => setOutput(e.target.value)}
            onSuccess={() => updateGameState({ audio: true })}
            onError={() => setHasError(true)}
            show={showAudio}
            setShow={setShowAudio}
          />
          <AudioCheck
            title={translate("MIC_TEST_TITLE_INPUT")}
            isConnected={gameState.microphone}
            type="microphone"
            value={input}
            values={inputs}
            onChange={(e) => setInput(e.target.value)}
            onSuccess={() => updateGameState({ microphone: true })}
            onError={() => setHasError(true)}
            show={showMicrophone}
            setShow={setShowMicrophone}
          />
          {translate("MIC_TEST_READY") && (
            <div className="mt-4">
              <Button
                disabled={
                  (!gameState?.audio || !gameState?.microphone || socketError) && !isDevMode()
                }
                onClick={handleAction}
              >
                {translate("MIC_TEST_READY")}
              </Button>
            </div>
          )}
          {hasError && translate("MIC_TEST_ERROR") && (
            <div
              className="col-8 mt-4"
              onClick={() => {
                setShowFaqModal(!app.showFaqModal);
              }}
              role="button"
            >
              <Info type="error" text={translate("MIC_TEST_ERROR")}/>
            </div>
          )}

          {socketError && (
            <div
              className="col-8 mt-4"
              onClick={() => {
                setShowFaqModal(!app.showFaqModal);
              }}
              role="button"
            >
              <Info type="error" text={translate("SOCKET_TEST_ERROR")}/>
            </div>
          )}
        </div>
      </PreGameLayout>

      <img
        src={introVideoCover}
        className="position-absolute vw-100 vh-100 intro-video-cover over"
        alt="End video cover"
        ref={introVideoCoverRef}
      />
    </>
  );
};

export default BeforeStartGame;
