import { useEffect, useState } from "react";
import { useContext } from "react";
import AssistantMioIcon from "../assets/icons/assistant-mio.svg";
import CloseIcon from "../assets/icons/close-icon.svg";
import { AppContext } from "../context/AppContext";
import { useTranslate } from "../utils/hooks/useTranslate";

const AssistantMio = () => {
  const { app } = useContext(AppContext);
  const [mioHeight, setMioHeight] = useState(0);

  const [show, setShow] = useState(false);

  const [selectedMioMessage, setSelectedMioMessage] = useState(0);

  const {translate} = useTranslate();

  useEffect(() => {
    if (app?.mio?.length && !show) {
      setShow(true);
    }

    if (!app?.mio?.length && show) {
      setShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.mio]);

  useEffect(() => {
    if (show) {
      const el = document.querySelector(".AssistantMio .content-holder");

      if (el) {
        setMioHeight(el.offsetHeight);
      }
    }

    return () => {
      setTimeout(() => {
        setSelectedMioMessage(0);
      }, 300);
    };
  }, [show]);

  useEffect(() => {
    if (app?.mio?.length > 0) {
      const mioContent = document.querySelector(
        `.AssistantMio .mio-content[data-is-in="true"]`
      );

      if (mioContent) {
        const mioContentWidth = mioContent.offsetWidth;
        const mioContentHeight = mioContent.offsetHeight;

        const contentsEl = document.querySelector(
          ".AssistantMio .mio-contents"
        );

        if (contentsEl) {
          contentsEl.style.width = `${mioContentWidth + 90}px`;
          contentsEl.style.height = `${mioContentHeight}px`;
        }
      }
    }
  }, [app?.mio, selectedMioMessage]);

  return (
    app?.mio?.length > 0 && (
      <div
        className="AssistantMio"
        style={{
          "--mio-height": `${mioHeight}px`,
        }}
        data-is-in={show}
      >
        <div className="content-holder position-absolute d-flex justify-content-between align-items-center">
          <div className="mio-name position-absolute">Mio</div>
          {app.mio.length - 1 === selectedMioMessage && (
            <div
              className="mio-close rounded-circle position-absolute d-flex justify-content-center align-items-center"
              role="button"
              onClick={() => setShow(false)}
            >
              <img src={CloseIcon} alt="Close" />
            </div>
          )}
          {app?.mio?.length > 0 && app?.mio?.length - 1 > selectedMioMessage ? (
            <div
              className="mio-next-button position-absolute user-select-none"
              onClick={() =>
                setSelectedMioMessage((prevState) => prevState + 1)
              }
              role="button"
            >
              {translate("ASSISTANT_MIO_NEXT")}
            </div>
          ) : (
            ""
          )}
          <div
            className="mio-icon flex-shrink-0"
            role="button"
            onClick={() => setShow(!show)}
          >
            <img src={AssistantMioIcon} alt="Mio" className="w-100 h-100" />
          </div>
          <div className="mio-contents position-relative">
            {app?.mio?.map((m, i) => (
              <p
                key={i}
                className="mio-content m-0 position-absolute"
                data-is-in={i === selectedMioMessage}
                dangerouslySetInnerHTML={{ __html: m }}
              />
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default AssistantMio;
