import PreGameLayout from "../../layouts/PreGameLayout";

import { Player } from "@lottiefiles/react-lottie-player";
import loading from "../../assets/videos/loading.json";

const Loading = () => {
  return (
    <PreGameLayout>
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <Player
            autoplay
            loop
            src={loading}
            style={{ height: "300px", width: "300px" }}
            controls={false}
          ></Player>
      </div>
    </PreGameLayout>
  );
};

export default Loading;
