import recordBreakVideo from "../../../assets/videos/record-break.mp4";
import recordBreak from "../../../assets/img/record-break.png";
import { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import Card from "../../../components/Card";

import PlayIcon from "../../../assets/icons/play-icon.svg";

const RecordBreak = ({ startDemo }) => {
  const [showVideo, setShowVideo] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const videoRef = useRef(null);
  const frameRef = useRef(null);

  useEffect(() => {
    if (showVideo) {
      videoRef?.current?.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showVideo]);

  return (
    <div className="second-main container-fluid d-flex flex-column align-items-center justify-content-between">
      <Header onlyTitle />
      <div className="col-12 col-md-8">
        <Card className="record-box-select-card-holder align-items-center justify-content-center">
          <img
            src={recordBreak}
            alt="Record Break First Frame"
            className="record-break-video first-frame position-absolute w-100 h-100"
            data-is-in={true}
            ref={frameRef}
          />
          {!isVideoPlaying && (
            <div
              className="video-play-button position-absolute rounded-circle"
              role="button"
              onClick={() => {
                videoRef?.current?.play();
              }}
            >
              <img src={PlayIcon} alt="Play Icon" className="w-100" />
            </div>
          )}
          <video
            className="record-break-video position-absolute w-100 h-100"
            onPlay={(e) => {
              e.target.volume = 0.4;
              e.target.muted = false;
              setIsVideoPlaying(true);
            }}
            data-is-in={showVideo}
            autoPlay={false}
            onEnded={() => {
              setShowVideo(false);
              setTimeout(startDemo, 400);
            }}
            onPause={() => {
              setIsVideoPlaying(false);
            }}
            muted
            playsInline
            ref={videoRef}
          >
            <source src={recordBreakVideo} type="video/mp4" />
          </video>
        </Card>
      </div>

      <div></div>
    </div>
  );
};

export default RecordBreak;
