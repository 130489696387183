import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Modal from "../Modal";

import VirtuosoMimo from "../../assets/icons/virtuoso-mimo.svg";
import GamerMimo from "../../assets/icons/gamer-mimo.svg";
import Button from "../Button";
import { useTranslate } from "../../utils/hooks/useTranslate";

const MODAL_TYPES = {
  VirtuosoWelcome: { type: "virtuoso", image: VirtuosoMimo },
  GamerWelcome: { type: "gamer", image: GamerMimo },
  GuestWelcome: { type: "guest", image: GamerMimo },
};

const GameDescriptionModal = ({ type }) => {
  const { app, updateApp } = useContext(AppContext);
  const { translate } = useTranslate();

  const handleClose = () => {
    updateApp({
      modal: null,
    });
  };

  return (
    <Modal
      show={
        app?.modal?.modalType === "GamerWelcome" ||
        app?.modal?.modalType === "VirtuosoWelcome" ||
        app?.modal?.modalType === "GuestWelcome"
      }
      toggleModal={handleClose}
      hideCloseButton
    >
      <div className="d-flex flex-column align-items-center">
        <img
          className="my-4 description-mio"
          src={MODAL_TYPES[app?.modal?.modalType]?.image}
          alt={`${MODAL_TYPES[app?.modal?.modalType]?.type} Mio`}
        />
        <p className="fw-bold h4 text-center my-1">
          {translate(`MODAL_${type}_WELCOME_TITLE`)}
        </p>
        <p
          className="text-center px-4 mb-4 fs-5"
          dangerouslySetInnerHTML={{
            __html: translate(`MODAL_${type}_WELCOME_DESCRIPTION`),
          }}
        />
      </div>
      <div className="d-flex justify-content-center mt-1">
        <Button onClick={handleClose}>
          {translate(`MODAL_${type}_WELCOME_CONTINUE`)}
        </Button>
      </div>
    </Modal>
  );
};

export default GameDescriptionModal;
