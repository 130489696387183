import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Card from "../../components/Card";
import { useTranslate } from "../../utils/hooks/useTranslate";

import virtuosoIcon from "../../assets/icons/virtuoso-mimo.svg";
import gamerIcon from "../../assets/icons/gamer-mimo.svg";
import guestIcon from "../../assets/icons/guest-alert-mimo.svg";
import { useEffect, useState } from "react";
import Virtuoso from "./components/Virtuoso";
import Gamer from "./components/Gamer";
import Guest from "./components/Guest";
import Modal from "../../components/Modal";
import Button from "../../components/Button";

import "intro.js/introjs.css";
import { Steps } from "intro.js-react";
import { useNavigate } from "react-router";

export const TYPES = [
  {
    icon: virtuosoIcon,
    id: "virtuoso",
    title: "TUTORIAL_VIRTUOSO_TITLE",
  },
  {
    icon: gamerIcon,
    id: "gamer",
    title: "TUTORIAL_GAMER_TITLE",
  },
  {
    icon: guestIcon,
    id: "guest",
    title: "TUTORIAL_GUEST_TITLE",
  },
];

const Tutorial = ({ handleContinueGame, defaultRole }) => {
  const [selectedRole, setSelectedRole] = useState(defaultRole || "virtuoso");
  const [selectedRoleLeftOffset, setSelectedRoleLeftOffset] = useState(0); // 0, 33.33, 66.66 [Virtüöz, Müzisyen, İzleyici]
  const { translate } = useTranslate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isTourCompleted, setIsTourCompleted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    handleArrowPosition();
    window.removeEventListener("resize", handleArrowPosition, false);
    window.addEventListener("resize", handleArrowPosition, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);

  useEffect(() => {
    window.addEventListener("resize", handleArrowPosition, false);

    return () => {
      window.removeEventListener("resize", handleArrowPosition, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleArrowPosition = () => {
    const selectedRoleEl = document.getElementById(`role-${selectedRole}`);
    const arrowEl = document.querySelector(".up-arrow");

    if (selectedRoleEl && arrowEl) {
      setSelectedRoleLeftOffset(
        selectedRoleEl.offsetLeft +
        selectedRoleEl.offsetWidth / 2 -
        arrowEl.offsetWidth / 2,
      );
    }
  };

  const mutualTourProps = {
    exitOnOverlayClick: false,
    exitOnEsc: false,
    overlayOpacity: 0.9,
    scrollToElement: true,
    tooltipClass: "tutorial-tooltip",
    nextLabel: translate("TUTORIAL_NEXT_BUTTON") || "",
    prevLabel: translate("TUTORIAL_PREV_BUTTON") || "",
    doneLabel: translate("TUTORIAL_DONE_BUTTON") || "",
    scrollPadding: 100,
  };

  return (
    <div className="game-layout tutorial-page position-absolute">
      <Modal
        show={isModalOpen}
        toggleModal={() => setIsModalOpen(false)}
        className="tutorial-modal"
      >
        <div className="d-flex flex-column align-items-center">
          <h2>{translate("TUTORIAL_MODAL_TITLE")}</h2>
          <p className="fs-5 fw-light text-center mb-4">
            {translate("TUTORIAL_MODAL_CONTENT")}
          </p>
          <Button onClick={() => setIsModalOpen(false)}>{translate("CONTINUE")}</Button>
        </div>
      </Modal>
      <div
        className="Tutorial second-main container-fluid d-flex flex-column align-items-center justify-content-between">
        <Header
          onlyTitle
          title={
            translate("TUTORIAL_TITLE") ||
            translate(
              `${TYPES?.find((type) => type?.id === selectedRole)?.title}`,
            )
          }
        />
        <div className="col-10 col-lg-8">
          <Card className="align-items-center justify-content-start pt-3 pb-4 px-3">
            <>
              <div className="row icons-area flex-shrink-0 w-100 position-relative">
                {TYPES.map((type) => (
                  <div
                    className={`icon justify-content-center align-items-center position-relative tutorial-type-icon ` +
                      `${defaultRole && defaultRole !== type.id ? `d-none` : `d-flex`} ${defaultRole === type.id ? `col-12` : `col-4`}`}
                    key={type.id}
                    role="button"
                    onClick={() => {
                      setSelectedRole(type.id);
                    }}
                    data-is-selected={selectedRole === type.id}
                    id={`role-${type.id}`}
                  >
                    <img src={type.icon} alt={type.title} className="h-100"/>
                    <div className="role-name position-absolute text-uppercase fw-bold">
                      {translate(type?.title)}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="tutorial-area-holder w-100 h-100 position-relative d-flex"
                data-selected-role={selectedRole}
                data-selected-role-offset={selectedRoleLeftOffset}
              >
                <div
                  className="up-arrow position-absolute"
                  style={{
                    transform: `translateX(${selectedRoleLeftOffset}px)`,
                  }}
                ></div>

                <div className="tutorial-pages w-100 overflow-hidden">
                  <div
                    className="pages d-flex justify-content-start h-100"
                    data-selected-index={TYPES?.findIndex(
                      (type) => type?.id === selectedRole,
                    )}
                  >
                    <Virtuoso
                      translate={translate}
                      selectedRole={selectedRole}
                      canStart={isModalOpen === false}
                      roleId={"virtuoso"}
                      tourOptions={mutualTourProps}
                      onComplete={() => {
                        defaultRole ? handleContinueGame() : setSelectedRole("gamer");
                      }}
                    />
                    <Gamer
                      translate={translate}
                      selectedRole={selectedRole}
                      canStart={isModalOpen === false}
                      roleId={"gamer"}
                      tourOptions={mutualTourProps}
                      onComplete={() => {
                        defaultRole ? handleContinueGame() : setSelectedRole("guest");
                      }}
                    />
                    <Guest
                      translate={translate}
                      selectedRole={selectedRole}
                      canStart={isModalOpen === false}
                      roleId={"guest"}
                      tourOptions={mutualTourProps}
                      onComplete={() => {
                        defaultRole ? handleContinueGame() : setIsTourCompleted(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          </Card>
        </div>

        <Steps
          enabled={isTourCompleted}
          initialStep={0}
          steps={[
            {
              element: document.querySelector(".faq"),
              intro: translate("TUTORIAL_FINAL_STEP"),
            },
          ]}
          onExit={() => {}}
          options={mutualTourProps}
          onComplete={() => {
            if (handleContinueGame) handleContinueGame();
            else navigate("/");
          }}
        />

        <Footer
          contentType={`none`}
          hideMicrophone={true}
          showTimer={false}
          hideMio
        />
      </div>
    </div>
  );
};

export default Tutorial;
