import { AgoraClient } from "../../components/AgoraComponent";
import { store } from "../../store";
import { setAudioFileTracks } from "../redux/Agora/agoraSlice";

const stopMultipleAudioFiles = async (audioFileTracks) => {
  if (!audioFileTracks) return;

  const dispatch = store.dispatch;
  if (!dispatch) return;

  for (const audioFileTrack of audioFileTracks) {
    if (!audioFileTrack) continue;

    audioFileTrack.stop();
    audioFileTrack.stopProcessAudioBuffer();
    await AgoraClient.unpublish(audioFileTrack);
  }

  dispatch(setAudioFileTracks([]));
}

export default stopMultipleAudioFiles