import { createContext, useState } from "react";

const defaultValue = {
  isShowing: false,
  modalContent: null,
  modalTitle: null,
  hideCloseButton: false,
  className: "",
};

const ModalContext = createContext(defaultValue);

const ModalContextProvider = ({ children }) => {
  const [isShowing, setIsShowing] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [hideCloseButton, setHideCloseButton] = useState(false);
  const [className, setClassName] = useState("");

  const showModal = ({
    content = "",
    title = "",
    hideClose = false,
    className = "",
  }) => {
    setModalContent(content);
    setModalTitle(title);
    setHideCloseButton(hideClose);
    setClassName(className);
    setIsShowing(true);
  };

  const hideModal = () => {
    setIsShowing(false);

    setTimeout(() => {
      setModalContent(null);
      setModalTitle(null);
      setHideCloseButton(false);
      setClassName("");
    }, 300);
  };

  return (
    <ModalContext.Provider
      value={{
        isShowing,
        showModal,
        hideModal,
        modalContent,
        modalTitle,
        hideCloseButton,
        className,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalContextProvider };
