import BeforeStartGame from "../../pages/BeforStartGame/BeforeStartGame";
import EnterGame from "../../pages/EnterGame/EnterGame";
import GameOver from "../../pages/GameOver/GameOver";
import Gamer from "../../pages/Gamer/Gamer";
import GameStarted from "../../pages/GameStarted/GameStarted";
import Guest from "../../pages/Guest/Guest";
import RecordSelect from "../../pages/RecordSelect/RecordSelect";
import RoomBoxSelect from "../../pages/RoomBoxSelect/RoomBoxSelect";
import RoomName from "../../pages/RoomName/RoomName";
import RoomSelect from "../../pages/RoomSelect/RoomSelect";
import RoomStart from "../../pages/RoomStart/RoomStart";
import RoomTrackSelect from "../../pages/RoomTrackSelect/RoomTrackSelect";
import RoomWaitingModerator from "../../pages/RoomWaitingModerator/RoomWaitingModerator";
import RoomWaitingNextTrack from "../../pages/RoomWaitingNextTrack/RoomWaitingNextTrack";
import Tutorial from "../../pages/Tutorial/Tutorial";
import Virtuoso from "../../pages/Virtuoso/Virtuoso";
import Welcome from "../../pages/Welcome/Welcome";

const GAME_PAGES = {
  welcome: Welcome,
  "enter-game": EnterGame,
  "before-start-game": BeforeStartGame,
  "room-select": RoomSelect,
  "room-name": RoomName,
  "room-start": RoomStart,
  "room-waiting-moderator": RoomWaitingModerator,
  "room-waiting-next-track": RoomWaitingNextTrack,
  "room-track-select-info": RoomTrackSelect,
  "room-box-select-info": RoomBoxSelect,
  "room-box-select": RecordSelect,
  "game-gamer": Gamer,
  "game-virtuoso": Virtuoso,
  "game-guest": Guest,
  "game-started": GameStarted,
  "game-over": GameOver,
  tutorial: Tutorial,
};

export const API_GAME_PAGES = {
  CountDown: "welcome",
  SetGamerInfo: "enter-game",
  CheckGamerAudio: "before-start-game",
  RoomList: "room-select",
  RoomName: "room-name",
  RoomStart: "room-start",
  RoomWaitingModeration: "room-waiting-moderator",
  RoomMioTrackSelectingInfo: "room-track-select-info",
  RoomMioBoxSelectInfo: "room-box-select-info",
  RoomBoxSelection: "room-box-select",
  GameGamer: "game-gamer",
  GameVirtuoso: "game-virtuoso",
  GameGuest: "game-guest",
  GameEnd: "game-over",
  GamerDisconnectedWaitingNextTrack: "room-waiting-next-track",
};

export default GAME_PAGES;
