import { createContext, useContext, useEffect, useState } from "react";
import { AppContext } from "./AppContext";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { API_GAME_PAGES } from "../utils/constants/GAME_PAGES";

import toastr from "toastr";
import { useTranslate } from "../utils/hooks/useTranslate";

const defaultValue = {};

const SignalRContext = createContext(defaultValue);

const SignalRContextProvider = ({ children }) => {
  const [connection, setConnection] = useState(defaultValue);

  const { gameState, updateApp, updateGameState, updateRoomState, handleUpdatePlayingTrack } =
    useContext(AppContext);

  const {translate} = useTranslate();

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_SIGNALR_URL, {
        accessTokenFactory: () => localStorage.getItem("access_token"),
      })
      .withAutomaticReconnect()
      .build();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    setConnection(connection);
  }, []);

  let connectionRetry = 0;
  useEffect(() => {
    if (gameState?.connected && connection?.state === "Disconnected") {
      connection.on("ChangeScreen", (data) => {
        const { screenName } = data;

        if (screenName && API_GAME_PAGES[screenName]) {
          // handleUpdateAppState({ modal: null });
          handleUpdateGameState({ page: API_GAME_PAGES[screenName] });
        }

        handleUpdateRoomState({
          listCorrectOrWrong: [],
        })
      });

      connection.on("GamerInfo", (data) => {
        const player = {
          id: data.gamerId,
          name: data.name,
          avatar: data.avatarId,
          box: data.boxIconId,
          isGuest: data.isGuest,
          roomId: data.roomId,
        };

        handleUpdateGameState({ player });
      });

      connection.on("RoomInfo", (data) => {
        handleUpdateRoomState({
          id: data.roomId,
          name: data.name,
          moderator: data.moderator,
          guests: data.roomGuests.map((guest) => ({
            ...guest,
            hasAudio: false,
            isMuted: true,
          })),
          players: data.roomGamers.map((player) => ({
            ...player,
            hasAudio: false,
            isMuted: true,
          })),
          difficulty: data.level,
          startDate: data.gameStartDate,
          totalSeconds: data.totalSeconds,
          fullRecordUrl: data.activeTrack?.fullTrackRecordUrl,
          score: data.point,
          numberOfSkip: data.numberOfSkip,
          round: data.roomTracks?.length || 0
        });
      });

      connection.on("RoomList", (data) => {
        handleUpdateGameState({ rooms: data });
      });

      connection.on("ShowModal", (data) => {
        if (data.modalType === "GameEnd") {
          handleUpdateRoomState({ endData: data.data });
          return;
        }

        if (data) handleUpdateAppState({ modal: data });

        if (data?.modalType === "TrackSuccess") {
          handleUpdateRoomState({ score: data?.data?.gamePoint });
        }

        if (data?.modalType === "TrackPartCheck") {
          handleUpdateRoomState({ listCorrectOrWrong: data?.data?.listCorrectOrWrong });
        }
      });

      connection.on("HideModal", (data) => {
        handleHideModal(data?.modalType);
      });

      connection.on("ErrorMessage", (data) => {
        toastr.error(data);
      });

      connection.on("SuccessMessage", (data) => {
        toastr.success(data);
      });

      connection.on("AgoraConnectInfo", (data) => {
        handleUpdateRoomState({ agora: data });
      });

      connection.on("BoxData", (data) => {
        handleUpdateGameState({ boxes: data });
      });

      connection.on("GamerTrackParts", (data) => {
        handleUpdateRoomState({
          records: data,
          fullRecordUrl: data?.fullTrackUrl,
        });
      });

      connection.on("VirtuosoTrackParts", (data) => {
        handleUpdateRoomState({
          records: data?.trackParts,
        });
      });

      connection.on("GuestTrackParts", (data) => {
        handleUpdateRoomState({
          records: data?.trackParts,
        });
      });

      connection.on("MioMessage", (data) => {
        handleUpdateAppState({ mio: data?.messages });
      });

      connection.on("SelectedParts", (data) => {
        handleUpdateRoomState({
          selectedParts: data,
        });
      });

      connection.on("GamerPlayingTrack", (data) => {
        handleUpdatePlayingTrack(data, "play");
      })

      connection.on("GamerStoppedTrack", (data) => {
        handleUpdatePlayingTrack(data, "stop");
      });

      connection.on(
        "GameEndData",
        ({ roomTracks, meetingLink, spotifyLink }) => {
          handleUpdateGameState({
            gameEndData: {
              tracks: roomTracks?.map(
                ({ name, artistName: artist, artistImageUrl: cover }) => ({
                  name,
                  artist,
                  cover,
                })
              ),
              meetingLink,
              spotifyLink,
            },
          });
        }
      );

      connection.on("RoomListGamerCount", (data) => {
        handleUpdateGameState({ headerPlayerInfo: data });
      });

      connection.onclose(() => {
        if (!gameState?.connected) return;

        toastr.clear();
        toastr.error(translate("BACKEND_CONNECTION_LOST"));

        connectionRetry++;

        const newConnection = connection;

        setTimeout(() => handleConnect(newConnection), 4000 * connectionRetry);
      });

      connection.onreconnecting(() => {
        toastr.clear();
        toastr.info(translate("BACKEND_RECONNECTING"));
      });

      connection.onreconnected(() => {
        toastr.clear();
        toastr.success(translate("BACKEND_RECONNECTED"));
      });
    } else {
      if (connection) {
        if (connection?.onclose) connection.onclose(() => {});
        if (connection?.onreconnecting) connection?.onreconnecting(() => {});
        if (connection?.onreconnected) connection?.onreconnected(() => {});

        // setConnection(connection);

        if (connection?.state === "Disconnected") {
          connection.stop().catch((err) => {
            console.error(err.toString());
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState?.connected]);

  useEffect(() => {
    if (gameState?.connected && connection?.state === "Disconnected") {
      handleConnect(connection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState?.connected]);

  const handleConnect = (connection) => {
    connection.start().catch((err) => {
      console.error(err.toString());
    });
  };

  const handleDisconnect = () => {
    updateGameState({ connected: false });
  };

  const handleHideModal = () => {
    // if (modalType === app?.modal?.modalType)
    handleUpdateAppState({ modal: null });
  };

  const handleUpdateRoomState = (data) => {
    updateRoomState(data);
  };

  const handleUpdateGameState = (data) => {
    updateGameState(data);
  };

  const handleUpdateAppState = (data) => {
    updateApp(data);
  };

  const joinRoom = (roomId, isGuest = false) => {
    connection.send("JoinRoom", roomId, isGuest);
  };

  const leaveRoom = () => {
    connection.send("ExitRoom");
  }

  const startGame = () => {
    connection.send("StartRoomGame");
  };

  const checkTrackOrder = (tracks) => {
    connection.send("CheckPartOrder", tracks);
  };

  const startNewGame = (skipped = false) => {
    connection.send("StartNewGame", skipped);
    handleUpdateRoomState({
      listCorrectOrWrong: [],
    })
  };
  const endGame = () => {
    connection.send("GameEnd");
  };

  const updateOnPlayerNotes = (notes) => {
    connection.send("SetSelectedParts", notes);
  };

  const startPlaying = () => {
    connection.send("SetGamerPlayingTrack")
  }

  const stopPlaying = () => {
    connection.send("SetGamerStoppedTrack")
  }

  return (
    <SignalRContext.Provider
      value={{
        connection,
        joinRoom,
        leaveRoom,
        startGame,
        checkTrackOrder,
        startNewGame,
        endGame,
        closeConnection: handleDisconnect,
        updateOnPlayerNotes,
        startPlaying,
        stopPlaying
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export { SignalRContext, SignalRContextProvider };
