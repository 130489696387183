import { default as CountdownComponent } from "react-countdown";
import { useTranslate } from "../utils/hooks/useTranslate";

const renderer = ({ days, hours, minutes, seconds }, translate) => {
  const totalHours = days * 24 + hours;
  return totalHours >= 24 ? (
    <h6
      className="fs-4 text-center"
      dangerouslySetInnerHTML={{
        __html: translate(`MORE_THAN_24_HOURS_TO_START`),
      }}
    />
  ) : totalHours >= 1 ? (
    <h6
      className="fs-4 text-center"
      dangerouslySetInnerHTML={{
        __html: translate(`HOURS_LEFT_TO_START`).replace(
          "$totalHours",
          totalHours
        ),
      }}
    />
  ) : (
    <div className="d-flex align-items-center justify-content-center fs-1 fw-bold">
      <div className="countdown-number musing-border">
        {String(minutes).length > 1 ? String(minutes)[0] : 0}
      </div>
      <div className="countdown-number musing-border mx-4">
        {String(minutes).length > 1 ? String(minutes)[1] : minutes}
      </div>
      <span className="text-white">:</span>
      <div className="countdown-number musing-border mx-4">
        {String(seconds).length > 1 ? String(seconds)[0] : 0}
      </div>
      <div className="countdown-number musing-border">
        {String(seconds).length > 1 ? String(seconds)[1] : seconds}
      </div>
    </div>
  );
};

const Countdown = ({ startTime, onComplete, autoStart = true }) => {
  const { translate } = useTranslate();

  return (
    <CountdownComponent
      date={startTime}
      renderer={(props) => renderer(props, translate)}
      daysInHours={true}
      zeroPadTime={1}
      onComplete={onComplete}
      autoStart={autoStart}
      overtime={false}
      translate={translate}
    />
  );
};

export default Countdown;
