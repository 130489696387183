import DefaultAvatar from "../../assets/avatar/default-avatar.svg";

const Avatar = require.context("../../assets/avatar", false, /\.svg$/);

let AVATARS = {
  default: DefaultAvatar,
};

Avatar.keys()
  .sort((a, b) => a - b)
  .filter((avatar) => avatar !== "./default-avatar.svg")
  .forEach((avatar, index) => {
    AVATARS[index + 1] = Avatar(avatar);
  });

export default AVATARS;
