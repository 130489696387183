import Modal from "./Modal";
import Button from "./Button";
import SadMio from "../assets/icons/sad-mimo.svg";
import { useTranslate } from "../utils/hooks/useTranslate";
import { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";
import { SignalRContext } from "../context/SignalRContext";
import Countdown from "./Countdown";
import { useEffect } from "react";

const GamePausedModal = ({ show, gamerType }) => {
  const { translate } = useTranslate();
  const [countdown, setCountdown] = useState(null);
  const [pausedType, setPausedType] = useState("PAUSED");

  const { app } = useContext(AppContext);
  const { startNewGame } = useContext(SignalRContext);

  useEffect(() => {
    if (show && app?.modal?.data?.canStartNewGame)
      setCountdown(new Date().getTime() + 120000);
    else setCountdown(null);
  }, [app?.modal?.data?.canStartNewGame, show]);

  useEffect(() => {
    setPausedType(
      app?.modal?.data?.canBeContinued ? "DISCONNECT" : "CANT_CONTINUE"
    );
  }, [app?.modal?.data?.canBeContinued]);

  const onContinue = () => {
    startNewGame(false);
  };

  return (
    <Modal show={show} hideCloseButton={true}>
      <div className="d-flex align-items-center mt-3">
        <img className="mb-4" src={SadMio} width={134} alt="Sad Mio" />
        <p
          className="speech-bubble h3 ms-4 p-3 border-gradient border-gradient-purple"
          dangerouslySetInnerHTML={{
            __html: translate(`MODAL_${gamerType}_${pausedType}_MIO`),
          }}
        />
      </div>
      <div className="d-flex flex-column my-1">
        <p
          className="fw-bold h5 text-center px-4"
          dangerouslySetInnerHTML={{
            __html: translate(`MODAL_${gamerType}_${pausedType}_TITLE`),
          }}
        />
        <p
          className="text-center mt-2"
          dangerouslySetInnerHTML={{
            __html: translate(`MODAL_${gamerType}_${pausedType}_DESCRIPTION`),
          }}
        />
      </div>
      {app?.modal?.data?.canStartNewGame && countdown !== null && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="countdown-holder paused-fix d-flex align-items-center fs-1 fw-bold mb-4">
            <Countdown
              startTime={countdown}
              onComplete={onContinue}
              autoStart={true}
            />
          </div>
        </div>
      )}
      {app?.modal?.data?.buttons && (
        <div className="d-flex justify-content-center">
          {app?.modal?.data?.canStartNewGame && (
            <Button className="mt-3" onClick={onContinue}>
              {translate(`MODAL_${gamerType}_DISCONNECT_CONTINUE`)}
            </Button>
          )}
        </div>
      )}
    </Modal>
  );
};

export default GamePausedModal;
