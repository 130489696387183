import NOTES from "../../../utils/constants/NOTES";
import PauseIcon from "../../../assets/icons/pause-icon.svg";

const Note = ({ note, isPlaying, handlePlay, customId }) => {
  return (
    <div
      className="note-holder mh-100 position-relative d-flex justify-content-center align-items-center"
      onClick={handlePlay}
      role="button"
      id={customId || ""}
    >
      <div className="note-border note-border--1 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--2 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--3 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--4 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--5 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--6 w-100 h-100 position-absolute"></div>
      <div className="note-border note-border--7 w-100 h-100 position-absolute"></div>

      <img
        src={NOTES[note?.iconId] || NOTES[0]}
        alt={`Note ${note?.partId}`}
        className="note position-absolute"
      />

      {isPlaying && (
        <img
          className="note-playing-icon position-absolute"
          src={PauseIcon}
          alt="Playing Indicator"
        />
      )}
    </div>
  );
};

export default Note;
