import note1 from "../../assets/notes/note-1.svg";
import note2 from "../../assets/notes/note-2.svg";
import note3 from "../../assets/notes/note-3.svg";
import note4 from "../../assets/notes/note-4.svg";
import note5 from "../../assets/notes/note-5.svg";
import note6 from "../../assets/notes/note-6.svg";
import note7 from "../../assets/notes/note-7.svg";
import note8 from "../../assets/notes/note-8.svg";
import note9 from "../../assets/notes/note-9.svg";
import note10 from "../../assets/notes/note-10.svg";

const NOTES = {
  1: note1,
  2: note2,
  3: note3,
  4: note4,
  5: note5,
  6: note6,
  7: note7,
  8: note8,
  9: note9,
  10: note10,
};

export default NOTES;
