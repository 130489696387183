import FAQ from "./FAQ";
import MuteButton from "./MuteButton";
import SpotifyIcon from "../assets/icons/spotify.svg";
import MessageSquareIcon from "../assets/icons/message-square.svg";
import ZoomIcon from "../assets/icons/zoom.svg";
import Button from "./Button";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import UserInfo from "./UserInfo";
import AssistantMio from "./AssistantMio";
import { default as CountdownComponent } from "react-countdown";
import { useTranslate } from "../utils/hooks/useTranslate";

import playAgain from "../assets/icons/play-again.svg";
import getLinkWithProtocol from "../utils/helpers/getLinkWithProtocol";
import dayjs from "dayjs";
import { SignalRContext } from "../context/SignalRContext";
import Mio from "../assets/icons/assistant-mio.svg";
import Modal from "./Modal";
import { useSelector } from "react-redux";

const CANLEAVEROOM = ["room-name", "room-waiting-moderator", "room-start"];

const Footer = ({
  contentType,
  buttonText,
  showTimer,
  hideMicrophone,
  onClick,
  disableSingleButton,
  customCountdown = null,
  demoFooter = false,
  onCountdownComplete = null,
  demoUserInfo = null,
  hideMio = false,
}) => {
  const { leaveRoom } = useContext(SignalRContext);
  const [countdown, setCountdown] = useState(0);
  const { roomState, gameState, updateRoomState } = useContext(AppContext);
  const [demoUser, setDemoUser] = useState({
    gamerId: "demo-user",
    isConnected: false,
  });

  const [leaveRoomModal, setLeaveRoomModal] = useState(false);
  const [leavingRoom, setLeavingRoom] = useState(false);

  const { translate } = useTranslate();

  const { connectionState } = useSelector((state) => state.agora);

  useEffect(() => {
    if ((customCountdown || roomState?.totalSeconds) && !countdown) {
      setCountdown(
        customCountdown ??
        dayjs().add(roomState.totalSeconds, "seconds").unix() * 1000,
      );
    }
  }, [roomState.totalSeconds, customCountdown, countdown]);

  useEffect(() => {
    if (demoFooter) {
      const demoUser = demoUserInfo;
      if (demoUser) {
        demoUser.gamerId = "demo-user";
        demoUser.isConnected = true;
        demoUser.avatarId = demoUser.avatar;
      }

      setDemoUser(demoUser);
    }
  }, [demoFooter, demoUserInfo]);

  let timeout;
  const handleResize = () => {
    clearTimeout(timeout);
    timeout = setTimeout(
      () => {
        const footerEl = document.querySelector(".game-layout > .footer");
        if (footerEl)
          document
            .querySelector(":root")
            ?.style.setProperty(
            "--footer-height",
            `${footerEl?.offsetHeight}px`,
          );
      },
      200,
    );
  };

  useEffect(() => {
    setTimeout(handleResize, 1000);

    window.addEventListener("resize", handleResize, false);

    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line
  }, [connectionState, roomState?.players, gameState.page, gameState?.player?.role, roomState?.records, showTimer]);

  const handleLeaveRoom = () => {
    setLeavingRoom(true);
    updateRoomState({ agora: null });
    leaveRoom();
  };

  return (
    <div
      className="footer d-flex align-items-end justify-content-center justify-content-md-between position-relative"
      data-is-demo={demoFooter}
    >
      <div className="col-0 col-md-1">
        {contentType !== "demoEnded" && (
          <div
            className="footer-action-buttons d-flex justify-content-start align-items-center ms-md-1 ms-lg-4 mb-md-4">
            <FAQ/>
            {!hideMio && <AssistantMio/>}
          </div>
        )}
      </div>
      <div className="col-12 col-md-10 col-lg-9">
        <div className="content d-flex flex-column align-items-center">
          <div
            className={`countdown-content text-white justify-content-center align-items-center ${
              showTimer ? `d-flex` : `d-none`
            }`}
          >
            <CountdownComponent
              date={countdown}
              key={countdown}
              renderer={(props) => (
                <>
                  <span className="label">{translate("ROOM_COUNT_DOWN")} </span>
                  <span className="time-left">
                    {props.minutes < 10 ? "0" + props.minutes : props.minutes}:
                    {props.seconds < 10 ? "0" + props.seconds : props.seconds}
                  </span>
                </>
              )}
              daysInHours={true}
              zeroPadTime={1}
              onComplete={() => {
                onCountdownComplete && onCountdownComplete();
              }}
            />
          </div>

          <div
            className="users-content d-flex flex-wrap align-items-center px-5 px-md-2 px-lg-5 py-3 py-md-2 justify-content-around"
            data-is-demo={demoFooter}
          >
            {contentType === "singleButton" ? (
              <Button
                className="px-5"
                onClick={(e) => {
                  onClick(e, "singleButton");
                }}
                disabled={disableSingleButton}
              >
                {buttonText}
              </Button>
            ) : contentType === "default" ? (
              <div className="users-holder d-grid w-100 justify-content-center">
                {!demoFooter &&
                  roomState?.players.map((user) => (
                    <UserInfo key={user?.gamerId} user={user}/>
                  ))}
                {demoFooter && <UserInfo user={demoUser}/>}
              </div>
            ) : contentType === "gameEnded" ? (
              <>
                {gameState?.gameEndData?.meetingLink && (
                  <Button
                    className="game-end-button"
                    onClick={(e) => {
                      onClick(e, "zoom");
                    }}
                  >
                    <img
                      className="button-icon"
                      src={ZoomIcon}
                      alt="Zoom Icon"
                    />
                    <span className="ms-2">
                      {translate("GAME_END_MEET_LINK")}
                    </span>
                  </Button>
                )}
                <Button
                  className="game-end-button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      getLinkWithProtocol(translate("GAME_END_SPOTIFY_LINK")),
                    );
                  }}
                >
                  <img
                    className="button-icon"
                    src={SpotifyIcon}
                    alt="spotify"
                  />
                  <span>{translate("GAME_END_SPOTIFY_TEXT")}</span>
                </Button>
                <Button
                  className="game-end-button"
                  onClick={(e) => {
                    onClick(e, "feedback");
                  }}
                >
                  <img
                    className="button-icon"
                    src={MessageSquareIcon}
                    alt="message"
                  />
                  <span>{translate("GAME_END_SHARE_OPINION")}</span>
                </Button>
              </>
            ) : contentType === "demoEnded" ? (
              <>
                <Button
                  className="game-end-button"
                  onClick={(e) => {
                    onClick(e, "playAgain");
                  }}
                >
                  <img
                    className="button-icon"
                    src={playAgain}
                    alt="Play Again Icon"
                  />
                  <span className="ms-2 button-text">
                    {translate("DEMO_END_PLAY_AGAIN")}
                  </span>
                </Button>
                <Button
                  className="game-end-button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      getLinkWithProtocol(translate("GAME_END_SPOTIFY_LINK")),
                    );
                  }}
                >
                  <img
                    className="button-icon"
                    src={SpotifyIcon}
                    alt="spotify"
                  />
                  <span className="button-text">
                    {translate("GAME_END_SPOTIFY_TEXT")}
                  </span>
                </Button>
                <Button
                  className="game-end-button"
                  onClick={(e) => {
                    onClick(e, "contactUs");
                  }}
                >
                  <img
                    className="button-icon"
                    src={MessageSquareIcon}
                    alt="Contact Us"
                  />
                  <span className="button-text">
                    {translate("DEMO_END_CONTACT_US")}
                  </span>
                </Button>
              </>
            ) : (
              ``
            )}
          </div>
        </div>
      </div>
      <div className="col-0 col-md-1 position-relative">
        {gameState.player.roomId && (CANLEAVEROOM.includes(gameState?.page) || gameState.player.isGuest) && (
          <Button
            className="text-nowrap position-absolute top-0 leave-room-button"
            style={{
              transform:
                "translate(calc(-50% - 2.5rem), calc(-100% - 40px))",
              left: "50%",
            }}
            onClick={() => setLeaveRoomModal(true)}
          >
            {!gameState.player.isGuest && <div className="tooltip position-absolute top-0">
              {translate("LEAVE_ROOM_TOOLTIP")}
            </div>}
            {translate("LEAVE_ROOM")}
          </Button>
        )}
        <div className="me-1 me-lg-4 mb-4">
          <MuteButton
            hide={
              contentType === "demoEnded" ||
              hideMicrophone ||
              connectionState !== "CONNECTED" ||
              demoFooter
            }
          />
        </div>
      </div>

      <Modal
        show={leaveRoomModal}
        hideCloseButton={false}
        toggleModal={() => setLeaveRoomModal(false)}
      >
        <div className="d-flex align-items-center mt-3">
          <img className="mb-4" src={Mio} width={134} alt="Mio"/>
          <div>
            <h4 className="speech-bubble ms-4 mb-3 ps-3 border-gradient border-gradient-purple">
              {translate(gameState.player.isGuest ? `GUEST_MODAL_LEAVE_ROOM_TITLE` : `MODAL_LEAVE_ROOM_TITLE`)}
            </h4>
            <p
              className="speech-bubble ms-4 ps-3 border-gradient border-gradient-purple"
              dangerouslySetInnerHTML={{
                __html: translate(gameState.player.isGuest ? `GUEST_MODAL_LEAVE_ROOM_TEXT` : `MODAL_LEAVE_ROOM_TEXT`),
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="mt-3"
            onClick={handleLeaveRoom}
            disabled={leavingRoom}
          >
            {translate(`LEAVE_ROOM`)}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

Footer.defaultProps = {
  contentType: "default",
  buttonText: "Başla",
  showTimer: true,
  hideMicrophone: false,
  disableSingleButton: false,
  onClick: () => {},
};

export default Footer;
