import { useContext } from "react";
import Card from "../../components/Card";
import Header from "../../components/Header";
import { AppContext } from "../../context/AppContext";
import GameLayout from "../../layouts/GameLayout";
import Footer from "../../components/Footer";

const RoomWaitingModerator = ({ translate }) => {
  const { roomState } = useContext(AppContext);
  return (
    <GameLayout>
      <div className="second-main container-fluid d-flex flex-column align-items-center justify-content-between">
        <Header onlyTitle title={roomState?.name || "Musing"} />
        <div className="col-10 col-lg-8">
          <Card className="d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex flex-column align-items-center pt-5 px-4">
              <h1
                className="mb-2"
                dangerouslySetInnerHTML={{
                  __html: translate("ROOM_WAITING_TITLE"),
                }}
              />
              <p
                className="fs-5 py-3 text-center px-5"
                dangerouslySetInnerHTML={{
                  __html: translate("ROOM_WAITING_DESCRIPTION"),
                }}
              />
            </div>
          </Card>
        </div>

        <Footer
          contentType="default"
          showTimer={false}
          onClick={() => {
            return;
          }}
        />
      </div>
    </GameLayout>
  );
};

export default RoomWaitingModerator;
