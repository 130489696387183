import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { useSelector } from "react-redux";

const RemoteUsersList = () => {
  const { roomState, gameState } = useContext(AppContext);

  const [player, setPlayer] = useState(null); // gameState?.player
  const [users, setUsers] = useState([]);

  const { isMuted } = useSelector((state) => state.agora);

  useEffect(() => {
    const remoteUsers = roomState?.remoteUsers?.filter((user) => user?.uid);

    if (remoteUsers?.length) {
      setUsers(
        remoteUsers?.map((user) => {
          const player = roomState?.players?.find(
            (player) => String(player?.gamerId) === String(user?.uid)
          );

          return {
            uid: user?.uid,
            name: player?.name,
            isMuted: user?.muted,
          };
        })
      );
    }
  }, [roomState]);

  useEffect(() => {
    if (gameState?.player) {
      setPlayer({
        uid: gameState?.player?.id,
        name: gameState?.player?.name,
        isMuted,
      });
    }
  }, [gameState?.player, isMuted]);

  return users?.length ? (
    <div className="GuestList position-absolute overflow-hidden">
      <h3 className="list-title text-center text-uppercase text-white mb-0">
        Remote Users
      </h3>
      <div className="list position-relative">
        <div className="list-item d-flex justify-content-start align-items-center">
          <div className="name text-info">
            {player?.uid} ({player?.name}) ({isMuted ? `Muted` : `Unmuted`})
          </div>
        </div>
        {users.map((user) => (
          <div
            key={user?.uid}
            className="list-item d-flex justify-content-start align-items-center"
          >
            <div className="name text-white">
              {user?.uid} ({user?.name}) ({user?.isMuted ? `Muted` : `Unmuted`})
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default RemoteUsersList;
