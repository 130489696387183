const Button = ({
  children,
  className,
  hasBorder = true,
  widthFull = false,
  disabled = false,
  ...props
}) => {
  return (
    <button
      className={`btn musing-button
        ${widthFull ? "btn-width-full" : ""}
       ${className ? className : ""}
        ${hasBorder ? "button-border" : ""}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
