import { createElement, createRef, useContext, useEffect, useState } from "react";
import GameScreen from "./pages/GameScreen";
import DemoVideo from "../../assets/videos/demo5.mp4";
import { useNavigate, useParams } from "react-router";
import Welcome from "./pages/Welcome";
import PickingASong from "./pages/PickingASong";
import { useTranslate } from "../../utils/hooks/useTranslate";
import RecordBreak from "./pages/RecordBreak";
import DemoOver from "./pages/DemoOver";
import SoundCheck from "./pages/SoundCheck";
import ResolutionInfo from "../../components/ResolutionInfo";
import { AppContext } from "../../context/AppContext";

export const DEMO_TIME_LIMIT = 5 * 60; // 5 minutes

const PAGES = {
  soundCheck: SoundCheck,
  welcome: Welcome,
  pickingASong: PickingASong,
  recordBreak: RecordBreak,
  demoOver: DemoOver,
};

const Demo = () => {
  const [page, setPage] = useState("soundCheck");
  const [demoReady, setDemoReady] = useState(false);
  const [demoStart, setDemoStart] = useState(false);
  const [demoState, setDemoState] = useState(null);
  const [demoUser, setDemoUser] = useState({
    name: "",
    avatar: "",
  });

  const videoRef = createRef(null);
  const { started } = useParams();
  const navigate = useNavigate();
  const { app } = useContext(AppContext);

  const { translate } = useTranslate();
  const [demoURL, setDemoURL] = useState("");

  useEffect(() => {
    setDemoURL(app.language === "en" ? "/en/demo" : "/demo");
  }, [app.language]);

  useEffect(() => {
    if (started) {
      navigate(demoURL);
      restartDemo();
      setDemoReady(true);
    } else {
      setDemoReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.language, demoURL]);

  useEffect(() => {
    if (demoReady) {
      if (demoState) {
        if (demoState.demoStart) setDemoStart(true);

        if (demoState.page) {
          setPage(demoState.page);
        }
      } else {
        const defaultState = { demoStart: false, page: Object.keys(PAGES)[0] };
        updateDemoState(defaultState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoState]);

  useEffect(() => {
    if (demoReady) {
      if (started) {
        setDemoStart(true);
        updateDemoState({ demoStart: true });
      } else {
        setDemoStart(false);
        updateDemoState({ demoStart: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [started]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.src === "" && !started) {
      videoRef.current.src = DemoVideo;
      videoRef.current.play();
      videoRef.current.muted = false;
    }
  }, [videoRef, started]);

  const startDemo = () => {
    if (demoReady) {
      navigate(demoURL + "/started");
    }
  };

  const nextPage = () => {
    const currentPage = Object.keys(PAGES).indexOf(page);
    const nextPage = Object.keys(PAGES)[currentPage + 1];

    setPage(nextPage);

    updateDemoState({ page: nextPage });
  };

  const updateDemoState = (state) => {
    const newState = { ...demoState, ...state };

    setDemoState(newState);
  };

  const updateDemoUser = (user) => {
    const demoUser = user;

    setDemoUser(demoUser);
  };

  const endDemo = () => {
    nextPage();

    navigate(demoURL);
  };

  const restartDemo = () => {
    setDemoState(null);
    setDemoUser(null);
    setDemoStart(false);

    setPage(Object.keys(PAGES)[0]);
  };

  return (
    <>
      {process.env.REACT_APP_DEV_MODE === "true" && (
        <select
          onChange={(e) => setPage(e.target.value)}
          className="position-fixed"
          style={{ top: 0, left: 0, zIndex: 99999 }}
          value={page}
        >
          {Object.keys(PAGES).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
      )}

      <ResolutionInfo/>
      {!demoStart &&
        PAGES[page] &&
        createElement(PAGES[page], {
          nextPage,
          startDemo,
          updateDemoUser,
          updateDemoState,
          translate,
          demoUser,
          demoState,
          restart: restartDemo,
        })}
      {demoStart && (
        <GameScreen
          endDemo={endDemo}
          updateDemoState={updateDemoState}
          demoState={demoState}
          demoUser={demoUser}
        />
      )}
    </>
  );
};

export default Demo;
