const Modal = ({
  title,
  show,
  toggleModal,
  hideCloseButton = false,
  children,
  className = "",
}) => {
  return (
    <div className={`modal overflow-visible ${show ? "show" : ""} ${className}`}>
      <div className="modal-content overflow-auto">
        {(title || !hideCloseButton) && (
          <div className="modal-header">
            <p className="h4 fw-bold mb-0">{title}</p>
            {!hideCloseButton && (
              <div className="modal-close ms-auto" onClick={toggleModal}>
                &times;
              </div>
            )}
          </div>
        )}
        <div className="mb-4 mh-100 overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
