const CircleButton = ({ onClick, children, restProps }) => {
  return (
    <button
      className="btn circle-button d-flex align-items-center justify-content-center text-white"
      onClick={onClick}
      {...restProps}
    >
      {children}
    </button>
  );
};
export default CircleButton;
