import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { useTranslate } from "../../../utils/hooks/useTranslate";

const GameEndModal = ({
  show,
  gamePoint,
  artistName,
  trackName,
  cover,
  endDemo,
  demoUser,
}) => {
  const { translate } = useTranslate();

  return (
    <Modal show={show} hideCloseButton className="game-end-modal">
      <div className="d-flex flex-column align-items-center">
        <p className="fw-bold h4 text-center my-3">
          {translate("MODAL_GAME_END_TITLE").replace(
            "$teamName",
            demoUser?.name || ""
          )}
        </p>
        <p className="text-center px-4 mb-4">
          {translate("MODAL_GAME_END_DESCRIPTION")}
        </p>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="mini-box w-100 text-center mx-4 py-2">
          <p className="fs-6 mb-0">{translate("MODAL_GAME_END_POINT")}</p>
          <p className="fw-bold fs-3 mb-0">{gamePoint}</p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-start mt-4">
        <img
          className="ms-4 rounded-circle overflow-hidden"
          width={100}
          src={cover}
          alt={`${artistName} - ${trackName} Cover`}
        />
        <p className="mt-4 ms-3">
          <span className="fw-bold">{artistName}</span>
          <br />
          {trackName}
        </p>
      </div>
      {
        <div className="d-flex justify-content-center mt-4">
          <Button onClick={endDemo}>
            {translate("MODAL_GAME_END_CONTINUE")}
          </Button>
        </div>
      }
    </Modal>
  );
};

export default GameEndModal;
