import NOTES from "../../../utils/constants/NOTES";
import PlayIcon from "../../../assets/icons/play-icon.svg";
import PauseIcon from "../../../assets/icons/pause-icon.svg";
import { useTranslate } from "../../../utils/hooks/useTranslate";

const Note = ({
  note,
  isAdded,
  onClick,
  draggable,
  onDragStart,
  onPlayClick,
  isPlaying,
  isPlayingDisabled,
}) => {
  const { translate } = useTranslate();

  return (
    <div
      className="note-holder demo-notes position-relative d-flex justify-content-center align-items-center"
      role={isAdded ? "none" : "button"}
      data-is-added={isAdded}
      draggable={draggable}
      onDragStart={onDragStart}
    >
      <div
        className="play-note-button position-absolute"
        role="button"
        onClick={(e) => {
          e.preventDefault();
          onPlayClick(note);
        }}
        data-disabled={isPlayingDisabled}
      >
        {!isPlaying && (
          <img className="play-icon" src={PlayIcon} alt="Musing Logo" />
        )}
        {isPlaying && (
          <img
            className="note-playing-icon position-absolute"
            src={PauseIcon}
            alt="Playing Indicator"
          />
        )}

        {translate("DEMO_TRACK_PART_PLAY_TOOLTIP") && <div className="button-tooltip position-absolute d-none d-md-flex">
          {translate("DEMO_TRACK_PART_PLAY_TOOLTIP")}
        </div>}
      </div>
      <div
        className="note-button position-absolute w-100 h-100"
        onClick={onClick}
      >
        <div className="note-border note-border--1 w-100 h-100 position-absolute"></div>
        <div className="note-border note-border--2 w-100 h-100 position-absolute"></div>
        <div className="note-border note-border--3 w-100 h-100 position-absolute"></div>
        <div className="note-border note-border--4 w-100 h-100 position-absolute"></div>
        <div className="note-border note-border--5 w-100 h-100 position-absolute"></div>
        <div className="note-border note-border--6 w-100 h-100 position-absolute"></div>
        <div className="note-border note-border--7 w-100 h-100 position-absolute"></div>

        <img
          src={NOTES[note?.iconId] || NOTES[0]}
          alt={`Note ${note?.partId}`}
          className="note position-absolute"
        />
      </div>

      {process.env.REACT_APP_DEV_MODE === "true" ? (
        <span className="text-white position-absolute" style={{ bottom: 0 }}>
          {note?.originalOrder}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Note;
