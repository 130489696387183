import { useContext, useEffect, useRef, useState } from "react";
import Card from "../../../components/Card";

import Logo from "../../../assets/img/musing-logo.svg";
import DefaultCover from "../../../assets/img/default-cover.jpg";
import JboxOutro from "../../../assets/videos/jbox-outro.mp4";

import HappyMio from "../../../assets/icons/robot.svg";
import SadMio from "../../../assets/icons/sad-mimo.svg";
import Footer from "../../../components/Footer";
import FullScreenVideo from "../../../components/FullScreenVideo";
import { AppContext } from "../../../context/AppContext";

import introVideoCover from "../../../assets/img/intro-video.png";
import endVideoCover from "../../../assets/img/end-video.png";

const DemoOver = ({ translate, demoUser, demoState, restart }) => {
  const [playVideo, setPlayVideo] = useState(true);

  const { updateApp } = useContext(AppContext);

  const introVideoCoverRef = useRef(null);
  const endVideoCoverRef = useRef(null);

  useEffect(() => {
    updateApp({ mio: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (playVideo)
      setTimeout(() => {
        endVideoCoverRef.current.classList.add("over");
      }, 1000);
  }, [playVideo]);

  const handleAction = (e, type) => {
    e.preventDefault();

    switch (type) {
      case "playAgain":
        restart();
        break;
      case "contactUs":
        window.open("https://www.digi-stage.com/iletisim/", "_blank");
        break;
      default:
        break;
    }
  };

  return (
    <div className="pre-game-layout demo game-over-page container-fluid main d-flex flex-column align-items-center justify-content-between justify-content-md-between">
      <div className="row d-flex align-items-center justify-content-center mt-5">
        <div className="col-12 col-md-8 mt-4 pt-2">
          {!playVideo && (
            <Card className="d-flex flex-column align-items-center justify-content-center">
              <img className="logo pt-2" src={Logo} alt="Musing Logo" />
              <div className="d-flex flex-column align-items-center text-center text-md-start">
                {demoUser?.name && (
                  <h5 className="demo-over-text mb-0">
                    {translate("DEMO_END_TITLE").replace(
                      "$roomName",
                      demoUser.name
                    )}
                  </h5>
                )}
                <p
                  className="fs-5 text-center px-5 col-12 col-md-10 text-center text-md-start"
                  dangerouslySetInnerHTML={{
                    __html: translate("DEMO_END_DESCRIPTION"),
                  }}
                />
                <p
                  className="fs-5 text-center px-5 col-10 mb-1"
                  dangerouslySetInnerHTML={{
                    __html: translate("DEMO_END_GAME_POINT"),
                  }}
                />
                <p className="fw-bold fs-3 mb-0">
                  {!isNaN(demoState?.totalPoints) ? demoState?.totalPoints : 0}
                </p>
              </div>
              <div className="songs overflow-auto col-12 col-md-8 mt-2">
                {demoState?.tracks?.length > 1
                  ? demoState?.tracks
                      ?.slice(0, demoState.currentLevel + 1)
                      ?.map((track, index) => (
                        <div
                          className="d-flex align-items-center justify-content-start mt-2 gradient-border"
                          key={index}
                        >
                          <img
                            className="ms-4 rounded"
                            height={50}
                            src={track?.artistImageUrl || DefaultCover}
                            alt={`${track?.trackName} Cover`}
                          />
                          <p className="mt-4 ms-3">
                            <span className="fw-bold">{track?.artistName}</span>
                            <br />
                            {track?.trackName}
                          </p>
                          <div className="track-status ms-auto me-3 d-flex justify-content-center align-items-center">
                            <img
                              src={track.point > 0 ? HappyMio : SadMio}
                              alt={
                                track.point > 0
                                  ? `${track.point} Points`
                                  : `No Points`
                              }
                              className="w-100"
                            />
                          </div>
                        </div>
                      ))
                  : ""}
              </div>
            </Card>
          )}
        </div>
      </div>
      <Footer
        showTimer={false}
        onClick={handleAction}
        contentType="demoEnded"
        demoFooter
      />

      <FullScreenVideo
        src={JboxOutro}
        onEnded={() => {
          setPlayVideo(false);
          introVideoCoverRef.current.classList.add("over");
        }}
        isIn={playVideo}
      />

      <img
        src={endVideoCover}
        className="position-absolute vw-100 vh-100 end-video-cover over"
        alt="End video cover"
        ref={endVideoCoverRef}
      />
      <img
        src={introVideoCover}
        className="position-absolute vw-100 vh-100 intro-video-cover"
        alt="End video cover"
        ref={introVideoCoverRef}
      />
    </div>
  );
};

export default DemoOver;
