import KeyboardArrowUp from "../assets/icons/KeyboardArrowUp.svg";
const Accordion = ({ summary = null, details = null }) => {
  return (
    <details className="accordion my-4">
      <summary className="summary d-flex align-items-center justify-content-between px-3 py-2">
        {summary}
        <img
          src={KeyboardArrowUp}
          className="accordion-icon rotate"
          alt="Keyboard Arrow Up"
        />
      </summary>
      <div
        className="details mb-0 px-3 py-2"
        // replace all a tags with target="_blank" with rel="noopener noreferrer"
        dangerouslySetInnerHTML={{
          __html: details?.replace(
            /<a /g,
            '<a rel="noopener noreferrer" target="_blank" '
          ),
        }}
      />
    </details>
  );
};

export default Accordion;
