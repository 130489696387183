import { useContext, useState } from "react";
import Card from "../../components/Card";
import Footer from "../../components/Footer";
import FeedbackModal from "./components/FeedbackModal";
import Logo from "../../assets/img/musing-logo.svg";
import DefaultCover from "../../assets/img/default-cover.jpg";
import JboxOutro from "../../assets/videos/jbox-outro.mp4";
import { AppContext } from "../../context/AppContext";
import { useEffect } from "react";
import { SignalRContext } from "../../context/SignalRContext";
import toastr from "toastr";
import ConnectionQuality from "../../utils/components/ConnectionQuality";
import HappyMio from "../../assets/icons/robot.svg";
import SadMio from "../../assets/icons/sad-mimo.svg";
import FullScreenVideo from "../../components/FullScreenVideo";
import Modal from "../../components/Modal";
import copyIcon from "../../assets/icons/copy-icon.svg";
import getLinkWithProtocol from "../../utils/helpers/getLinkWithProtocol";
import { useParams } from "react-router";
import { AgoraClient } from "../../components/AgoraComponent";
import API from "../../utils/API";

const GAMEOVERTIMEOUT = 20 * 60 * 1000;
const INFOTIMEOUT = 17 * 60 * 1000;

toastr.options = {
  timeOut: 7000,
};

const GameOver = ({ translate }) => {
  const { gameState, updateGameState, updateRoomState } =
    useContext(AppContext);
  const { closeConnection } = useContext(SignalRContext);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [countdownStarted, setCountdownStarted] = useState(false);

  const [hideMicrophone, setHideMicrophone] = useState(false);

  const { tokenString } = useParams();

  useEffect(() => {
    setPlayVideo(true);
    API.getData("GameEnd").then(({ data }) => {
      updateGameState({ gameEndData: data });

      const localTimeout = localStorage.getItem("GameOverTimeout");

      let timeOffset = 0;

      if (localTimeout) {
        const { token, timeout } = JSON.parse(localTimeout);

        if (token === tokenString) {
          timeOffset = new Date().getTime() - timeout;
        }

        if (timeOffset < GAMEOVERTIMEOUT && !gameState?.connected)
          updateGameState({ connected: true });
      } else {
        updateGameState({ connected: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const localTimeout = localStorage.getItem("GameOverTimeout");

    let timeOffset = 0;

    if (localTimeout) {
      const { token, timeout } = JSON.parse(localTimeout);

      if (token === tokenString) {
        timeOffset = new Date().getTime() - timeout;
      }
    } else {
      localStorage.setItem(
        "GameOverTimeout",
        JSON.stringify({ token: tokenString, timeout: new Date().getTime() })
      );
    }

    if (
      AgoraClient?.connectionState === "CONNECTED" &&
      !countdownStarted &&
      timeOffset < GAMEOVERTIMEOUT
    ) {
      setHideMicrophone(false);

      if (timeOffset < INFOTIMEOUT)
        setTimeout(() => {
          toastr.info(translate("THREE_MINUTES_UNTIL_AUDIO_DISCONNECTION"));
        }, INFOTIMEOUT - timeOffset); // 17 minutes

      setTimeout(async () => {
        updateRoomState({ agora: null });
        setHideMicrophone(true);
        toastr.info(
          `${translate("GAME_OVER")}, ${translate("YOU_LEFT_THE_GAME")}`
        );
      }, GAMEOVERTIMEOUT - timeOffset); // 20 minutes

      setCountdownStarted(true);
    } else if (
      AgoraClient?.connectionState === "CONNECTED" &&
      !countdownStarted
    ) {
      setCountdownStarted(true);

      closeConnection();
      setTimeout(async () => {
        updateRoomState({ agora: null });
        setHideMicrophone(true);
        toastr.info(
          `${translate("GAME_OVER")}, ${translate("YOU_LEFT_THE_GAME")}`
        );
      }, 20);
    }
  }, [
    closeConnection,
    countdownStarted,
    gameState?.connected,
    tokenString,
    translate,
    updateGameState,
    updateRoomState,
  ]);

  const handleAction = (e, type) => {
    e.preventDefault();

    switch (type) {
      case "feedback":
        setShowFeedbackModal(true);
        break;
      case "zoom":
        if (!gameState?.gameEndData?.meetingCode)
          window.open(getLinkWithProtocol(gameState?.gameEndData?.meetingLink));
        else setShowMeetingModal(true);
        break;

      default:
        break;
    }
  };

  const copyText = (e, text) => {
    e.preventDefault();

    navigator.clipboard.writeText(text);
    toastr.clear();
    toastr.success(translate("COPIED"));
  };

  useEffect(() => {
    if (AgoraClient?.connectionState !== "CONNECTED") setHideMicrophone(true);
    else setHideMicrophone(false);
  }, []);

  return (
    <div className="pre-game-layout game-over-page container-fluid main d-flex flex-column align-items-center justify-content-between">
      <ConnectionQuality />
      <div className="row d-flex align-items-center justify-content-center mt-5">
        <div className="col-10 col-lg-8 mt-4 pt-2">
          {!playVideo && (
            <Card className="d-flex flex-column align-items-center justify-content-center">
              <img className="logo pt-2" src={Logo} alt="Musing Logo" />
              <div className="d-flex flex-column align-items-center">
                {gameState?.gameEndData?.roomName && (
                  <p className="fw-bold fs-1 mb-0">
                    {translate("GAME_END_TITLE").replace(
                      "$roomName",
                      gameState?.gameEndData?.roomName
                    )}{" "}
                  </p>
                )}
                <p
                  className="fs-5 text-center px-5 col-10"
                  dangerouslySetInnerHTML={{
                    __html: translate("GAME_END_DESCRIPTION"),
                  }}
                />
              </div>
              <div className="songs overflow-auto col-8 mt-2">
                {gameState?.gameEndData?.roomTracks?.map((track, index) => (
                  <div
                    className="d-flex align-items-center justify-content-start mt-2 gradient-border"
                    key={index}
                  >
                    <img
                      className="ms-4 rounded"
                      height={50}
                      src={track?.trackImageUrl || DefaultCover}
                      alt={`${track?.name} Cover`}
                    />
                    <p className="mt-4 ms-3">
                      <span className="fw-bold">{track?.artistName}</span>
                      <br />
                      {track?.name}
                    </p>
                    <div className="track-status ms-auto me-3 d-flex justify-content-center align-items-center">
                      <img
                        src={track.point > 0 ? HappyMio : SadMio}
                        alt={
                          track.point > 0
                            ? `${track.point} Points`
                            : `No Points`
                        }
                        className="w-100"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          )}
        </div>
      </div>
      <Footer
        hideMicrophone={hideMicrophone}
        showTimer={false}
        onClick={handleAction}
        contentType="gameEnded"
      />
      <FeedbackModal
        show={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
      />
      <FullScreenVideo
        src={JboxOutro}
        onEnded={() => {
          setPlayVideo(false);
        }}
        isIn={playVideo}
      />

      <Modal
        show={showMeetingModal}
        toggleModal={() => setShowMeetingModal(false)}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h2 className="text-center">
            {translate("GAME_END_MEET_MODAL_TITLE")}
          </h2>
          <p
            className="card-page-description mb-0 text-center"
            dangerouslySetInnerHTML={{
              __html: translate("GAME_END_MEET_MODAL_DESCRIPTION"),
            }}
          />
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="text-center">
              {translate("GAME_END_MEET_MODAL_CODE")}
              <br />
              <span
                className="fw-bold user-select-all"
                role="button"
                onClick={(e) =>
                  copyText(e, gameState?.gameEndData?.meetingCode)
                }
              >
                {gameState?.gameEndData?.meetingCode}
                <img
                  src={copyIcon}
                  alt="Copy"
                  title="Kopyala"
                  className="user-select-none"
                />
              </span>
            </p>

            <p className="text-center mt-3">
              {translate("GAME_END_MEET_MODAL_PASSWORD")}
              <br />
              <span
                className="fw-bold user-select-all"
                role="button"
                onClick={(e) =>
                  copyText(e, gameState?.gameEndData?.meetingPassword)
                }
              >
                {gameState?.gameEndData?.meetingPassword}
                <img
                  src={copyIcon}
                  alt="Copy"
                  title="Kopyala"
                  className="user-select-none"
                />
              </span>
            </p>

            <a
              href={getLinkWithProtocol(gameState?.gameEndData?.meetingLink)}
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary mt-3"
            >
              {translate("GAME_END_MEET_MODAL_JOIN")}
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GameOver;
