const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return (
    /iphone|ipad|ipod/.test(userAgent) ||
    (/macintosh/.test(userAgent) &&
      window.navigator.maxTouchPoints &&
      window.navigator.maxTouchPoints > 2)
  );
};

export default isIOS;
