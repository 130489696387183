import { useContext, useEffect, useState } from "react";
import ErrorIcon from "../../assets/icons/error-icon.svg";
import Hourglass from "../../assets/icons/hourglass.svg";
import AVATARS from "../../utils/constants/AVATARS";
import SpeakerIcon from "../../assets/icons/speaker-icon.svg";

import toastr from "toastr";
import { useTranslate } from "../../utils/hooks/useTranslate";
import { AppContext } from "../../context/AppContext";

const UserInfo = ({ user }) => {
  const { roomState } = useContext(AppContext);
  const [isConnected, setIsConnected] = useState(user?.isConnected);

  const { translate } = useTranslate();

  useEffect(() => {
    if (user?.gamerId === "demo-user") return;

    if (!user?.isConnected && isConnected) {
      setIsConnected(false);
    } else if (user?.isConnected && !isConnected) {
      toastr.success(translate("AUDIO_CONNECTED_WITH_USER", { user: user?.name }));
      setIsConnected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isConnected]);

  const isPlaying = roomState?.playingTrack?.includes(user?.gamerId) || user?.gamerId === 1186;

  return user?.gamerId ? (
    <div className="UserInfo d-flex align-items-center justify-content-center position-relative" data-is-playing={isPlaying}>
      <div
        className="avatar flex-shrink-0 overflow-hidden rounded-circle d-flex justify-content-center align-items-center">
        <img
          src={user?.avatarId ? AVATARS[user?.avatarId] : AVATARS["default"]}
          alt={`${user?.name} Avatar`}
        />
      </div>
      <p className="user-name fw-semibold mb-0">{user?.name}</p>
      <div className="info-area position-relative">
        {!user?.isConnected && (
          <img
            className="error-icon flex-shrink-0"
            src={ErrorIcon}
            alt="information"
          />
        )}
        {user?.isWaiting && user?.isConnected && (
          <img
            src={Hourglass}
            className="waiting-icon flex-shrink-0"
            alt="Hourglass"
          />
        )}

        {isPlaying &&
          <img
            src={SpeakerIcon}
            alt="Speaker"
            className="speaker-icon flex-shrink-0"
          />
        }

        {!user?.isConnected || (user?.isWaiting && user?.isConnected) ? (
          <div className="info-area__tooltip position-absolute">
            {translate(
              `GAME_USER_${
                user?.isWaiting && user?.isConnected
                  ? `WAITING`
                  : !user?.isConnected
                    ? `DISCONNECTED`
                    : ``
              }_TOOLTIP`,
            )}
          </div>
        ) : (
          ""
        )}

      </div>


      <div className="border border--1 w-100 h-100 position-absolute"></div>
      <div className="border border--2 w-100 h-100 position-absolute"></div>
      <div className="border border--3 w-100 h-100 position-absolute"></div>
      <div className="border border--4 w-100 h-100 position-absolute"></div>
      <div className="border border--5 w-100 h-100 position-absolute"></div>
      <div className="border border--6 w-100 h-100 position-absolute"></div>
      <div className="border border--7 w-100 h-100 position-absolute"></div>
    </div>
  ) : (
    <div></div>
  );
};

export default UserInfo;
