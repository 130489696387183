import { useEffect, useRef, useState } from "react";
import FullScreenVideo from "../../../components/FullScreenVideo";

import JboxIntro from "../../../assets/videos/jbox-intro.mp4";
import introVideoCover from "../../../assets/img/intro-video.png";
import endVideoCover from "../../../assets/img/end-video.png";
import Header from "../../../components/Header";
import Card from "../../../components/Card";

import recordBreak from "../../../assets/img/record-break.png";
import MimoDj from "../../../assets/img/mimo-dj-bigger.svg";
import isIOS from "../../../utils/helpers/isIOS";

const PickingASong = ({ translate, nextPage }) => {
  const [playVideo, setPlayVideo] = useState(true);
  const [startFadeOut, setStartFadeOut] = useState(false);

  const introVideoCoverRef = useRef(null);
  const endVideoCoverRef = useRef(null);

  const frameRef = useRef(null);

  useEffect(() => {
    if (playVideo)
      setTimeout(() => {
        endVideoCoverRef.current.classList.add("over");
      }, 1000);
  }, [playVideo]);

  useEffect(() => {
    if (!playVideo) {
      setTimeout(() => {
        frameRef.current.setAttribute("data-is-in", true);
      }, 2750);
      setTimeout(nextPage, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playVideo]);

  const fadeOut = (e) => {
    if (e.target.volume > 0.05 && !isIOS()) {
      e.target.volume -= 0.05;
      setTimeout(() => fadeOut(e), 100);
    } else {
      setPlayVideo(false);
    }
  };

  return (
    <>
      <FullScreenVideo
        isIn={playVideo}
        onEnded={() => setPlayVideo(false)}
        onPlay={() => {
          introVideoCoverRef.current.classList.add("over");
        }}
        onTimeUpdate={(e) => {
          if (e.target.currentTime >= e.target.duration - 1.8) {
            if (!startFadeOut) {
              setStartFadeOut(true);
              fadeOut(e);
            }
          }
        }}
        src={JboxIntro}
      />

      <img
        src={introVideoCover}
        className="position-absolute vw-100 vh-100 intro-video-cover"
        alt="End video cover"
        ref={introVideoCoverRef}
      />
      <img
        src={endVideoCover}
        className="position-absolute vw-100 vh-100 end-video-cover over"
        alt="End video cover"
        ref={endVideoCoverRef}
      />

      <div className="second-main container-fluid d-flex flex-column align-items-center justify-content-between">
        <Header onlyTitle />
        <div className="col-12 col-md-8">
          <Card className="d-flex flex-column align-items-center justify-content-center record-box-select-card-holder">
            <img
              ref={frameRef}
              src={recordBreak}
              alt="Record Break First Frame"
              className="record-break-video first-frame position-absolute w-100 h-100"
              data-is-in={false}
            />
            <img src={MimoDj} alt="mimo-dj" className="mw-100" />
            <div className="d-flex flex-column align-items-center py-3 pt-md-5 px-4 mt-4">
              <h1
                className="mb-2 text-center description-title"
                style={{ maxWidth: "480px" }}
                dangerouslySetInnerHTML={{
                  __html: translate("DEMO_MIO_SELECTING_TRACK"),
                }}
              />
            </div>
          </Card>
        </div>

        <div></div>
      </div>
    </>
  );
};

export default PickingASong;
