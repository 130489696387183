import { createRef, useEffect, useState } from "react";

import PlayIcon from "../../assets/icons/play-icon.svg";

const FullScreenVideo = ({
  isIn,
  src,
  onEnded = null,
  onPlay = null,
  onPause = null,
  onTimeUpdate = null,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = createRef(null);

  useEffect(() => {
    if (isIn) {
      videoRef?.current?.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIn]);

  return (
    <div className="fullscreen" data-is-in={isIn}>
      {!isVideoPlaying && (
        <div
          className="video-play-button position-absolute rounded-circle"
          role="button"
          onClick={() => {
            videoRef?.current?.play();
          }}
        >
          <img src={PlayIcon} alt="Play Icon" className="w-100" />
        </div>
      )}
      <video
        className="fullscreen-video"
        autoPlay={false}
        muted
        playsInline
        ref={videoRef}
        onEnded={(e) => {
          if (onEnded) onEnded(e);
        }}
        onPlay={(e) => {
          e.target.volume = 0.2;
          e.target.muted = false;
          setIsVideoPlaying(true);

          if (onPlay) onPlay(e);
        }}
        onPause={(e) => {
          setIsVideoPlaying(false);

          if (onPause) onPause(e);
        }}
        onTimeUpdate={(e) => {
          if (onTimeUpdate) onTimeUpdate(e);
        }}
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export default FullScreenVideo;
